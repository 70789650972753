import useAppMutation from 'hooks/useAppMutation';
import { BaseErrorResponse } from 'models/_base';
import API from 'services/API';
import {
  CreatePropertyMainSettingsPayload,
  PropertyMainSettingsCreationResponse,
} from './AddProperty.types';

const useCreatePropertyMainSettings = () => {
  const { mutateAsync: createPropertyMainSettings, isLoading } = useAppMutation<
    PropertyMainSettingsCreationResponse,
    BaseErrorResponse,
    CreatePropertyMainSettingsPayload
  >(async (payload: CreatePropertyMainSettingsPayload) => {
    return API.post<PropertyMainSettingsCreationResponse, BaseErrorResponse>(
      'api/internal/properties/main-settings',
      payload,
    );
  });

  return { isLoading, createPropertyMainSettings };
};

export default useCreatePropertyMainSettings;
