import PropertyMultiSelectFilterField from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField';
import AirbnbIcon from 'assets/images/icons/channels/airbnb.svg?react';
import BookingIcon from 'assets/images/icons/channels/booking.svg?react';
import HvmiIcon from 'assets/images/icons/channels/hvmi.svg?react';
import HostfullyIcon from 'assets/images/icons/channels/hostfully.svg?react';
import VrboIcon from 'assets/images/icons/channels/vrbo.svg?react';
import RadioGroupField from 'components/forms/radioGroupField/RadioGroupField';
import SelectField from 'components/forms/selectField/SelectField';
import SelectTextField from 'components/forms/selectTextField/SelectTextField';
import TextField from 'components/forms/textField/TextField';
import { AppliesToLabel } from 'pages/property/common/Common.styles';
import { Col, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FeeAmountTypeEnum, FeeTypeEnum } from 'models/PropertyFees';
import { Currency } from 'models/Currency';
import useFetchAgencyChannelSettings from 'components/domain/channelSettings/useFetchAgencyChannelSettings';
import { Channel } from 'models/Channels';
import { Property } from 'models/Properties';
import {
  AppliesToHostfullyContainer,
  DividerLabel,
} from './PropertyFeeModal.styles';
import MappingTypeField from './MappingTypeField';
import useFieldsOptions from './useFieldsOptions';
import ScopeField from './ScopeField';

export enum ConditionField {
  NONE = 'NONE',
  EXEMPT = 'EXEMPT',
  EXCLUSIVE = 'EXCLUSIVE',
}

const PropertyFeeModalFields = ({
  propertyCurrency,
  propertyUid,
}: {
  propertyCurrency: Currency;
  propertyUid: Property['uid'];
}) => {
  const {
    AIRBNB_OPTIONS,
    AIRBNB_UNIT_TYPE_OPTIONS,
    BOOKING_OPTIONS,
    HVMB_OPTIONS,
    VRBO_OPTIONS,
    typeOptions,
    conditionOptions,
    amountScopeOptions,
    percentageScopeOptions,
  } = useFieldsOptions();
  const { channelSettings } = useFetchAgencyChannelSettings();
  const typeField = useWatch({ name: 'type' });
  const { t } = useTranslation();

  const isChannelActive = (channel: Channel) =>
    !!channelSettings?.find(({ enumId }) => enumId === channel)?.isActive;

  const isAirbnbActive = isChannelActive(Channel.airbnb);
  const isVrboActive = isChannelActive(Channel.homeaway);
  const isBookingDotComActive = isChannelActive(Channel.booking_com);
  const isHvmbActive = isChannelActive(Channel.hvmi);
  const isTypeFee = typeField === FeeTypeEnum.CUSTOM;

  const labelInputSizes = {
    colSmLabel: 4,
    colSmInput: 7,
  };

  return (
    <>
      <TextField
        name="name"
        label={t('common.name')}
        required
        {...labelInputSizes}
      />
      <SelectField
        name="type"
        label={t('common.type')}
        required
        {...labelInputSizes}
        options={typeOptions}
      />

      {isTypeFee ? (
        <SelectTextField
          required
          label={t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.feeValue',
          )}
          textFieldName="fee.value"
          selectFieldName="fee.type"
          position="left"
          {...labelInputSizes}
          options={[
            { value: FeeAmountTypeEnum.AMOUNT, label: propertyCurrency },
            { value: FeeAmountTypeEnum.TAX, label: '%' },
          ]}
        />
      ) : (
        <SelectTextField
          required
          label={t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.taxValue',
          )}
          textFieldName="tax.value"
          selectFieldName="tax.type"
          position="left"
          {...labelInputSizes}
          options={[
            { value: FeeAmountTypeEnum.AMOUNT, label: propertyCurrency },
            { value: FeeAmountTypeEnum.TAX, label: '%' },
          ]}
        />
      )}

      {isTypeFee && (
        <TextField
          required
          name="fee.taxationRate"
          label={t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.taxRate',
          )}
          type="number"
          min={0}
          max={100}
          rightAddons={['%']}
          {...labelInputSizes}
        />
      )}

      <ScopeField
        labelInputSizes={labelInputSizes}
        amountScopeOptions={amountScopeOptions}
        percentageScopeOptions={percentageScopeOptions}
      />

      {!isTypeFee && (
        <RadioGroupField
          name="tax.conditions"
          label={t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.conditions',
          )}
          required
          {...labelInputSizes}
          options={conditionOptions}
        />
      )}

      <Row>
        <Col xs={12} sm={labelInputSizes.colSmLabel}>
          <AppliesToLabel $required>
            {t(
              'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.appliesTo',
            )}
          </AppliesToLabel>
        </Col>
        <Col xs={12} sm={labelInputSizes.colSmInput}>
          <PropertyMultiSelectFilterField
            name="appliesTo"
            enableHotels={false}
            enableUnitTypes
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={10} smOffset={1}>
          <DividerLabel>
            {t(
              'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.channelSpecific',
            )}
          </DividerLabel>
        </Col>
      </Row>

      <AppliesToHostfullyContainer>
        <RadioGroupField
          horizontal
          required
          name="appliesToHostfully"
          label={t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.appliesToHostfully',
          )}
          options={[
            { value: 'NO', label: t('common.no') },
            { value: 'YES', label: t('common.yes') },
          ]}
          {...labelInputSizes}
        />
        <HostfullyIcon />
      </AppliesToHostfullyContainer>

      {isAirbnbActive && (
        <>
          <MappingTypeField
            name="airbnb"
            label={t(
              'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.airbnbMappingType',
            )}
            options={AIRBNB_OPTIONS}
            icon={<AirbnbIcon />}
            labelInputSizes={labelInputSizes}
            channel={Channel.airbnb}
            propertyUid={propertyUid}
          />

          <MappingTypeField
            name="airbnbUnit"
            label={t(
              'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.airbnbFeeUnitType',
            )}
            options={AIRBNB_UNIT_TYPE_OPTIONS}
            icon={<AirbnbIcon />}
            labelInputSizes={labelInputSizes}
            channel={Channel.airbnb}
            propertyUid={propertyUid}
          />
        </>
      )}

      {isVrboActive && (
        <MappingTypeField
          name="vrbo"
          label={t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.vrboMappingType',
          )}
          options={VRBO_OPTIONS}
          icon={<VrboIcon />}
          labelInputSizes={labelInputSizes}
          channel={Channel.homeaway}
          propertyUid={propertyUid}
        />
      )}

      {isBookingDotComActive && (
        <MappingTypeField
          name="booking"
          label={t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.bookingMappingType',
          )}
          options={BOOKING_OPTIONS}
          icon={<BookingIcon />}
          labelInputSizes={labelInputSizes}
          channel={Channel.booking_com}
          propertyUid={propertyUid}
        />
      )}

      {isHvmbActive && (
        <MappingTypeField
          name="hvmb"
          label={t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.hvmbMappingType',
          )}
          options={HVMB_OPTIONS}
          icon={<HvmiIcon />}
          labelInputSizes={labelInputSizes}
          channel={Channel.hvmi}
          propertyUid={propertyUid}
        />
      )}
    </>
  );
};

export default PropertyFeeModalFields;
