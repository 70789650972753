import { BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { PROPERTY_ROOMS_BASE_QUERY_KEY } from 'components/domain/property/rooms/useFetchPropertyRooms';
import { FormContextParts } from 'components/forms/form/Form.types';
import useNotify from 'hooks/useNotify';
import { PropertyBusinessType } from 'models/Properties';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import usePropertySettingsBulkSaveModal from '../bulkSave/usePropertySettingsBulkSaveModal';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import {
  getFormDefaultValues,
  PropertySettingsMainTabFormValues,
} from './PropertySettingsMainTab.schema';
import useUpdatePropertyMainSettings from './useUpdatePropertyMainSettings';
import usePropertyMainSettingsUpdatePayload from './usePropertyMainSettingsUpdatePayload';
import useUpdateHotelMainSettings from './useUpdateHotelMainSettings';
import { convertPropertyToHotelMainSettingsUpdatePayload } from './PropertySettingsMainTab.utilts';

export async function handlePropertyMainSettingsUpdateSuccess({
  notifySuccess,
  propertyUid,
  queryClient,
  reset,
  t,
}: {
  propertyUid: string;
  queryClient: QueryClient;
  reset: (values: PropertySettingsMainTabFormValues) => void;
  notifySuccess: (message: string) => void;
  t: TFunction;
}) {
  const queryKey = ['propertyMainSettingsData', propertyUid];
  await queryClient.invalidateQueries(queryKey).then(() => {
    const updatedFormValues = getFormDefaultValues(
      queryClient.getQueryData(queryKey),
    );

    reset(updatedFormValues);

    notifySuccess(t('pageProperty.mainSettings.updateSuccess'));
  });

  await queryClient.invalidateQueries([
    PROPERTY_ROOMS_BASE_QUERY_KEY,
    propertyUid,
  ]);
}

const usePropertyMainSettingsFormSubmit = (propertyUid: string) => {
  const { notifyError, notifySuccess } = useNotify();
  const { openPropertySettingsBulkSaveModal } =
    usePropertySettingsBulkSaveModal();
  const getUpdatePayload = usePropertyMainSettingsUpdatePayload();
  const { property } = usePropertySettingsInitData();
  const { updateHotelMainSettings } = useUpdateHotelMainSettings();
  const { updatePropertyMainSettings } = useUpdatePropertyMainSettings();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return async (
    formValues: PropertySettingsMainTabFormValues,
    { nativeEvent }: BaseSyntheticEvent<SubmitEvent> | undefined,
    {
      formState: { dirtyFields },
      reset,
    }: FormContextParts<PropertySettingsMainTabFormValues>,
  ) => {
    if (Object.keys(dirtyFields).length === 0) {
      return;
    }

    const isBulkSave = nativeEvent.submitter.id === 'bulk-save-button';

    if (isBulkSave) {
      openPropertySettingsBulkSaveModal({
        dirtyFields,
        formValues,
      });
      return;
    }

    const payload = getUpdatePayload({ dirtyFields, formValues });

    const executeUpdate = async () => {
      if (property?.businessType === PropertyBusinessType.HOTEL) {
        return updateHotelMainSettings({
          hotelUid: propertyUid,
          payload: convertPropertyToHotelMainSettingsUpdatePayload(payload),
        });
      }

      return updatePropertyMainSettings({ propertyUid, payload });
    };

    await executeUpdate()
      .then(async ({ response }) => {
        const apiErrorCode = response?.data?.apiErrorCode;
        const apiErrorMessage = response?.data?.apiErrorMessage;

        if (apiErrorCode || apiErrorMessage) {
          notifyError(
            getErrorMessage({
              apiErrorCode,
              apiErrorMessage,
              baseKey: 'pageProperty.apiErrorCodes.',
              t,
            }),
          );
          return;
        }

        await handlePropertyMainSettingsUpdateSuccess({
          notifySuccess,
          propertyUid,
          queryClient,
          reset,
          t,
        });
      })
      .catch(({ response: { data } }) => {
        notifyError(
          getErrorMessage({
            ...data,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      });
  };
};

export default usePropertyMainSettingsFormSubmit;
