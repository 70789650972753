import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';
import Tooltip from 'components/tooltip/Tooltip';
import LinkWithIcon from 'pages/property/common/LinkWithIcon';
import PropertyEditActionWrapper from '../../../common/PropertyEditActionWrapper';
import { InvoicePaymentMethodsLinkContainer } from './PropertyVrboSettingsInvoicePaymentMethodsLink.styles';

const PropertyVrboSettingsInvoicePaymentMethodsLink = () => {
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();
  const { t } = useTranslation();

  if (!propertiesUpdatingAllowed) {
    return (
      <InvoicePaymentMethodsLinkContainer data-testid="invoice-payment-methods-link-container">
        <PropertyEditActionWrapper tooltipId="vrbo-configure-invoice-payment-methods-tooltip">
          <Button bsStyle="link">
            {t(
              'pageProperty.mainSettings.vrboSettings.configureInvoicePaymentMethods',
            )}
          </Button>
        </PropertyEditActionWrapper>
      </InvoicePaymentMethodsLinkContainer>
    );
  }

  const tooltipContent = (
    <a
      href="https://help.hostfully.com/en/articles/4446365-set-up-vrbo-invoice-payment-methods"
      rel="noreferrer"
      target="_blank"
    >
      {t(
        'pageProperty.mainSettings.vrboSettings.configureInvoicePaymentMethodsTooltip',
      )}
    </a>
  );

  return (
    <InvoicePaymentMethodsLinkContainer data-testid="invoice-payment-methods-link-container">
      <Tooltip
        id="vrbo-configure-invoice-payment-methods-tooltip"
        text={tooltipContent}
      >
        <LinkWithIcon to="/channels/vrbo/invoices" target="_blank">
          {t(
            'pageProperty.mainSettings.vrboSettings.configureInvoicePaymentMethods',
          )}
        </LinkWithIcon>
      </Tooltip>
    </InvoicePaymentMethodsLinkContainer>
  );
};

export default PropertyVrboSettingsInvoicePaymentMethodsLink;
