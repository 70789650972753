import { SortingState } from '@tanstack/react-table';
import { ExpectedQueryData } from 'components/table/hooks/useServerFilteredTable';
import {
  DirectBookingReview,
  GetDirectBookingReviewsResponse,
} from 'models/Reviews';
import API from 'services/API';

export const PROPERTIES_REVIEWS_BASE_QUERY_KEY = 'property-reviews';

const getDirectBookingReviewsQuery = ({
  propertyUid,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => {
  return {
    queryKey: [
      PROPERTIES_REVIEWS_BASE_QUERY_KEY,
      'list',
      propertyUid,
      // { pageIndex, sorting }, TODO: pagination and sorting to be implemented within PMP-11389
    ],
    queryFn: async (): Promise<ExpectedQueryData<DirectBookingReview>> => {
      const response = await API.get<GetDirectBookingReviewsResponse>(
        '/api/v3/reviews',
        {
          params: {
            propertyUid,
          },
        },
      );

      const { reviews } = response.data;

      // slicing the data so it returns only one page of 10 items,
      // just for now, will be removed once pagination is implemented:
      const slicedReviews = reviews.slice(0, 10);

      return {
        metadata: {
          count: slicedReviews.length,
          totalCount: slicedReviews.length,
        },
        data: slicedReviews,
      };
    },
    enabled: true,
  };
};

export default getDirectBookingReviewsQuery;
