import Button from 'components/button/Button';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const PropertyFeeModalPlaceholder = ({ isEdit }: { isEdit: boolean }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit
            ? t(
                'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.editFeeModalTitle',
              )
            : t(
                'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.addFeeModalTitle',
              )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="secondary">{t('common.cancel')}</Button>
        <Button type="submit" bsStyle="primary" disabled>
          {t('common.save')}
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default PropertyFeeModalPlaceholder;
