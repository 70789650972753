import { useTranslation } from 'react-i18next';
import {
  OverallAirbnbRatingsContainer,
  RatingsAndCategoriesContainer,
} from './OverallAirbnbRatings.styles';
import useAirbnbRatingStats from './useAirbnbRatingStats';
import AverageRatingReviews from './AverageRatingReviews';
import CategoryScores from './CategoryScores';
// import StarRatingsBreakdown from './StarRatingsBreakdown';

const OverallAirbnbRatings = () => {
  const { t } = useTranslation();
  const { airbnbRatingStats, isLoading } = useAirbnbRatingStats();

  if (isLoading) {
    return null;
  }

  return (
    <OverallAirbnbRatingsContainer>
      <h3>{t('pageProperty.reviews.airbnbGuest.overallAirbnbRating.title')}</h3>
      <AverageRatingReviews
        averageRating={airbnbRatingStats.rating}
        reviewsAmount={airbnbRatingStats.count}
      />
      <RatingsAndCategoriesContainer>
        {/* 
          TODO: Implement this when it's unblocked by backend:
          <StarRatingsBreakdown ratings={[
            { star: 5, count: 0 },
            { star: 4, count: 0 },
            { star: 3, count: 0 },
            { star: 2, count: 0 },
            { star: 1, count: 0 },
          ]} totalCount={0} /> 
         */}
        <CategoryScores
          categoryStatistics={airbnbRatingStats.categoryStatistics}
        />
      </RatingsAndCategoriesContainer>
    </OverallAirbnbRatingsContainer>
  );
};

export default OverallAirbnbRatings;
