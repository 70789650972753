import { useTranslation } from 'react-i18next';
import { AirbnbCategoryStatistic, AirbnbRatingCategory } from 'models/Reviews';
import {
  CategoryScore,
  CategoryScoresContainer,
} from './OverallAirbnbRatings.styles';

const CategoryScores = ({
  categoryStatistics,
}: {
  categoryStatistics: AirbnbCategoryStatistic[];
}) => {
  const { t } = useTranslation();

  const definedOrderCategories = [
    AirbnbRatingCategory.CLEANLINESS,
    AirbnbRatingCategory.ACCURACY,
    AirbnbRatingCategory.CHECKIN,
    AirbnbRatingCategory.COMMUNICATION,
    AirbnbRatingCategory.LOCATION,
    AirbnbRatingCategory.VALUE,
  ];

  const sortedCategories = categoryStatistics.toSorted((a, b) => {
    return (
      definedOrderCategories.indexOf(a.category) -
      definedOrderCategories.indexOf(b.category)
    );
  });

  return (
    <CategoryScoresContainer data-testid="airbnb-rating-categories">
      {sortedCategories.map(({ category, rating }) => (
        <CategoryScore
          data-testid={`category-score-${category}`}
          key={category}
        >
          <span className="title">
            {t(
              `pageProperty.reviews.airbnbGuest.overallAirbnbRating.scores.${category}`,
            )}
          </span>
          <span className="score">{rating}</span>
        </CategoryScore>
      ))}
    </CategoryScoresContainer>
  );
};

export default CategoryScores;
