import { useTranslation } from 'react-i18next';
import PropertyAddress from '../../mainSettings/sections/propertyAddress/PropertyAddress';
import FormFooter from '../../common/FormFooter';
import AddMultiUnitPropertyDetailsSection from './AddMultiUnitPropertyDetailsSection';

const AddMultiUnitPropertyFormElements = () => {
  const { t } = useTranslation();

  return (
    <>
      <AddMultiUnitPropertyDetailsSection />
      <PropertyAddress />
      <FormFooter
        saveButtonLabel={t('pageProperty.add.multiUnit.saveProperty')}
      />
    </>
  );
};

export default AddMultiUnitPropertyFormElements;
