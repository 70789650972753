import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { HouseRule } from 'models/HouseRules';
import { useTranslation } from 'react-i18next';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import useDeleteModal from '../modals/useDeleteModal';
import useHouseRulesModal from '../modals/useHouseRulesModal';

const ActionsCell = ({ row }: { row: Row<HouseRule> }) => {
  const { t } = useTranslation();
  const { openBulkDeleteModal } = useDeleteModal({
    houseRule: row.original,
  });
  const { openBulkUpdateModal } = useHouseRulesModal(row.original);

  return (
    <>
      <PropertyEditActionWrapper tooltipId="row-edit-house-rule-button-tooltip">
        <Button
          bsStyle="link"
          data-testid="row-edit-house-rule-button"
          onClick={openBulkUpdateModal}
        >
          {t('common.edit')}
        </Button>
      </PropertyEditActionWrapper>
      <PropertyEditActionWrapper tooltipId="row-delete-house-rule-button-tooltip">
        <Button
          bsStyle="link"
          onClick={openBulkDeleteModal}
          data-testid="row-delete-house-rule-button"
        >
          {t('common.delete')}
        </Button>
      </PropertyEditActionWrapper>
    </>
  );
};

export default ActionsCell;
