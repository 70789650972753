import { Col, ModalBody, ModalFooter, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useGetCurrencySymbol from 'hooks/useGetCurrencySymbol';
import useFormatCurrency from 'hooks/useFormatCurrency';
import useAppModal from 'hooks/useAppModal';
import useAppEvent from 'hooks/useAppEvent';
import useScreenBreakpoints from 'hooks/useScreenBreakpoints';
import { AppEventType } from 'models/Events';
import { Lead } from 'models/Leads';
import {
  ManualTransactionCreationPayload,
  TransactionStatus,
  TransactionType,
} from 'models/Transactions';
import Button from '../../../button/Button';
import useCreateManualTransaction from '../../transaction/useCreateManualTransaction';
import FormWithProvider from '../../../forms/form/Form';
import TextField from '../../../forms/textField/TextField';
import {
  CurrentBalanceAmountWrapper,
  CurrentBalanceContainer,
} from './RecordPaymentModal.styles';

const recordPaymentFormSchema = () => ({
  amount: Yup.number().required().positive(),
  notes: Yup.string().max(100).optional(),
});

const MarkAsPaidModal = ({
  lead,
  modalId,
}: {
  lead: Lead;
  modalId: string;
}) => {
  const { closeModal } = useAppModal();
  const { publish } = useAppEvent();
  const { isXS } = useScreenBreakpoints();
  const { createManualTransaction, isLoading } = useCreateManualTransaction();
  const getCurrencySymbol = useGetCurrencySymbol();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();

  const {
    order: { balance, currency, uid: orderUid },
    uid,
  } = lead;

  const onCancel = () => closeModal(modalId);
  const onSubmit = async ({ amount, notes }) => {
    const transaction: ManualTransactionCreationPayload = {
      amount,
      notes,
      orderUid,
      status: TransactionStatus.SUCCESS,
      type: TransactionType.SALE,
    };

    await createManualTransaction({
      successCallback: () => {
        publish(AppEventType.LEAD_UPDATED, { lead: { uid } });
        closeModal(modalId);
      },
      transaction,
    });
  };

  return (
    <FormWithProvider
      defaultValues={{}}
      onSubmit={onSubmit}
      schema={recordPaymentFormSchema()}
    >
      <ModalBody>
        <Row>
          <Col>
            <CurrentBalanceContainer>
              <div
                className="col-sm-4 col-xs-6 control-label"
                data-testid="current-balance-label"
              >
                {t('componentLead.actionsInfo.recordPayment.currentBalance')}
              </div>
              <div className="col-sm-8 col-xs-6 col-sm-offset-0">
                <CurrentBalanceAmountWrapper data-testid="current-balance">
                  {formatCurrency({ currency, value: balance })}
                </CurrentBalanceAmountWrapper>
              </div>
            </CurrentBalanceContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label={t('componentLead.actionsInfo.recordPayment.amountLabel')}
              name="amount"
              leftAddon={getCurrencySymbol(currency)}
              colSmInput={8}
              colSmLabel={4}
              labelOnTop={isXS}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label={t('componentLead.actionsInfo.recordPayment.notesLabel')}
              name="notes"
              colSmInput={8}
              colSmLabel={4}
              componentClass="textarea"
              labelOnTop={isXS}
              maxCharacters={100}
              maxLength={100}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isLoading} onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="primary" disabled={isLoading} type="submit">
          {t(isLoading ? 'common.saving' : 'common.save')}
        </Button>
      </ModalFooter>
    </FormWithProvider>
  );
};

export default MarkAsPaidModal;
