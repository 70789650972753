import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
`;

export const ModalDescription = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #212529;
`;
