import { useTranslation } from 'react-i18next';
import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { RentalConditions } from 'models/RentalCondition';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import useEditRentalConditions from '../modals/useEditRentalConditions';

const ActionsCell = ({ row }: { row: Row<RentalConditions> }) => {
  const { t } = useTranslation();

  const { openEditModal } = useEditRentalConditions({
    rentalConditions: row.original,
  });

  return (
    <PropertyEditActionWrapper tooltipId="row-edit-rental-conditions-button-tooltip">
      <Button
        bsStyle="link"
        data-testid="row-edit-rental-conditions-button"
        onClick={openEditModal}
      >
        {t('common.edit')}
      </Button>
    </PropertyEditActionWrapper>
  );
};

export default ActionsCell;
