import styled from 'styled-components';
import { breakpoints } from 'styles/mediaQuery';

export const PropertySettingsTabHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  .breadcrumb {
    margin-bottom: 10px;
  }

  @media (min-width: ${breakpoints.XS}px) {
    justify-content: space-between;
  }
`;
