import useAppQuery from 'hooks/useAppQuery';
import { PropertyBusinessType } from 'models/Properties';
import API from 'services/API';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import {
  HotelMainSettingsData,
  PropertyMainSettingsData,
} from './PropertySettingsMainTab.types';
import { convertHotelToPropertyMainSettings } from './PropertySettingsMainTab.utilts';

const useFetchPropertyMainSettingsData = (
  propertyUid: string,
  enabled = true,
) => {
  const { property, isLoading } = usePropertySettingsInitData();
  const { data: mainSettingsData, isInitialLoading: isFetching } = useAppQuery(
    ['propertyMainSettingsData', propertyUid],
    async (): Promise<PropertyMainSettingsData> => {
      const isMultiUnitProperty =
        property?.businessType === PropertyBusinessType.HOTEL;

      const response = await API.get<
        PropertyMainSettingsData | { hotel: HotelMainSettingsData }
      >(
        `/api/internal/${
          isMultiUnitProperty ? 'hotels' : 'properties'
        }/${propertyUid}/main-settings`,
      );

      return 'hotel' in response.data
        ? convertHotelToPropertyMainSettings(response.data?.hotel)
        : response.data;
    },
    { enabled: !!propertyUid && !isLoading && enabled },
  );

  return { isFetching: isFetching || isLoading, mainSettingsData };
};

export default useFetchPropertyMainSettingsData;
