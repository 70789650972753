import { DeepPartial } from 'react-hook-form';
import { Namespace, TFunction } from 'react-i18next';
import { Description } from 'models/Descriptions';
import * as Yup from 'yup';
import { Channel } from 'models/Channels';
import { isRequiredAtPropertyLevel } from './PropertySettingsDescriptionsTabFormFields.utils';
import { DescriptionFields } from './PropertySettingsDescriptionsTab.types';

export const propertySettingsDescriptionsTabSchema = ({
  activeChannelsAtPropertyLevel,
  t,
}: {
  activeChannelsAtPropertyLevel: Channel[];
  t: TFunction<Namespace<'en'>>;
}) => {
  const isVrboActiveAtPropertyLevel = activeChannelsAtPropertyLevel.includes(
    Channel.homeaway,
  );
  const isAirbnbActiveAtPropertyLevel = activeChannelsAtPropertyLevel.includes(
    Channel.airbnb,
  );

  const isRequired = (fieldName: DescriptionFields) => {
    return isRequiredAtPropertyLevel(activeChannelsAtPropertyLevel, fieldName);
  };

  const validateAirbnbMaxCharacters = ({
    shortSummary,
    space,
    access,
    interaction,
    neighbourhood,
    transit,
    notes,
  }) => {
    const MAX_CHARS = 65_535;

    const totalCharacters = [
      shortSummary,
      space,
      access,
      interaction,
      neighbourhood,
      transit,
      notes,
    ]
      .filter((field) => field != null)
      .reduce((acc, field) => acc + field.length, 0);

    return totalCharacters <= MAX_CHARS;
  };

  const airbnbValidationObject = {
    name: 'combinedCharacterCount',
    test: (_, testContext) => {
      return validateAirbnbMaxCharacters(testContext.parent);
    },
    message: t('pageProperty.descriptions.airbnbCombinedCharacterCountError'),
  };

  return Yup.object({
    access: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      }),
    houseManual: Yup.string().optional().nullable(),
    interaction: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      }),
    locale: Yup.string(),
    name: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isRequired('name'),
        then: (schema) => schema.required(),
      }),
    neighbourhood: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      }),
    notes: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      }),
    shortSummary: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isVrboActiveAtPropertyLevel,
        then: Yup.string()
          .required()
          .min(20, t('pageProperty.descriptions.vrboShortSummaryMinLength')),
      })
      .when([], {
        is: () => isRequired('shortSummary'),
        then: (schema) => schema.required(),
      })
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      }),
    space: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      }),
    summary: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isRequired('summary'),
        then: (schema) => schema.required(),
      }),
    transit: Yup.string()
      .optional()
      .nullable()
      .when([], {
        is: () => isAirbnbActiveAtPropertyLevel,
        then: (schema) => schema.test(airbnbValidationObject),
      }),
  });
};

export type PropertySettingsDescriptionsTabFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof propertySettingsDescriptionsTabSchema>>
>;

export const getFormDefaultValues = (
  description: Partial<Description>,
): PropertySettingsDescriptionsTabFormValues => {
  return {
    access: description?.access ?? '',
    houseManual: description?.houseManual ?? '',
    interaction: description?.interaction ?? '',
    locale: description?.locale ?? '',
    name: description?.name ?? '',
    neighbourhood: description?.neighbourhood ?? '',
    notes: description?.notes ?? '',
    shortSummary: description?.shortSummary ?? '',
    space: description?.space ?? '',
    summary: description?.summary ?? '',
    transit: description?.transit ?? '',
  };
};
