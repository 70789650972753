import { ReactNode } from 'react';
import { TableProps as BSTableProps } from 'react-bootstrap';
import { Table as ReactTable } from '@tanstack/react-table';

export interface SortingObject<T> {
  column: keyof T;
  order: 'ascending' | 'descending';
}

export interface TableProps<T> extends Omit<BSTableProps, 'ref' | 'as'> {
  emptyState?: ReactNode;
  isLoading?: boolean;
  tableInstance: ReactTable<T>;
}

export interface TableContextProps<T> {
  tableInstance: ReactTable<T>;
  emptyState?: ReactNode;
  isLoading?: boolean;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
