import { Trans, useTranslation } from 'react-i18next';
import FormWithProvider from 'components/forms/form/Form';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import PageHeader from 'components/pageHeader/PageHeader';
import FieldPlaceholder from '../common/FieldPlaceholder';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import { PropertySettingsDescriptionsTabStyled } from './PropertySettingsDescriptionsTab.styles';
import LabelWithTooltip from './form/LabelWithTooltip';

const PropertySettingsDescriptionsTabLoader = () => {
  const { t } = useTranslation();
  const { property } = usePropertySettingsInitData();

  return (
    <PropertySettingsDescriptionsTabStyled>
      <PageHeader
        className="tab-title"
        title={
          <Trans
            i18nKey="pageProperty.descriptions.tabTitle"
            values={{ propertyName: property?.name }}
            components={{ 1: <span className="green" /> }}
          />
        }
        noDivider
      />

      <div className="channel-filter-loader">
        <div>
          {t('pageProperty.descriptions.showSupportedDescriptionsPerChannel')}
        </div>
        <LoadingPlaceholder linesCount={2.42} />
      </div>

      <FormWithProvider
        className="placeholder-form"
        defaultValues={{}}
        onSubmit={() => {}}
        noValidate
        disabled
      >
        <FieldPlaceholder
          label={<LabelWithTooltip fieldName="name" />}
          name="name"
          colSmInput={12}
          labelOnTop
          required
        />
        <FieldPlaceholder
          label={<LabelWithTooltip fieldName="shortSummary" />}
          name="shortSummary"
          colSmInput={12}
          labelOnTop
          required
        />
        <FieldPlaceholder
          label={<LabelWithTooltip fieldName="summary" />}
          name="summary"
          colSmInput={12}
          linesCount={5.28}
          labelOnTop
          required
        />
        <FieldPlaceholder
          label={<LabelWithTooltip fieldName="notes" />}
          name="notes"
          colSmInput={12}
          linesCount={5.28}
          labelOnTop
        />
        <FieldPlaceholder
          label={<LabelWithTooltip fieldName="interaction" />}
          name="interaction"
          colSmInput={12}
          linesCount={5.28}
          labelOnTop
        />
        <FieldPlaceholder
          label={<LabelWithTooltip fieldName="neighbourhood" />}
          name="neighbourhood"
          colSmInput={12}
          linesCount={5.28}
          labelOnTop
        />
        <FieldPlaceholder
          label={<LabelWithTooltip fieldName="access" />}
          name="access"
          colSmInput={12}
          linesCount={5.28}
          labelOnTop
        />
        <FieldPlaceholder
          label={<LabelWithTooltip fieldName="space" />}
          name="space"
          colSmInput={12}
          linesCount={5.28}
          labelOnTop
        />
        <FieldPlaceholder
          label={<LabelWithTooltip fieldName="transit" />}
          name="transit"
          colSmInput={12}
          linesCount={5.28}
          labelOnTop
        />
        <FieldPlaceholder
          label={<LabelWithTooltip fieldName="houseManual" />}
          name="houseManual"
          colSmInput={12}
          linesCount={5.28}
          labelOnTop
        />
      </FormWithProvider>
    </PropertySettingsDescriptionsTabStyled>
  );
};

export default PropertySettingsDescriptionsTabLoader;
