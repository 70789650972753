import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useInvalidateFetchAllProperties from 'components/domain/property/useInvalidateFetchAllProperties';
import useNotify from 'hooks/useNotify';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import {
  getPropertyDetailsMainSettingsCreationPayload,
  getPropertyPricingMainSettingsCreationPayload,
} from '../common/AddProperty.utils';
import useCreatePropertyMainSettings from '../common/useCreatePropertyMainSettings';
import {
  AddUnitTypeFormValues,
  UnitTypeCreationPayload,
} from './AddUnitType.schema';

const useAddUnitTypeSubmit = () => {
  const { createPropertyMainSettings } = useCreatePropertyMainSettings();
  const { invalidateFetchAllProperties } = useInvalidateFetchAllProperties();
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const { t } = useTranslation();

  return async ({
    formValues,
    hotelUid,
  }: {
    formValues: AddUnitTypeFormValues;
    hotelUid: string;
  }) => {
    const { capacityDetails } = formValues;

    const payload: UnitTypeCreationPayload = {
      capacityDetails,
      hotelUid,
      propertyDetails:
        getPropertyDetailsMainSettingsCreationPayload(formValues),
      pricing: getPropertyPricingMainSettingsCreationPayload({
        formValues,
      }),
    };

    await createPropertyMainSettings(payload)
      .then(({ data, response }) => {
        const apiErrorCode = response?.data?.apiErrorCode;
        const apiErrorMessage = response?.data?.apiErrorMessage;

        if (apiErrorCode || apiErrorMessage) {
          notifyError(
            getErrorMessage({
              apiErrorCode,
              apiErrorMessage,
              baseKey: 'pageProperty.apiErrorCodes.',
              t,
            }),
          );
          return;
        }

        const propertyUid = data?.propertyUid;

        if (propertyUid) {
          invalidateFetchAllProperties();

          notifySuccess(t('pageProperty.add.unitType.success'));

          navigate(`/property/${propertyUid}`);
        }
      })
      .catch(({ response: { data } }) => {
        notifyError(
          getErrorMessage({
            ...data,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      });
  };
};

export default useAddUnitTypeSubmit;
