import { Trans, useTranslation } from 'react-i18next';
import SectionHeader from 'pages/property/common/SectionHeader';
import useOpenPropertyFeeModal from './modal/useOpenPropertyFeeModal';

const PropertyFeesAndTaxesHeader = () => {
  const { openAddModal } = useOpenPropertyFeeModal();
  const { t } = useTranslation();

  const handleNewItem = () => {
    openAddModal();
  };

  return (
    <SectionHeader
      id="property-fees-taxes"
      title={t('pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.title')}
      tooltip={
        <Trans
          i18nKey="pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.tooltip"
          components={{
            1: (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
              <a
                href="https://help.hostfully.com/en/articles/3586061-set-up-your-listing-fees-taxes-and-policies"
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
        />
      }
      button={{
        label: t(
          'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addItem',
        ),
        onClick: handleNewItem,
      }}
    />
  );
};

export default PropertyFeesAndTaxesHeader;
