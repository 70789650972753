import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import { FeeAmountTypeEnum, FeeTypeEnum } from 'models/PropertyFees';
import * as Yup from 'yup';

export const propertyFeeSchema = () => ({
  uid: Yup.string().nullable().optional(),
  name: Yup.string().required(),
  type: Yup.string().oneOf(Object.values(FeeTypeEnum)).required(),
  fee: Yup.object().when('type', {
    is: FeeTypeEnum.CUSTOM,
    then: Yup.object().shape({
      type: Yup.string().oneOf(Object.values(FeeAmountTypeEnum)).required(),
      value: Yup.number()
        .required()
        .when(['type'], {
          is: FeeAmountTypeEnum.TAX,
          then: (schema) => schema.min(0).max(100),
          otherwise: (schema) => schema.min(0),
        }),
      taxationRate: Yup.number().min(0).max(100).required(),
    }),
    otherwise: Yup.object().shape({}),
  }),
  tax: Yup.object().when('type', {
    is: FeeTypeEnum.TAX,
    then: Yup.object().shape({
      value: Yup.number()
        .required()
        .when(['type'], {
          is: FeeAmountTypeEnum.TAX,
          then: (schema) => schema.min(0).max(100),
          otherwise: (schema) => schema.min(0),
        }),
      conditions: Yup.string().required(),
      type: Yup.string().oneOf(Object.values(FeeAmountTypeEnum)).required(),
    }),
    otherwise: Yup.object().shape({}),
  }),
  scope: Yup.object().when(['type', 'fee', 'tax'], {
    is: (type, fee, tax) =>
      type === FeeTypeEnum.TAX
        ? tax?.type === FeeAmountTypeEnum.TAX
        : fee?.type === FeeAmountTypeEnum.TAX,
    then: Yup.object().shape({
      tax: Yup.string().required(),
    }),
    otherwise: Yup.object().shape({
      amount: Yup.string().required(),
    }),
  }),
  appliesTo: Yup.string().oneOf(Object.values(PROPERTIES_SELECTION)).required(),
  appliesToProperties: Yup.array()
    .of(Yup.object({ label: Yup.string(), value: Yup.string() }))
    .label('properties')
    .when('appliesTo', {
      is: PROPERTIES_SELECTION.ALL_PROPERTIES,
      then: (schema) => schema.optional().nullable(true),
      otherwise: (schema) => schema.min(1).required(),
    }),
  appliesToHostfully: Yup.string().oneOf(['YES', 'NO']).required(),
  airbnbMappingType: Yup.string().nullable(),
  airbnbUnitMappingType: Yup.string().nullable(),
  bookingMappingType: Yup.string().nullable(),
  hvmbMappingType: Yup.string().nullable(),
  vrboMappingType: Yup.string().nullable(),
});

export type PropertyFeeFormSchemaType = Yup.InferType<
  Yup.ObjectSchema<ReturnType<typeof propertyFeeSchema>>
>;
