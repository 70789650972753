import { useTranslation } from 'react-i18next';
import { Lead } from 'models/Leads';
import { isDuplicate } from 'utils/lead/leadUtils';
import { LeadListTag } from './LeadListTag.styles';

const LeadListItemDuplicateTag = ({ lead }: { lead: Lead }) => {
  const { t } = useTranslation();

  if (isDuplicate(lead)) {
    return (
      <LeadListTag backgroundColor="#2D2AA5" data-testid="lead-duplicate-tag">
        {t('componentLead.statusIndicator.duplicate')}
      </LeadListTag>
    );
  }

  return null;
};

export default LeadListItemDuplicateTag;
