import useAppModal from 'hooks/useAppModal';
import { Property } from 'models/Properties';
import ManageLanguagesModal, {
  MANAGE_LANGUAGES_MODAL_ID,
} from './ManageLanguagesModal';

const useManageLanguagesModal = () => {
  const { openModal } = useAppModal();

  const openManageLanguageModal = ({ property }: { property: Property }) => {
    openModal({
      customContent: <ManageLanguagesModal property={property} />,
      size: 'medium',
      id: MANAGE_LANGUAGES_MODAL_ID,
    });
  };

  return { openManageLanguageModal };
};

export default useManageLanguagesModal;
