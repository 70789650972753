import { format, isDate } from 'date-fns';
import {
  PropertyMainSettingsUpdatePayload,
  PropertySettingsMainTabFormDirtyFields,
  PropertySettingsMainTabFormValues,
} from './PropertySettingsMainTab.schema';
import { CHECK_IN_TIME_END_FLEXIBLE } from './sections/airbnbSettings/AirbnbSettings.constants';
import { commonFieldValueConverter } from './PropertyMainSettingsTab.utils';

const fieldValuesConverters = {
  airbnbSettings: {
    nonRefundableRateDiscount: (
      value: PropertySettingsMainTabFormValues['airbnbSettings']['nonRefundableRateDiscount'],
    ) => {
      return typeof value === 'number' ? value / 100 : value;
    },
  },
  bookingDotComSettings: {
    ...commonFieldValueConverter.bookingDotComSettings,
  },
  bookingSettings: {
    percentageUponReservation: (
      value: PropertySettingsMainTabFormValues['bookingSettings']['percentageUponReservation'],
    ) => {
      return value / 100;
    },
    daysOfTheWeekToCheckInOn: (
      value: PropertySettingsMainTabFormValues['bookingSettings']['daysOfTheWeekToCheckInOn'],
    ) =>
      Object.entries(value)
        .filter(([_day, isChecked]) => isChecked)
        .map(([day]) => day),
  },
  miscInfo: {
    rentalLicenseExpirationDate: (
      value: PropertySettingsMainTabFormValues['miscInfo']['rentalLicenseExpirationDate'],
    ) => (value && isDate(value) ? format(value, 'yyyy-MM-dd') : null),
  },
};

function appendMutuallyDependentFields(
  payload: PropertyMainSettingsUpdatePayload,
  formValues: PropertySettingsMainTabFormValues,
) {
  if (payload.propertyDetails?.propertySize !== undefined) {
    payload.propertyDetails.propertySizeUnit =
      formValues.propertyDetails.propertySizeUnit;
  } else if (payload.propertyDetails?.propertySizeUnit !== undefined) {
    payload.propertyDetails.propertySize =
      formValues.propertyDetails.propertySize;
  }

  // If `checkInTimeEnd` was changed
  if (payload.airbnbSettings?.checkInTimeEnd !== undefined) {
    const { checkInTimeEnd } = formValues.airbnbSettings;

    if (checkInTimeEnd === CHECK_IN_TIME_END_FLEXIBLE) {
      payload.airbnbSettings.checkInTimeEnd = null;
      payload.airbnbSettings.checkInTimeEndFlexible = true;
    } else {
      const checkInTimeEndParsed = Number.parseInt(checkInTimeEnd, 10);
      payload.airbnbSettings.checkInTimeEnd = checkInTimeEndParsed;
      payload.airbnbSettings.checkInTimeEndFlexible = false;
    }
  }

  return payload;
}

const usePropertyMainSettingsUpdatePayload = () => {
  return ({
    dirtyFields,
    formValues,
  }: {
    dirtyFields: PropertySettingsMainTabFormDirtyFields;
    formValues: PropertySettingsMainTabFormValues;
  }) => {
    const payload: PropertyMainSettingsUpdatePayload = Object.entries(
      dirtyFields,
    ).reduce((acc, [sectionName, sectionDirtyFields]) => {
      return {
        ...acc,
        [sectionName]: Object.entries(sectionDirtyFields)
          .filter(([_, isDirty]) => isDirty)
          .reduce((sectionAcc, [fieldName, isDirtyOrDirtyObject]) => {
            const fieldValue = formValues[sectionName][fieldName];
            const fieldValueConverter =
              fieldValuesConverters[sectionName]?.[fieldName];

            return {
              ...sectionAcc,
              [fieldName]: fieldValueConverter
                ? fieldValueConverter(fieldValue, isDirtyOrDirtyObject)
                : fieldValue,
            };
          }, {}),
      };
    }, {});

    appendMutuallyDependentFields(payload, formValues);

    return payload;
  };
};

export default usePropertyMainSettingsUpdatePayload;
