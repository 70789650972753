import { useIsMutating } from '@tanstack/react-query';
import Button from 'components/button/Button';
import PropertyMultiSelectFilterField from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import FormWithProvider from 'components/forms/form/Form';
import useAppModal from 'hooks/useAppModal';
import { AppliesToLabel } from 'pages/property/common/Common.styles';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import { HouseRule } from 'models/HouseRules';
import { TextContainer } from './HouseRulesDeleteModal.styles';

export const houseRuleDeleteModalId = 'house-rule-delete-modal';

const deleteFormSchema = () => ({
  appliesTo: Yup.string().required(),
  appliesToProperties: Yup.array()
    .of(
      Yup.object({
        label: Yup.string(),
        value: Yup.string(),
        isFixed: Yup.boolean().optional(),
      }),
    )
    .label('properties')
    .when('appliesTo', {
      is: PROPERTIES_SELECTION.ALL_PROPERTIES,
      then: (schema) => schema.optional().nullable(true),
      otherwise: (schema) => schema.min(1).required(),
    }),
});

export type HouseRulesDeleteFormSchemaType = Yup.InferType<
  OptionalObjectSchema<ReturnType<typeof deleteFormSchema>>
>;

const HouseRulesDeleteModal = ({
  onSubmit,
  mutationKey,
  houseRule,
}: {
  onSubmit: (data: HouseRulesDeleteFormSchemaType) => void;
  mutationKey: string[];
  houseRule: HouseRule;
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();
  const isMutating = useIsMutating({
    mutationKey,
  });

  const isLoading = isMutating > 0;

  const handleClose = () => {
    closeModal(houseRuleDeleteModalId);
  };

  return (
    <FormWithProvider
      horizontal
      schema={deleteFormSchema()}
      onSubmit={onSubmit}
      defaultValues={{
        appliesTo: PROPERTIES_SELECTION.ALL_PROPERTIES,
        appliesToProperties: houseRule?.metadata?.appliesToAllProperties
          ? []
          : houseRule?.metadata?.propertiesThatAppliesTo?.map(
              ({ uid, name }) => ({
                label: name,
                value: uid,
              }),
            ),
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('pageProperty.feesTaxesAndPolicies.houseRules.deleteModal.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextContainer>
          <Row>
            <Col xs={12}>
              {t(
                'pageProperty.feesTaxesAndPolicies.houseRules.deleteModal.question',
              )}
            </Col>
            <Col xs={12}>
              {t(
                'pageProperty.feesTaxesAndPolicies.houseRules.deleteModal.warning',
              )}
            </Col>
          </Row>
        </TextContainer>

        <Row>
          <Col xs={2}>
            <AppliesToLabel>
              {t(
                'pageProperty.feesTaxesAndPolicies.houseRules.modal.appliesTo',
              )}
            </AppliesToLabel>
          </Col>

          <Col xs={10}>
            <PropertyMultiSelectFilterField
              name="appliesTo"
              enableHotels={false}
              enableUnitTypes
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="default" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="danger" type="submit" disabled={isLoading}>
          {t('common.delete')}
        </Button>
      </Modal.Footer>
    </FormWithProvider>
  );
};

export default HouseRulesDeleteModal;
