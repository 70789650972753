import { Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFetchAgencyChannelSettings from 'components/domain/channelSettings/useFetchAgencyChannelSettings';
import LeadChannelIcon from 'components/domain/lead/LeadChannelIcon';
import RadioGroupField from 'components/forms/radioGroupField/RadioGroupField';
import TextField from 'components/forms/textField/TextField';
import SelectField from 'components/forms/selectField/SelectField';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { BookingType } from 'models/BookingType';
import { Channel } from 'models/Channels';
import { LeadChannel } from 'models/Leads';
import { PropertyBusinessType } from 'models/Properties';
import {
  FormSectionColumn,
  SectionTitleContainer,
} from 'pages/property/common/Common.styles';
import PropertyFormFieldLabel from 'pages/property/common/PropertyFormFieldLabel';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';
import usePropertySettingsInitData from '../../../usePropertySettingsInitData';
import { PropertyVrboSettingsCollapseStyled } from './PropertyVrboSettings.styles';
import PropertyVrboSettingsCancellationPolicyField from './PropertyVrboSettingsCancellationPolicyField';
import PropertyVrboSettingsInvoicePaymentMethodsLink from './PropertyVrboSettingsInvoicePaymentMethodsLink';
import { VRBO_AVAILABLE_BOOKING_TYPES } from './PropertyVrboSettings.constants';

const PropertyVrboSettings = () => {
  const { getCopyAddonIcon } = useCopyToClipboard();
  const { channelSettings, isFetching: isLoadingChannelSettings } =
    useFetchAgencyChannelSettings();
  const { property } = usePropertySettingsInitData();
  const { t } = useTranslation();
  const {
    vrboSettings: { propertyId: vrboPropertyId },
  } = useWatch<PropertySettingsMainTabFormValues>();

  const isVrboActive = !!channelSettings?.find(
    ({ enumId }) => enumId === Channel.homeaway,
  )?.isActive;

  const isHotel = property?.businessType === PropertyBusinessType.HOTEL;
  const isUnitType = property?.businessType === PropertyBusinessType.UNIT_TYPE;

  if (!isVrboActive || isLoadingChannelSettings || isHotel || isUnitType) {
    return null;
  }

  const sectionTitle = (
    <SectionTitleContainer>
      <LeadChannelIcon channel={LeadChannel.VRBO} />
      {t('pageProperty.mainSettings.vrboSettings.sectionTitle')}
    </SectionTitleContainer>
  );

  const bookingTypeFieldName = 'vrboSettings.bookingType';
  const bookingTypeOptions = VRBO_AVAILABLE_BOOKING_TYPES.map(
    (type: BookingType) => ({
      value: type,
      label: t(`common.bookingType.${type}`),
    }),
  );

  const propertyLocationOptions = [true, false].map((value) => ({
    label: t(
      `pageProperty.mainSettings.vrboSettings.showPropertyLocation.option_${value}`,
    ),
    value: `${value}`,
  }));

  return (
    <PropertyVrboSettingsCollapseStyled
      data-testid="vrbo-settings"
      defaultExpanded
      label={sectionTitle}
      variant="card"
    >
      <fieldset>
        <Row>
          <FormSectionColumn>
            <TextField
              label={t('pageProperty.mainSettings.vrboSettings.propertyId')}
              name="vrboSettings.propertyId"
              colSmInput={7}
              colSmLabel={5}
              disabled
              rightAddons={[getCopyAddonIcon(vrboPropertyId)]}
            />
            <SelectField
              label={
                <PropertyFormFieldLabel name={bookingTypeFieldName}>
                  {t('pageProperty.mainSettings.vrboSettings.bookingType')}
                </PropertyFormFieldLabel>
              }
              name={bookingTypeFieldName}
              colSmInput={7}
              colSmLabel={5}
              options={bookingTypeOptions}
              required
            />
            <PropertyVrboSettingsCancellationPolicyField />
          </FormSectionColumn>
          <FormSectionColumn>
            <RadioGroupField
              label={t(
                'pageProperty.mainSettings.vrboSettings.showPropertyLocation.label',
              )}
              name="vrboSettings.showPropertyExactLocation"
              options={propertyLocationOptions}
              colSmInput={7}
              colSmLabel={5}
            />
            <PropertyVrboSettingsInvoicePaymentMethodsLink />
          </FormSectionColumn>
        </Row>
      </fieldset>
    </PropertyVrboSettingsCollapseStyled>
  );
};

export default PropertyVrboSettings;
