import { BreadcrumbItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormWithProvider from 'components/forms/form/Form';
import { Property, PropertySubUnit, PropertyUnitType } from 'models/Properties';
import { normalizeKey } from 'utils/localeUtils';
import { PropertyPageBreadcrumb } from './common/Common.styles';
import PropertySettingsTabHeaderPropertySelector from './PropertySettingsTabHeaderPropertySelector';
import { PropertySettingsTabHeaderContainer } from './PropertySettingsTabHeader.styles';
import PropertySettingsTabHotelBreadcrumbItem from './PropertySettingsTabHotelBreadcrumbItem';
import { PropertySettingsTab } from './PropertySettingsTabs.constants';
import PropertySettingsMainTabHeader from './mainSettings/PropertySettingsMainTabHeader';
import PropertySettingsDescriptionsTabHeader from './descriptions/PropertySettingsDescriptionsTabHeader';

const PropertySettingsTabHeader = ({
  activeTab,
  property,
}: {
  activeTab: string;
  property: Property | PropertySubUnit | PropertyUnitType;
}) => {
  const { uid: selectedPropertyUid } = property;

  const { t } = useTranslation();

  const tabHeaderButtonsComponentMap = {
    [PropertySettingsTab.descriptions]: PropertySettingsDescriptionsTabHeader,
    [PropertySettingsTab.mainSettings]: PropertySettingsMainTabHeader,
    [PropertySettingsTab.pricing]: PropertySettingsMainTabHeader,
  };

  const DynamicTabHeaderButtons =
    tabHeaderButtonsComponentMap[activeTab] || (() => null);

  return (
    <PropertySettingsTabHeaderContainer>
      <FormWithProvider onSubmit={() => {}} defaultValues={{}}>
        <PropertyPageBreadcrumb>
          <BreadcrumbItem href="#/properties">
            {t('common.pageTitles.properties')}
          </BreadcrumbItem>
          <PropertySettingsTabHotelBreadcrumbItem
            activeTab={activeTab}
            property={property}
          />
          <BreadcrumbItem active>
            <PropertySettingsTabHeaderPropertySelector
              activeTab={activeTab}
              currentPropertyUid={selectedPropertyUid}
            />
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {t(normalizeKey(`pageProperty.tabNames.${activeTab}`))}
          </BreadcrumbItem>
        </PropertyPageBreadcrumb>
      </FormWithProvider>

      <DynamicTabHeaderButtons />
    </PropertySettingsTabHeaderContainer>
  );
};

export default PropertySettingsTabHeader;
