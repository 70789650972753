import { PropertyBusinessType } from 'models/Properties';
import PropertyEditPermissionInfo from '../common/PropertyEditPermissionInfo';
import SectionWrapper from '../common/SectionWrapper';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import { SectionContainer } from './PropertySettingsFeesAndPoliciesTab.styles';
import CancellationPolicy from './sections/cancellationPolicy/CancellationPolicy';
import PropertyExpectations from './sections/propertyExpectations/PropertyExpectations';
import HouseRules from './sections/houseRules/HouseRules';
import PropertyFeesAndTaxes from './sections/propertyFeesAndTaxes/PropertyFeesAndTaxes';
import RentalConditions from './sections/rentalConditions/RentalConditions';

const PropertySettingsFeesAndPoliciesTab = () => {
  const { property } = usePropertySettingsInitData();

  const showCancellationPolicy =
    property?.businessType !== PropertyBusinessType.UNIT &&
    property?.businessType !== PropertyBusinessType.HOTEL;

  return (
    <>
      <PropertyEditPermissionInfo />
      <SectionWrapper>
        <SectionContainer>
          <PropertyFeesAndTaxes />
          <HouseRules />
          <PropertyExpectations />
          {showCancellationPolicy && <CancellationPolicy />}
          <RentalConditions />
        </SectionContainer>
      </SectionWrapper>
    </>
  );
};

export default PropertySettingsFeesAndPoliciesTab;
