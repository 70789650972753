import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: inline-flex;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 10px;

  select {
    width: fit-content;
  }
`;
