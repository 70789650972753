import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/textField/TextField';
import { PropertyBusinessType } from 'models/Properties';
import {
  CollapseSectionStyled,
  FormSectionColumn,
} from 'pages/property/common/Common.styles';
import PropertyDetailsUploadField from '../../mainSettings/sections/propertyDetails/PropertyDetailsUploadField';

const AddUnitTypePropertyDetailsSection = () => {
  const { t } = useTranslation();

  return (
    <CollapseSectionStyled
      data-testid="property-details"
      defaultExpanded
      label={t('pageProperty.mainSettings.propertyDetails.sectionName')}
      variant="card"
    >
      <fieldset>
        <Row>
          <FormSectionColumn>
            <TextField
              label={t(
                'pageProperty.mainSettings.propertyDetails.propertyName',
                { context: PropertyBusinessType.UNIT_TYPE },
              )}
              name="propertyDetails.propertyName"
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.propertyDetails.propertyNameTooltip',
              )}
              maxLength={49}
              required
            />
            <PropertyDetailsUploadField />
          </FormSectionColumn>
        </Row>
      </fieldset>
    </CollapseSectionStyled>
  );
};

export default AddUnitTypePropertyDetailsSection;
