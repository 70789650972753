import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';

const AddPropertyLoadingPlaceholder = () => {
  return (
    <div className="container">
      <div>
        <LoadingPlaceholder linesCount={1.5} margin="0 0 2em 0" width="35%" />
      </div>
      <div>
        <LoadingPlaceholder linesCount={2.5} margin="0 0 1em 0" width="20%" />
      </div>
      <div>
        <LoadingPlaceholder linesCount={1.5} margin="0 0 2em 0" width="75%" />
      </div>
      {Array.from({ length: 3 }, Math.random).map((key) => (
        <div key={key}>
          <LoadingPlaceholder linesCount={10} margin="0 0 1em 0" width="100%" />
        </div>
      ))}
    </div>
  );
};

export default AddPropertyLoadingPlaceholder;
