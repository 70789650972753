import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import SelectField from 'components/forms/selectField/SelectField';
import Tooltip from 'components/tooltip/Tooltip';
import { normalizeKey } from 'utils/localeUtils';
import { FeeScopeEnum, FeeTypeEnum } from 'models/PropertyFees';
import { Channel } from 'models/Channels';
import useFetchPropertyChannelSettings from 'components/domain/channelSettings/useFetchPropertyChannelSettings';
import { Property } from 'models/Properties';
import { FeeAmountType } from 'models/Quote';
import { MappingTypeContainer } from './PropertyFeeModal.styles';

interface MappingTypeFieldProps {
  name: string;
  label: string;
  channel: Channel;
  options: string[];
  icon: React.ReactElement;
  labelInputSizes: {
    colSmLabel: number;
    colSmInput: number;
  };
  propertyUid: Property['uid'];
}

const MappingTypeField: React.FC<MappingTypeFieldProps> = ({
  name,
  label,
  options,
  channel,
  icon,
  labelInputSizes,
  propertyUid,
}) => {
  const { t } = useTranslation();
  const isTypeTax = useWatch({ name: 'type' }) === FeeTypeEnum.TAX;
  const feeTypeField = useWatch({ name: 'fee.type' });
  const taxTypeField = useWatch({ name: 'tax.type' });
  const scopeField = useWatch({ name: 'scope' });
  const { setValue } = useFormContext();
  const { channelSettings } = useFetchPropertyChannelSettings(propertyUid);

  const isChannelActiveAtProperty = !!channelSettings?.find(
    ({ enumId }) => enumId === channel,
  )?.isActive;

  const amountType = isTypeTax ? taxTypeField : feeTypeField;
  const scopeValue =
    amountType === FeeAmountType.TAX ? scopeField.tax : scopeField.amount;
  const isScopePerPet = scopeValue === FeeScopeEnum.PER_PET;
  const isScopePerPetPerNight = scopeValue === FeeScopeEnum.PER_PET_PER_NIGHT;
  const isAirbnbExclusiveScope = isScopePerPet || isScopePerPetPerNight;
  const isAirbnbMappingType = channel === Channel.airbnb;
  const isScopeNotSupported = isAirbnbExclusiveScope && !isAirbnbMappingType;
  const isMappingTypeUnsupported =
    !isChannelActiveAtProperty || isScopeNotSupported;

  const mappingTypeErrorClassName = isMappingTypeUnsupported ? 'has-error' : '';

  const getMappingTypeOptions = () => {
    if (isMappingTypeUnsupported) {
      return [
        {
          value: 'UNSUPPORTED',
          label: t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.notSupported',
          ),
        },
      ];
    }
    return [
      {
        value: '',
        label: t(
          'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.pleaseSelect',
        ),
      },
      ...options.map((value) => ({
        value,
        label: t(
          normalizeKey(
            `pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.channelSpecific.${name}Options.${value}`,
          ),
        ),
      })),
    ];
  };

  // Set undefined value when mapping type is unsupported
  useEffect(() => {
    if (isMappingTypeUnsupported) {
      setValue(`${name}MappingType`, undefined);
    }
  }, [isMappingTypeUnsupported]);

  const field = (
    <MappingTypeContainer className={mappingTypeErrorClassName}>
      <SelectField
        name={`${name}MappingType`}
        label={label}
        {...labelInputSizes}
        options={getMappingTypeOptions()}
        disabled={isMappingTypeUnsupported}
      />
      {icon}
    </MappingTypeContainer>
  );

  if (isMappingTypeUnsupported) {
    return (
      <Tooltip
        id={`tooltip-${name}`}
        text={t(
          'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.channelNotAvailable',
        )}
      >
        {field}
      </Tooltip>
    );
  }

  return field;
};

export default MappingTypeField;
