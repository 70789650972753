import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SelectField from 'components/forms/selectField/SelectField';
import PropertyFormFieldLabel from 'pages/property/common/PropertyFormFieldLabel';
import useFetchPropertyMainSettingsData from 'pages/property/mainSettings/useFetchPropertyMainSettingsData';
import { getListingHostName } from 'utils/airbnb/listingHostUtils';

const SendMessageAsField = () => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const { mainSettingsData } = useFetchPropertyMainSettingsData(propertyUid);

  if (!mainSettingsData?.airbnbSettings?.airbnbListingHosts?.length) {
    return null;
  }

  const sendMessageAsOptions =
    mainSettingsData?.airbnbSettings?.airbnbListingHosts?.map(
      (airbnbListingHost) => {
        return {
          label: getListingHostName(t, airbnbListingHost),
          value: airbnbListingHost.airbnbUserId,
        };
      },
    );

  return (
    <SelectField
      label={
        <PropertyFormFieldLabel name="airbnbSettings.sendMessageAsUserId">
          {t('pageProperty.mainSettings.airbnbSettings.sendMessageAsUserId')}
        </PropertyFormFieldLabel>
      }
      name="airbnbSettings.sendMessageAsUserId"
      options={sendMessageAsOptions}
      colSmInput={7}
      colSmLabel={5}
      rightTooltip={t(
        'pageProperty.mainSettings.airbnbSettings.sendMessageAsUserIdTooltip',
      )}
    />
  );
};

export default SendMessageAsField;
