import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AirbnbFeeType,
  AirbnbFeeUnitType,
  BookingDotComFeeType,
  FeeConditionEnum,
  FeeScopeEnum,
  FeeTypeEnum,
  HVMIFeeType,
  VrboFeeType,
} from 'models/PropertyFees';
import { useFormContext, useWatch } from 'react-hook-form';
import { Channel } from 'models/Channels';
import useFetchAgencyChannelSettings from 'components/domain/channelSettings/useFetchAgencyChannelSettings';

const useFieldsOptions = () => {
  const { t } = useTranslation();
  const { channelSettings } = useFetchAgencyChannelSettings();
  const { resetField } = useFormContext();
  const typeField = useWatch({ name: 'type' });

  const isAirbnbActive = channelSettings?.some(
    ({ enumId, isActive }) => enumId === Channel.airbnb && isActive,
  );

  const AIRBNB_OPTIONS = Object.values(AirbnbFeeType);
  const AIRBNB_UNIT_TYPE_OPTIONS = Object.values(AirbnbFeeUnitType);
  const BOOKING_OPTIONS = Object.values(BookingDotComFeeType);
  const HVMB_OPTIONS = Object.values(HVMIFeeType);
  const VRBO_OPTIONS = Object.values(VrboFeeType);

  const typeOptions = [
    {
      value: FeeTypeEnum.CUSTOM,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.fee',
      ),
    },
    {
      value: FeeTypeEnum.TAX,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.tax',
      ),
    },
  ];

  const conditionOptions = [
    {
      value: FeeConditionEnum.NONE,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.none',
      ),
    },
    {
      value: FeeConditionEnum.EXEMPT_LONG_STAY,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.exemptLongTermStay',
      ),
    },
    {
      value: FeeConditionEnum.EXCLUSIVE_LONG_STAY,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.addModal.exclusiveLongTermStay',
      ),
    },
  ];

  const amountTypeScopeOptions = [
    {
      value: FeeScopeEnum.PER_STAY,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PER_STAY',
      ),
    },
    {
      value: FeeScopeEnum.PER_NIGHT,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PER_NIGHT',
      ),
    },
    {
      value: FeeScopeEnum.PER_GUEST,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PER_GUEST',
      ),
    },
    {
      value: FeeScopeEnum.PER_GUEST_PER_NIGHT,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PER_GUEST_PER_NIGHT',
      ),
    },
  ];

  const airbnbScopeOptions = [
    {
      value: FeeScopeEnum.PER_PET,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PER_PET',
      ),
    },
    {
      value: FeeScopeEnum.PER_PET_PER_NIGHT,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PER_PET_PER_NIGHT',
      ),
    },
  ];

  const percentageScopeOptions = [
    {
      value: FeeScopeEnum.PERCENTAGE_OF_RENT,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PERCENT_OF_RENT',
      ),
    },
    {
      value: FeeScopeEnum.PERCENTAGE_OF_TOTAL,
      label: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scopes.PERCENT_OF_TOTAL',
      ),
    },
  ];

  const amountScopeOptions = isAirbnbActive
    ? [...amountTypeScopeOptions, ...airbnbScopeOptions]
    : amountTypeScopeOptions;

  // Reset amount value/type when type (fee/tax) changes
  useEffect(() => {
    resetField('fee');
    resetField('tax');
  }, [typeField]);

  return {
    AIRBNB_OPTIONS,
    AIRBNB_UNIT_TYPE_OPTIONS,
    BOOKING_OPTIONS,
    HVMB_OPTIONS,
    VRBO_OPTIONS,
    typeOptions,
    amountScopeOptions,
    percentageScopeOptions,
    conditionOptions,
  };
};

export default useFieldsOptions;
