import useAppMutation from 'hooks/useAppMutation';
import { BaseErrorResponse } from 'models/_base';
import API from 'services/API';
import { MultiUnitPropertyCreationResponse } from './AddMultiUnitProperty.types';
import { MultiUnitPropertyCreationPayload } from './AddMultiUnitProperty.schema';

const useCreateMultiUnitProperty = () => {
  const { mutateAsync: createMultiUnitProperty, isLoading } = useAppMutation<
    MultiUnitPropertyCreationResponse,
    BaseErrorResponse,
    MultiUnitPropertyCreationPayload
  >(async (payload: MultiUnitPropertyCreationPayload) => {
    return API.post<MultiUnitPropertyCreationResponse, BaseErrorResponse>(
      'api/internal/hotels',
      payload,
    );
  });

  return { isLoading, createMultiUnitProperty };
};

export default useCreateMultiUnitProperty;
