import useFetchAgencyChannelSettings from 'components/domain/channelSettings/useFetchAgencyChannelSettings';
import PropertyStatus from 'components/domain/property/status/PropertyStatus';
import useFetchPropertyTypes from 'components/domain/property/useFetchPropertyTypes';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';
import FormWithProvider from 'components/forms/form/Form';
import PageHeader from 'components/pageHeader/PageHeader';
import { PropertyBusinessType } from 'models/Properties';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FormFooter from '../common/FormFooter';
import PropertyEditPermissionInfo from '../common/PropertyEditPermissionInfo';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import {
  getFormDefaultValues,
  propertySettingsMainTabSchema,
} from './PropertySettingsMainTab.schema';
import { PropertySettingsMainTabStyled } from './PropertySettingsMainTab.styles';
import PropertySettingsMainTabEventListeners from './PropertySettingsMainTabEventListeners';
import PropertySettingsMainTabLoader from './PropertySettingsMainTabLoader';
import AirbnbSettings from './sections/airbnbSettings/AirbnbSettings';
import BookingDotComSettings from './sections/bookingDotComSettings/BookingDotComSettings';
import BookingSettings from './sections/bookingSettings/BookingSettings';
import PropertyCapacityDetails from './sections/capacityDetails/PropertyCapacityDetails';
import PropertyAddress from './sections/propertyAddress/PropertyAddress';
import PropertyDetails from './sections/propertyDetails/PropertyDetails';
import PropertyMiscInfo from './sections/propertyMiscInfo/PropertyMiscInfo';
import PropertyVrboSettings from './sections/vrboSettings/PropertyVrboSettings';
import useFetchPropertyMainSettingsData from './useFetchPropertyMainSettingsData';
import usePropertyMainSettingsFormSubmit from './usePropertyMainSettingsFormSubmit';

const PropertySettingsMainTab = () => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const { property, isLoading: isLoadingInitData } =
    usePropertySettingsInitData();

  const { isFetching: isLoadingChannelSettings } =
    useFetchAgencyChannelSettings(true);
  const { isLoading: isLoadingPropertyTypes } = useFetchPropertyTypes();
  const { isFetching: isFetchingPropertyMainSettings, mainSettingsData } =
    useFetchPropertyMainSettingsData(propertyUid);
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();

  const onSubmit = usePropertyMainSettingsFormSubmit(propertyUid);

  const isLoading =
    isLoadingInitData ||
    isFetchingPropertyMainSettings ||
    isLoadingPropertyTypes ||
    isLoadingChannelSettings;

  if (isLoading) {
    return <PropertySettingsMainTabLoader />;
  }

  const isHotel = property?.businessType === PropertyBusinessType.HOTEL;
  const isStandaloneProperty =
    property?.businessType === PropertyBusinessType.STANDALONE_PROPERTY;
  const isSubUnit = property?.businessType === PropertyBusinessType.SUB_UNIT;
  const isUnitType = property?.businessType === PropertyBusinessType.UNIT_TYPE;

  return (
    <PropertySettingsMainTabStyled data-testid="main-settings-tab">
      <PropertyEditPermissionInfo />
      <PageHeader
        className="tab-title"
        title={
          <Trans
            i18nKey="pageProperty.mainSettings.tabTitle"
            values={{ propertyName: property?.name }}
            components={{ 1: <span className="green" /> }}
          />
        }
        buttons={!isHotel && <PropertyStatus property={property} />}
        noDivider
      />

      <FormWithProvider
        defaultValues={getFormDefaultValues(mainSettingsData)}
        disabled={!propertiesUpdatingAllowed}
        resolver={propertySettingsMainTabSchema(property?.businessType, t)}
        onSubmit={onSubmit}
        noValidate
      >
        <fieldset disabled={!propertiesUpdatingAllowed}>
          <PropertySettingsMainTabEventListeners />
          <PropertyDetails />
          {!isUnitType && <PropertyAddress />}
          {(isStandaloneProperty || isSubUnit || isUnitType) && (
            <PropertyCapacityDetails currency={property?.pricing?.currency} />
          )}
          <BookingSettings />
          <PropertyMiscInfo />
          <AirbnbSettings />
          <BookingDotComSettings />
          <PropertyVrboSettings />
          <FormFooter
            noBulkSave={isHotel || isUnitType}
            saveButtonLabel={t('pageProperty.saveMainSettings')}
          />
        </fieldset>
      </FormWithProvider>
    </PropertySettingsMainTabStyled>
  );
};

export default PropertySettingsMainTab;
