import { Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { AppliesToMetadata } from 'models/Properties';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';

const AppliesToCell = <T extends AppliesToMetadata>({
  row,
}: {
  row: Row<T>;
}) => {
  const { t } = useTranslation();
  const { property } = usePropertySettingsInitData();
  const { metadata } = row.original;

  if (metadata?.appliesToAllProperties) {
    return t('pageProperty.common.cells.allProperties');
  }

  // Only show quantity if there are more than one property
  if (metadata?.propertiesThatAppliesTo.length > 1) {
    return t('pageProperty.common.cells.amountProperties', {
      count: metadata.propertiesThatAppliesTo.length,
    });
  }

  return property?.name;
};

export default AppliesToCell;
