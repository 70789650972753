import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import FormFooterBulkSaveButton from './FormFooterBulkSaveButton';
import FormFooterSaveButton from './FormFooterSaveButton';

const FormFooterButtons = ({
  saveButtonLabel,
  noBulkSave,
}: {
  saveButtonLabel: string;
  noBulkSave: boolean;
}) => {
  const { isSubmitting } = useFormState();
  const navigate = useNavigate();
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();

  const isEditMode = !!propertyUid;
  const showBulkSave = !noBulkSave && isEditMode;

  return (
    <>
      {showBulkSave && <FormFooterBulkSaveButton />}
      {!isEditMode && (
        <Button
          data-testid="cancel-button"
          onClick={() => navigate('/properties')}
          disabled={isSubmitting}
        >
          {t('common.cancel')}
        </Button>
      )}
      <FormFooterSaveButton saveButtonLabel={saveButtonLabel} />
    </>
  );
};

export default FormFooterButtons;
