import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import { BookingDotComCheckInMethodWhen } from 'models/bookingDotCom/CheckInMethods';
import { orderBy } from 'utils/arrayUtils';

interface CheckInMethodWhenFieldProps {
  name: 'alternativeCheckInMethod' | 'primaryCheckInMethod';
}

const CHECK_IN_METHOD_WHEN_ORDER = [
  BookingDotComCheckInMethodWhen.IMMEDIATE,
  BookingDotComCheckInMethodWhen.MONTH_BEFORE,
  BookingDotComCheckInMethodWhen.WEEK_BEFORE,
  BookingDotComCheckInMethodWhen.DAY_OF_ARRIVAL,
];

const CheckInMethodWhenField = ({ name }: CheckInMethodWhenFieldProps) => {
  const { t } = useTranslation();

  const sortedCheckInMethodWhenValues = orderBy(
    Object.values(BookingDotComCheckInMethodWhen),
    [
      {
        field: (value) => CHECK_IN_METHOD_WHEN_ORDER.indexOf(value),
        order: 'asc',
      },
    ],
  );

  const checkInMethodWhenOptions = [
    { label: t('common.select'), value: '' },
    ...sortedCheckInMethodWhenValues.map((value) => ({
      label: t(
        `pageProperty.mainSettings.bookingDotComSettings.checkInMethodWhen.${value}`,
      ),
      value,
    })),
  ];

  return (
    <SelectField
      label={`${t(
        `pageProperty.mainSettings.bookingDotComSettings.${name}.when`,
      )}:`}
      name={`bookingDotComSettings.${name}.when`}
      options={checkInMethodWhenOptions}
      colSmInput={12}
      colSmLabel={12}
      labelOnTop
    />
  );
};

export default CheckInMethodWhenField;
