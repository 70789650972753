import Button from 'components/button/Button';
import Tooltip from 'components/tooltip/Tooltip';
import { Glyphicon } from 'react-bootstrap';
import { SectionHeaderContainer } from './SectionHeader.styles';
import PropertyEditActionWrapper from './PropertyEditActionWrapper';

interface SectionHeaderProps {
  title: string;
  id: string;
  tooltip?: string | React.ReactNode;
  button?: {
    label: string;
    onClick: () => void;
  };
  subtitle?: string;
}

const SectionHeader = ({
  title,
  id,
  tooltip,
  button,
  subtitle,
}: SectionHeaderProps) => {
  return (
    <SectionHeaderContainer>
      <div>
        <div className="title-container">
          <h3>{title}</h3>
          {tooltip && (
            <Tooltip id={`${id}-tooltip`} text={tooltip}>
              <Glyphicon glyph="info-sign" />
            </Tooltip>
          )}
        </div>
        {subtitle && <p>{subtitle}</p>}
      </div>
      {button && (
        <PropertyEditActionWrapper tooltipId={`${id}-button-tooltip`}>
          <Button
            bsStyle="primary"
            onClick={button.onClick}
            data-testid={`${id}-button`}
          >
            <Glyphicon glyph="plus" />
            {button.label}
          </Button>
        </PropertyEditActionWrapper>
      )}
    </SectionHeaderContainer>
  );
};

SectionHeader.defaultProps = {
  tooltip: undefined,
  button: undefined,
  subtitle: undefined,
};

export default SectionHeader;
