import styled from 'styled-components';
import theme from 'styles/theme';
import mediaQuery from 'styles/mediaQuery';

export const PriceTagStyled = styled.div<{ $hasError?: boolean }>`
  background: ${({ $hasError }) =>
    $hasError ? theme.colors.hostfullyOrange : theme.colors.bgGreen};
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: white;
  border-radius: 5px;

  ${mediaQuery.sm} {
    font-size: 12px;
  }
`;
