import SelectField from 'components/forms/selectField/SelectField';
import { FeeAmountTypeEnum, FeeScopeEnum } from 'models/PropertyFees';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface AmountOption {
  value: FeeScopeEnum;
  label: string;
}

const ScopeField = ({
  labelInputSizes,
  amountScopeOptions,
  percentageScopeOptions,
}: {
  amountScopeOptions: AmountOption[];
  percentageScopeOptions: AmountOption[];
  labelInputSizes: {
    colSmLabel: number;
    colSmInput: number;
  };
}) => {
  const { t } = useTranslation();

  const feeTypeField = useWatch({ name: 'fee.type' });
  const taxTypeField = useWatch({ name: 'tax.type' });
  const isFeeTypePercentage = feeTypeField === FeeAmountTypeEnum.TAX;
  const isTaxTypePercentage = taxTypeField === FeeAmountTypeEnum.TAX;
  const isAmountPercentage = isFeeTypePercentage || isTaxTypePercentage;

  if (isAmountPercentage) {
    return (
      <SelectField
        required
        name="scope.tax"
        label={t(
          'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scope',
        )}
        {...labelInputSizes}
        options={percentageScopeOptions}
      />
    );
  }

  return (
    <SelectField
      required
      name="scope.amount"
      label={t('pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.scope')}
      {...labelInputSizes}
      options={amountScopeOptions}
    />
  );
};

export default ScopeField;
