import styled from 'styled-components';
import theme from 'styles/theme';
import mediaQuery from '../../../../styles/mediaQuery';

export const BasicInfoWrapper = styled.div`
  align-items: center;
  color: ${theme.colors.gray600};
  display: flex;

  ${mediaQuery.sm} {
    align-items: start;
  }
`;

export const InfoWrapper = styled.div`
  margin-left: 12px;

  h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    display: flex;
    gap: 5px;
    margin: 0.25em 0 0;

    div {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 5px;

    span.dates-separator {
      color: rgba(206, 212, 218, 1);
    }
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-top: 0;
  }

  a.lead-property-link {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-top: 0;
    color: inherit;
    display: block;
  }

  ${mediaQuery.sm} {
    margin-left: 8px;

    h4 {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      gap: 0;
      margin-top: 0;
    }

    p {
      font-size: 12px;
    }

    h5 {
      font-size: 12px;
    }

    a.lead-property-link {
      font-size: 12px;
    }
  }
`;

export const GuestName = styled.span`
  margin-right: 0.5em;
  word-break: break-all;
`;

export const GuestCounter = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;

  div {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${theme.colors.gray600};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const InfoPropertyDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  white-space: break-spaces;
`;

export const NightCountLabel = styled.span<{
  isMinimumStayNotSatisfied: boolean;
}>`
  color: ${({ isMinimumStayNotSatisfied }) =>
    isMinimumStayNotSatisfied ? theme.colors.hostfullyOrange : 'inherit'};
`;
