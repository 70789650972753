import { Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import { PropertyExpectation } from 'models/PropertyExpectations';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import useDeletePropertyExpectationModal from '../modals/useDeletePropertyExpectationModal';
import usePropertyExpectationAddEditModal from '../modals/usePropertyExpectationAddEditModal';

const ActionsCell = ({ row }: { row: Row<PropertyExpectation> }) => {
  const { original: propertyExpectation } = row;
  const { openEditPropertyExpectationModal } =
    usePropertyExpectationAddEditModal();
  const { t } = useTranslation();
  const { openPropertyExpectationDeleteModal } =
    useDeletePropertyExpectationModal({ propertyExpectation });

  const editHandler = () => {
    openEditPropertyExpectationModal({ propertyExpectation });
  };

  return (
    <>
      <PropertyEditActionWrapper tooltipId="row-edit-expectation-button-tooltip">
        <Button
          bsStyle="link"
          data-testid="row-edit-expectation-button"
          onClick={editHandler}
        >
          {t('common.edit')}
        </Button>
      </PropertyEditActionWrapper>
      <PropertyEditActionWrapper tooltipId="row-delete-expectation-button-tooltip">
        <Button
          bsStyle="link"
          data-testid="row-delete-expectation-button"
          onClick={openPropertyExpectationDeleteModal}
        >
          {t('common.delete')}
        </Button>
      </PropertyEditActionWrapper>
    </>
  );
};

export default ActionsCell;
