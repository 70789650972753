import { useContext } from 'react';
import { Row } from '@tanstack/react-table';
import { queryClient } from 'App';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import { PropertyInternalFee } from 'models/PropertyFees';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import TableContext from 'components/table/TableContext';
import { handlePaginationAfterDelete } from 'utils/pagination';
import { PROPERTIES_FEES_BASE_QUERY_KEY } from '../PropertyFeesAndTaxes.utils';

const useDeleteModal = ({ row }: { row: Row<PropertyInternalFee> }) => {
  const { t } = useTranslation();
  const { tableInstance } = useContext(TableContext);
  const { uid: propertyUid } = useParams();
  const { openConfirmModal, setErrorConfirmModal } = useAppModal();

  const feeUid = row.original?.uid;

  const { mutateAsync: deleteFee } = useAppMutation(
    ['propertyFeeDelete', feeUid],
    async ({ uid }: { uid: PropertyInternalFee['uid'] }) => {
      await API.delete('/api/internal/fees', {
        data: {
          feeUid: uid,
          propertyUids: [],
          allProperties: true,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          PROPERTIES_FEES_BASE_QUERY_KEY,
          propertyUid,
        ]);

        handlePaginationAfterDelete(tableInstance);
      },
      onError: (error: any) => {
        setErrorConfirmModal(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const getDescriptionMessage = ({
    appliesToAllProperties,
    hasSharedProperties,
    quantityOfSharedProperties,
  }: {
    appliesToAllProperties: boolean;
    hasSharedProperties: boolean;
    quantityOfSharedProperties: number;
  }) => {
    if (appliesToAllProperties) {
      return t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.deleteModal.deleteForAllProperties',
      );
    }

    if (hasSharedProperties) {
      return t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.deleteModal.deleteForThisProperties',
        {
          quantity: quantityOfSharedProperties,
        },
      );
    }

    return null;
  };

  const openConfirmDeleteModal = () => {
    const { propertiesThatShareFee, appliesToAllProperties } =
      row.original.metadata;
    const quantityOfSharedProperties = propertiesThatShareFee?.length;
    const hasSharedProperties = quantityOfSharedProperties > 0;

    const descriptionMessage = getDescriptionMessage({
      appliesToAllProperties,
      hasSharedProperties,
      quantityOfSharedProperties,
    });

    openConfirmModal({
      body: (
        <>
          {t(
            'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.deleteModal.question',
            {
              name: row.original?.name,
            },
          )}
          {descriptionMessage && <p>{descriptionMessage}</p>}
        </>
      ),
      onConfirmAsync: () =>
        deleteFee({
          uid: feeUid,
        }),
      title: t(
        'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.deleteModal.title',
      ),
      confirmLabel: t('common.delete'),
      confirmColor: 'danger',
    });
  };

  return {
    openConfirmDeleteModal,
  };
};

export default useDeleteModal;
