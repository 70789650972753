import styled from 'styled-components';
import { CollapseSectionStyled } from 'pages/property/common/Common.styles';
import { breakpoints } from 'styles/mediaQuery';
import { Col } from 'react-bootstrap';

export const BookingDotComCollapseStyled = styled(CollapseSectionStyled)`
  // only apply fix for > SM screens
  @media (min-width: ${breakpoints.SM}px) {
    .cancellation-policy-field {
      label {
        width: 20.15%;
      }

      label + div {
        width: 79.85%;
      }
    }
  }
`;

export const CheckInMethodSubOptionsContainer = styled(Col)`
  margin-bottom: 15px;
  margin-top: -15px;
`;
