import { useTranslation } from 'react-i18next';
import { GuestReviewsContainer } from './GuestReviews.styles';
import GuestReviewsTable from './GuestReviewsTable';

const GuestReviews = () => {
  const { t } = useTranslation();

  return (
    <GuestReviewsContainer>
      <h3>{t('pageProperty.reviews.airbnbGuest.guestReviews.title')}</h3>
      <GuestReviewsTable />
    </GuestReviewsContainer>
  );
};

export default GuestReviews;
