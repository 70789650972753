import { AppliesToMetadata } from './Properties';
import { BasePagingResponse, BaseResponse, ObjectUidFetchType } from './_base';

export enum PropertyExpectationType {
  REQUIRES_STAIRS = 'requires_stairs',
  POTENTIAL_NOISE = 'potential_noise',
  HAS_PETS = 'has_pets',
  LIMITED_PARKING = 'limited_parking',
  SHARED_SPACES = 'shared_spaces',
  LIMITED_AMENITIES = 'limited_amenities',
  SURVEILLANCE = 'surveillance',
  WEAPONS = 'weapons',
  ANIMALS = 'animals',
  LAKE_OR_RIVER_OR_WATER_BODY = 'lake_or_river_or_water_body',
  CLIMBING_OR_PLAY_STRUCTURE = 'climbing_or_play_structure',
  POOL_OR_JACUZZI_WITH_NO_FENCE = 'pool_or_jacuzzi_with_no_fence',
  HEIGHTS_WITH_NO_FENCE = 'heights_with_no_fence',
  NOISE_MONITOR = 'noise_monitor',
}

export interface PropertyExpectation extends AppliesToMetadata {
  uid: string;
  objectUid: string;
  expectationType: PropertyExpectationType;
  expectationDescription: string;
}

export interface GetPropertyExpectationsResponseTO
  extends BaseResponse,
    BasePagingResponse {
  propertyExpectations: PropertyExpectation[];
}

export interface PropertyExpectationsBulkUpdatePayload {
  objectUidFetchType: ObjectUidFetchType;
  objectUids?: string[];
  propertyExpectation: Omit<PropertyExpectation, 'metadata'>;
}

export type PropertyExpectationsBulkUpdateResponseTO = PropertyExpectation[] &
  BaseResponse;
