import useAppMutation from 'hooks/useAppMutation';
import { BaseErrorResponse } from 'models/_base';
import API from 'services/API';
import {
  HotelMainSettingsUpdatePayload,
  PropertyMainSettingsUpdateResponse,
} from './PropertySettingsMainTab.types';

const useUpdateHotelMainSettings = () => {
  const { mutateAsync: updateHotelMainSettings, isLoading } = useAppMutation<
    PropertyMainSettingsUpdateResponse,
    BaseErrorResponse,
    { hotelUid: string; payload: HotelMainSettingsUpdatePayload }
  >(async ({ hotelUid, payload }) => {
    return API.patch<PropertyMainSettingsUpdateResponse, BaseErrorResponse>(
      `api/internal/hotels/${hotelUid}/main-settings`,
      payload,
    );
  });

  return { isLoading, updateHotelMainSettings };
};

export default useUpdateHotelMainSettings;
