import { createColumnHelper } from '@tanstack/react-table';
import CommonTable from 'components/table/CommonTable';
import useTable from 'components/table/hooks/useTable';
import { CustomAmenity } from 'models/Amenity';
import { useTranslation } from 'react-i18next';
import { sortByColumns } from 'components/table/utils/sorting';
import AppliesToCell from 'pages/property/common/cells/AppliesToCell';
import ActionsCell from './cells/ActionsCell';
import useCustomAmenitiesData from './useCustomAmenitiesData';

const CustomAmenitiesTable = () => {
  const { t } = useTranslation();

  const { customAmenities, isLoadingCustomAmenities } =
    useCustomAmenitiesData();

  const columnHelper = createColumnHelper<CustomAmenity>();

  const columns = [
    columnHelper.accessor('name', {
      header: t('common.name'),
      sortingFn: sortByColumns(['name']),
    }),
    columnHelper.display({
      id: 'appliesTo',
      header: t('pageProperty.common.cells.appliesTo'),
      cell: AppliesToCell,
    }),
    columnHelper.display({
      id: 'actions',
      header: t('common.actions'),
      cell: ActionsCell,
    }),
  ];

  const tableInstance = useTable({
    data: customAmenities,
    columns,
    manualPagination: true,
    pageCount: 1,
  });

  return (
    <CommonTable
      tableInstance={tableInstance}
      isLoading={isLoadingCustomAmenities}
      emptyState={t('pageProperty.amenities.customAmenities.emptyTable')}
      data-testid="custom-amenities-table"
    />
  );
};

export default CustomAmenitiesTable;
