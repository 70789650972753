import { BaseErrorResponse } from './_base';
import { AppliesToMetadata } from './Properties';

export interface CustomAmenity extends AppliesToMetadata {
  uid: string;
  name: string;
  objectUid: string;
  objectType: CustomAmenityObjectTypes;
}

export enum CustomAmenityObjectTypes {
  HOTEL = 'HOTEL',
  PROPERTY = 'PROPERTY',
}

export interface GetPropertyCustomAmenitiesResponse {
  customAmenities: CustomAmenity[];
}

export enum AmenityType {
  HAS_TV = 'HAS_TV',
  HAS_CABLE_TV = 'HAS_CABLE_TV',
  HAS_SMART_TV = 'HAS_SMART_TV',
  HAS_AIR_CONDITIONING = 'HAS_AIR_CONDITIONING',
  HAS_HEATING = 'HAS_HEATING',
  HAS_KITCHEN = 'HAS_KITCHEN',
  HAS_KITCHENETTE = 'HAS_KITCHENETTE',
  HAS_INTERNET_WIFI = 'HAS_INTERNET_WIFI',
  HAS_PAID_WIFI = 'HAS_PAID_WIFI',
  HAS_WIFI_SPEED_25 = 'HAS_WIFI_SPEED_25',
  HAS_WIFI_SPEED_50 = 'HAS_WIFI_SPEED_50',
  HAS_WIFI_SPEED_100 = 'HAS_WIFI_SPEED_100',
  HAS_WIFI_SPEED_250 = 'HAS_WIFI_SPEED_250',
  HAS_WIFI_SPEED_500 = 'HAS_WIFI_SPEED_500',
  HAS_WASHER = 'HAS_WASHER',
  HAS_DRYER = 'HAS_DRYER',
  HAS_SHARED_WASHER = 'HAS_SHARED_WASHER',
  HAS_SHARED_DRYER = 'HAS_SHARED_DRYER',
  HAS_POOL = 'HAS_POOL',
  HAS_POOL_ALL_YEAR = 'HAS_POOL_ALL_YEAR',
  HAS_POOL_SEASONAL = 'HAS_POOL_SEASONAL',
  HAS_COMMUNAL_POOL = 'HAS_COMMUNAL_POOL',
  HAS_HEATED_POOL = 'HAS_HEATED_POOL',
  HAS_INDOOR_POOL = 'HAS_INDOOR_POOL',
  HAS_INDOOR_POOL_ALL_YEAR = 'HAS_INDOOR_POOL_ALL_YEAR',
  HAS_INDOOR_POOL_SEASONAL = 'HAS_INDOOR_POOL_SEASONAL',
  HAS_FENCED_POOL = 'HAS_FENCED_POOL',
  HAS_FENCED_YARD = 'HAS_FENCED_YARD',
  HAS_HOT_TUB = 'HAS_HOT_TUB',
  HAS_FREE_PARKING = 'HAS_FREE_PARKING',
  HAS_FREE_STREET_PARKING = 'HAS_FREE_STREET_PARKING',
  HAS_PAID_OFF_PREMISES_PARKING = 'HAS_PAID_OFF_PREMISES_PARKING',
  HAS_PAID_ON_PREMISES_PARKING = 'HAS_PAID_ON_PREMISES_PARKING',
  HAS_EV_CAR_CHARGER = 'HAS_EV_CAR_CHARGER',
  HAS_INDOOR_FIREPLACE = 'HAS_INDOOR_FIREPLACE',
  IS_WHEELCHAIR_ACCESSIBLE = 'IS_WHEELCHAIR_ACCESSIBLE',
  HAS_SINGLE_LEVEL = 'HAS_SINGLE_LEVEL',
  HAS_SMOKE_DETECTOR = 'HAS_SMOKE_DETECTOR',
  HAS_FIRE_EXTINGUISHER = 'HAS_FIRE_EXTINGUISHER',
  HAS_EMERGENCY_EXIT = 'HAS_EMERGENCY_EXIT',
  HAS_DEADBOLT_LOCK = 'HAS_DEADBOLT_LOCK',
  HAS_OUTDOOR_LIGHTING = 'HAS_OUTDOOR_LIGHTING',
  HAS_ESSENTIALS = 'HAS_ESSENTIALS',
  HAS_BALCONY_TERRASSE = 'HAS_BALCONY_TERRASSE',
  HAS_BABY_HIGH_CHAIR = 'HAS_BABY_HIGH_CHAIR',
  HAS_BABY_TRAVEL_BED = 'HAS_BABY_TRAVEL_BED',
  HAS_CDDVD_PLAYER = 'HAS_CDDVD_PLAYER',
  HAS_BOARD_GAMES = 'HAS_BOARD_GAMES',
  HAS_BARBECUE = 'HAS_BARBECUE',
  HAS_ELEVATOR = 'HAS_ELEVATOR',
  HAS_DEHUMIDIFIER = 'HAS_DEHUMIDIFIER',
  HAS_CEILING_FAN = 'HAS_CEILING_FAN',
  HAS_VENTILATION_FAN = 'HAS_VENTILATION_FAN',
  HAS_HAIR_DRYER = 'HAS_HAIR_DRYER',
  HAS_CROCKERY_CUTLERY = 'HAS_CROCKERY_CUTLERY',
  HAS_POTS_PANS = 'HAS_POTS_PANS',
  HAS_OVEN = 'HAS_OVEN',
  HAS_MICROWAVE_OVEN = 'HAS_MICROWAVE_OVEN',
  HAS_WATER_KETTLE = 'HAS_WATER_KETTLE',
  HAS_COFFEE_MAKER = 'HAS_COFFEE_MAKER',
  HAS_DISHWASHER = 'HAS_DISHWASHER',
  HAS_TOASTER = 'HAS_TOASTER',
  HAS_FRIDGE = 'HAS_FRIDGE',
  HAS_KITCHEN_ISLAND = 'HAS_KITCHEN_ISLAND',
  HAS_DINING_TABLE = 'HAS_DINING_TABLE',
  HAS_ALARM_SYSTEM = 'HAS_ALARM_SYSTEM',
  HAS_BASKETBALL_COURT = 'HAS_BASKETBALL_COURT',
  HAS_CINEMA_ROOM = 'HAS_CINEMA_ROOM',
  HAS_GATED_PROPERTY = 'HAS_GATED_PROPERTY',
  HAS_HELIPAD = 'HAS_HELIPAD',
  HAS_GYM = 'HAS_GYM',
  HAS_IPOD_STATION = 'HAS_IPOD_STATION',
  HAS_JACUZZI = 'HAS_JACUZZI',
  HAS_STEAM_ROOM = 'HAS_STEAM_ROOM',
  HAS_LIBRARY = 'HAS_LIBRARY',
  HAS_MASSAGE_ROOM = 'HAS_MASSAGE_ROOM',
  HAS_OFFICE = 'HAS_OFFICE',
  HAS_DESK = 'HAS_DESK',
  HAS_DESK_CHAIR = 'HAS_DESK_CHAIR',
  HAS_COMPUTER_MONITOR = 'HAS_COMPUTER_MONITOR',
  HAS_PRINTER = 'HAS_PRINTER',
  HAS_POOL_TABLE = 'HAS_POOL_TABLE',
  HAS_PIANO = 'HAS_PIANO',
  HAS_SAFE_BOX = 'HAS_SAFE_BOX',
  HAS_CABINET_LOCKS = 'HAS_CABINET_LOCKS',
  HAS_SEA_VIEW = 'HAS_SEA_VIEW',
  HAS_SMART_HOME = 'HAS_SMART_HOME',
  HAS_SOCCER_FIELD = 'HAS_SOCCER_FIELD',
  HAS_TENNIS = 'HAS_TENNIS',
  HAS_TOILETRIES = 'HAS_TOILETRIES',
  HAS_WINE_CELLAR = 'HAS_WINE_CELLAR',
  HAS_WARDROBE = 'HAS_WARDROBE',
  HAS_IRON = 'HAS_IRON',
  HAS_IRONING_FACILITIES = 'HAS_IRONING_FACILITIES',
  HAS_SHAMPOO = 'HAS_SHAMPOO',
  HAS_BREAKFAST = 'HAS_BREAKFAST',
  HAS_MEAL_DELIVERY = 'HAS_MEAL_DELIVERY',
  HAS_BUZZER = 'HAS_BUZZER',
  HAS_DOORMAN = 'HAS_DOORMAN',
  HAS_CARBON_MONOXIDE_DETECTOR = 'HAS_CARBON_MONOXIDE_DETECTOR',
  HAS_FIRST_AID_KIT = 'HAS_FIRST_AID_KIT',
  HAS_CAT = 'HAS_CAT',
  HAS_DOG = 'HAS_DOG',
  HAS_OTHER_PET = 'HAS_OTHER_PET',
  HAS_24_CHECKIN = 'HAS_24_CHECKIN',
  HAS_SAFETY_CARD = 'HAS_SAFETY_CARD',
  HAS_HANGERS = 'HAS_HANGERS',
  IS_LAPTOP_FRIENDLY = 'IS_LAPTOP_FRIENDLY',
  HAS_LOCK_ON_BEDROOM = 'HAS_LOCK_ON_BEDROOM',
  HAS_PRIVATE_ENTRANCE = 'HAS_PRIVATE_ENTRANCE',
  HAS_BABY_BATH = 'HAS_BABY_BATH',
  HAS_BABY_MONITOR = 'HAS_BABY_MONITOR',
  HAS_BABYSITTER_RECOMMENDATIONS = 'HAS_BABYSITTER_RECOMMENDATIONS',
  HAS_BATHTUB = 'HAS_BATHTUB',
  HAS_CHANGING_TABLE = 'HAS_CHANGING_TABLE',
  HAS_CHILDRENS_BOOKS_AND_TOYS = 'HAS_CHILDRENS_BOOKS_AND_TOYS',
  HAS_OUTDOOR_PLAY_AREA = 'HAS_OUTDOOR_PLAY_AREA',
  HAS_CHILDRENS_DINNERWARE = 'HAS_CHILDRENS_DINNERWARE',
  HAS_CHILDCARE = 'HAS_CHILDCARE',
  HAS_FIREPLACE_GUARDS = 'HAS_FIREPLACE_GUARDS',
  HAS_GAME_CONSOLE = 'HAS_GAME_CONSOLE',
  HAS_OUTLET_COVERS = 'HAS_OUTLET_COVERS',
  HAS_PACK_N_PLAY_TRAVEL_CRIB = 'HAS_PACK_N_PLAY_TRAVEL_CRIB',
  HAS_ROOM_DARKENING_SHADES = 'HAS_ROOM_DARKENING_SHADES',
  HAS_STAIR_GATES = 'HAS_STAIR_GATES',
  HAS_TABLE_CORNER_GUARDS = 'HAS_TABLE_CORNER_GUARDS',
  HAS_WINDOW_GUARDS = 'HAS_WINDOW_GUARDS',
  HAS_LAKE_ACCESS = 'HAS_LAKE_ACCESS',
  HAS_BEACH_FRONT = 'HAS_BEACH_FRONT',
  HAS_WATER_FRONT = 'HAS_WATER_FRONT',
  HAS_SKI_IN_SKI_OUT = 'HAS_SKI_IN_SKI_OUT',
  HAS_STOVE = 'HAS_STOVE',
  // CHECK_IN_OPTION = 'CHECK_IN_OPTION',
  // CHECK_IN_OPTION_INSTRUCTION = 'CHECK_IN_OPTION_INSTRUCTION',
  HAS_LINENS = 'HAS_LINENS',
  HAS_TOWELS = 'HAS_TOWELS',
  HAS_HOT_WATER = 'HAS_HOT_WATER',
  HAS_COOKING_BASICS = 'HAS_COOKING_BASICS',
  HAS_SURVEILLANCE = 'HAS_SURVEILLANCE',
  HAS_GARDEN = 'HAS_GARDEN',
  HAS_DECK_PATIO = 'HAS_DECK_PATIO',
  HAS_AIR_FILTER = 'HAS_AIR_FILTER',
  HAS_ENHANCED_CLEANING = 'HAS_ENHANCED_CLEANING',
  HAS_CLEANING_WITH_DISINFECTANTS = 'HAS_CLEANING_WITH_DISINFECTANTS',
  HAS_HIGH_TOUCH_SURFACES_CLEANING_WITH_DISINFECTANTS = 'HAS_HIGH_TOUCH_SURFACES_CLEANING_WITH_DISINFECTANTS',
  HAS_IN_PERSON_CHECKIN = 'HAS_IN_PERSON_CHECKIN',
  HAS_CONTACTLESS_CHECKIN = 'HAS_CONTACTLESS_CHECKIN',
  HAS_CONCIERGE = 'HAS_CONCIERGE',
  HAS_OCEAN_FRONT = 'HAS_OCEAN_FRONT',
  IS_RESORT = 'IS_RESORT',
  IS_RURAL = 'IS_RURAL',
  HAS_TOWN = 'HAS_TOWN',
  HAS_WATER_VIEW = 'HAS_WATER_VIEW',
  IS_DOWNTOWN = 'IS_DOWNTOWN',
  HAS_GOLF_COURSE_VIEW = 'HAS_GOLF_COURSE_VIEW',
  IS_LAKEFRONT = 'IS_LAKEFRONT',
  HAS_MOUNTAIN = 'HAS_MOUNTAIN',
  IS_NEAR_OCEAN = 'IS_NEAR_OCEAN',
  HAS_RIVER = 'HAS_RIVER',
  HAS_VILLAGE = 'HAS_VILLAGE',
  HAS_BEACH = 'HAS_BEACH',
  HAS_BEACH_VIEW = 'HAS_BEACH_VIEW',
  IS_GOLF_COURSE_FRONT = 'IS_GOLF_COURSE_FRONT',
  HAS_LAKE = 'HAS_LAKE',
  HAS_LAKE_VIEW = 'HAS_LAKE_VIEW',
  HAS_MOUNTAIN_VIEW = 'HAS_MOUNTAIN_VIEW',
  HAS_BAKING_SHEET = 'HAS_BAKING_SHEET',
  HAS_BARBEQUE_UTENSILS = 'HAS_BARBEQUE_UTENSILS',
  HAS_BEACH_ESSENTIALS = 'HAS_BEACH_ESSENTIALS',
  HAS_BIDET = 'HAS_BIDET',
  HAS_BIKES_FOR_RENT = 'HAS_BIKES_FOR_RENT',
  HAS_BLENDER = 'HAS_BLENDER',
  HAS_BOAT_SLIP = 'HAS_BOAT_SLIP',
  HAS_BODY_SOAP = 'HAS_BODY_SOAP',
  HAS_BREAD_MAKER = 'HAS_BREAD_MAKER',
  HAS_CLEANING_PRODUCTS = 'HAS_CLEANING_PRODUCTS',
  HAS_CLOTHES_DRYING_RACK = 'HAS_CLOTHES_DRYING_RACK',
  HAS_COFFEE = 'HAS_COFFEE',
  HAS_CONDITIONER = 'HAS_CONDITIONER',
  HAS_ETHERNET_CONNECTION = 'HAS_ETHERNET_CONNECTION',
  HAS_EXERCISE_EQUIPMENT = 'HAS_EXERCISE_EQUIPMENT',
  HAS_EXTRA_PILLOWS_AND_BLANKETS = 'HAS_EXTRA_PILLOWS_AND_BLANKETS',
  HAS_FIRE_PIT = 'HAS_FIRE_PIT',
  HAS_FREEZER = 'HAS_FREEZER',
  HAS_HAMMOCK = 'HAS_HAMMOCK',
  HAS_LAUNDROMAT_NEARBY = 'HAS_LAUNDROMAT_NEARBY',
  HAS_KAYAK = 'HAS_KAYAK',
  HAS_MINI_FRIDGE = 'HAS_MINI_FRIDGE',
  HAS_MOSQUITO_NET = 'HAS_MOSQUITO_NET',
  HAS_OUTDOOR_KITCHEN = 'HAS_OUTDOOR_KITCHEN',
  HAS_OUTDOOR_SEATING = 'HAS_OUTDOOR_SEATING',
  HAS_PING_PONG_TABLE = 'HAS_PING_PONG_TABLE',
  HAS_POCKET_WIFI = 'HAS_POCKET_WIFI',
  HAS_PORTABLE_FANS = 'HAS_PORTABLE_FANS',
  HAS_RAIN_SHOWER = 'HAS_RAIN_SHOWER',
  HAS_RECORD_PLAYER = 'HAS_RECORD_PLAYER',
  HAS_RESORT_ACCESS = 'HAS_RESORT_ACCESS',
  HAS_RICE_MAKER = 'HAS_RICE_MAKER',
  HAS_SAFE = 'HAS_SAFE',
  HAS_SHOWER_GEL = 'HAS_SHOWER_GEL',
  HAS_SOUND_SYSTEM = 'HAS_SOUND_SYSTEM',
  HAS_TRASH_COMPACTER = 'HAS_TRASH_COMPACTER',
  HAS_WINE_GLASSES = 'HAS_WINE_GLASSES',
  INVOICE_PROVIDED = 'INVOICE_PROVIDED',
}

export enum AmenityCategory {
  INDOOR = 'INDOOR',
  OUTDOOR = 'OUTDOOR',
  EXTRAS = 'EXTRAS',
  FAMILY = 'FAMILY',
}

export enum AmenitySubCategory {
  ENTERTAINMENT_AND_RECREATION = 'ENTERTAINMENT_AND_RECREATION',
  CLIMATE_CONTROL = 'CLIMATE_CONTROL',
  KITCHEN_AND_DINING = 'KITCHEN_AND_DINING',
  INTERNET_AND_CONNECTIVITY = 'INTERNET_AND_CONNECTIVITY',
  LAUNDRY_FACILITIES = 'LAUNDRY_FACILITIES',
  RECREATION = 'RECREATION',
  SETTING = 'SETTING',
  UNDEFINED = 'UNDEFINED',
  SAFETY_AND_SECURITY = 'SAFETY_AND_SECURITY',
  BATHROOM_ESSENTIALS = 'BATHROOM_ESSENTIALS',
  CHECKIN_AND_GUEST_SERVICES = 'CHECKIN_AND_GUEST_SERVICES',
  BABY_ESSENTIALS = 'BABY_ESSENTIALS',
  CHILD_COMFORT = 'CHILD_COMFORT',
  CHILD_SUPPORT = 'CHILD_SUPPORT',
  CHILD_SAFETY = 'CHILD_SAFETY',
  CHILDCARE_SERVICES = 'CHILDCARE_SERVICES',
  ACCESSIBILITY = 'ACCESSIBILITY',
  ENTERTAINMENT_AND_LEISURE = 'ENTERTAINMENT_AND_LEISURE',
  LOCATION_AND_COMMUNITY = 'LOCATION_AND_COMMUNITY',
  MEALS_AND_DINING = 'MEALS_AND_DINING',
  OFFICE_AND_WORK = 'OFFICE_AND_WORK',
  PETS = 'PETS',
  SPORTS_AND_RECREATION = 'SPORTS_AND_RECREATION',
  OTHER = 'OTHER',
}

export enum AmenityChannel {
  AIRBNB = 'AIRBNB',
  BOOKINGDOTCOM = 'BOOKINGDOTCOM',
  HVMI = 'HVMI',
  VRBO = 'VRBO',
  GOOGLE = 'GOOGLE',
  HOSTFULLY = 'HOSTFULLY',
}

export enum AmenityGroupedUnder {
  HAS_TV = 'HAS_TV',
  HAS_KITCHEN = 'HAS_KITCHEN',
  HAS_WASHER = 'HAS_WASHER',
  HAS_DRYER = 'HAS_DRYER',
  HAS_POOL = 'HAS_POOL',
  HAS_INDOOR_POOL = 'HAS_INDOOR_POOL',
  HAS_FREE_PARKING = 'HAS_FREE_PARKING',
  HAS_PAID_ON_PREMISES_PARKING = 'HAS_PAID_ON_PREMISES_PARKING',
  HAS_IRON = 'HAS_IRON',
  HAS_LAKE = 'HAS_LAKE',
  HAS_BEACH = 'HAS_BEACH',
  HAS_WATER_VIEW = 'HAS_WATER_VIEW',
  HAS_MOUNTAIN = 'HAS_MOUNTAIN',
  HAS_IN_PERSON_CHECKIN = 'HAS_IN_PERSON_CHECKIN',
  HAS_GOLF_COURSE_VIEW = 'HAS_GOLF_COURSE_VIEW',
  HAS_BARBECUE = 'HAS_BARBECUE',
  IS_NEAR_OCEAN = 'IS_NEAR_OCEAN',
}

export interface AvailableAmenity {
  amenityType: AmenityType;
  category: AmenityCategory;
  channels: AmenityChannel[];
  groupedUnder: AmenityGroupedUnder | null;
  subCategory: AmenitySubCategory;
  supportsDescription: boolean;

  // front-end implementations:
  children?: AvailableAmenity[];
  disabled?: boolean;
}

export interface GetAvailableAmenitiesResponse {
  amenities: AvailableAmenity[];
}

export interface PropertyAmenity {
  uid: string;
  amenity: AmenityType;
  category: AmenityCategory;
  propertyUid: string;
  description: string;
}

export interface GetPropertyAmenitiesResponse {
  amenities: PropertyAmenity[];
  _metadata: {
    count: number;
  };
}

export type PropertyAmenitiesUpdatePayload = {
  amenityType: AmenityType;
  description: string | undefined;
  state: 'OFF' | 'ON';
}[];

export interface PropertyAmenitiesBulkUpdatePayload {
  amenities: PropertyAmenitiesUpdatePayload;
  applyToAll: boolean;
  propertyUids?: string[];
}

export interface PropertyAmenitiesBulkUpdateResponse
  extends BaseErrorResponse {}
