import styled from 'styled-components';

export const DividerLabel = styled.p`
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 15px;
  margin-bottom: 25px;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin-left: 8px;
  }
`;

export const MappingTypeContainer = styled.div`
  position: relative;

  .form-field-container div {
    padding-right: 50px;
  }

  & > svg {
    position: absolute;
    right: 50px;
    top: 5px;
    width: 24px;
    height: 24px;
  }

  &.has-error {
    select {
      color: #a94442;
    }

    svg {
      opacity: 0.6;
    }
  }
`;

export const AppliesToHostfullyContainer = styled(MappingTypeContainer)`
  .form-field-container div div {
    margin-top: 0;
  }
`;
