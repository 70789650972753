import { Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFetchPropertyTypes from 'components/domain/property/useFetchPropertyTypes';
import AreaSizeField from 'components/forms/domain/property/areaSizeField/AreaSizeField';
import CurrencySelectField from 'components/forms/currencySelectField/CurrencySelectField';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';
import { PropertyBusinessType } from 'models/Properties';
import { MultiUnitPropertyType } from 'models/PropertyType';
import { FormSectionColumn } from 'pages/property/common/Common.styles';
import PropertySecurityDepositField from 'pages/property/common/pricing/PropertySecurityDepositField';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';
import PropertyDetailsUploadField from './PropertyDetailsUploadField';
import { PropertyDetailsCollapseStyled } from './PropertyDetails.styles';

const PropertyDetails = () => {
  const { t } = useTranslation();
  const { property } = usePropertySettingsInitData();
  const isEditMode = !!property?.uid;
  const { data: propertyTypes, isLoading: isLoadingPropertyTypes } =
    useFetchPropertyTypes();
  const {
    propertyDetails: { currency },
  } = useWatch<PropertySettingsMainTabFormValues>();

  if (isLoadingPropertyTypes) {
    return null;
  }

  const isMultiUnitProperty =
    property?.businessType === PropertyBusinessType.HOTEL;
  const isPropertyTypeFieldVisible =
    property?.businessType !== PropertyBusinessType.UNIT_TYPE;
  const isWeblinkFieldVisible = !isMultiUnitProperty;
  const isFloorCountFieldVisible =
    !isEditMode ||
    property?.businessType === PropertyBusinessType.STANDALONE_PROPERTY ||
    property?.businessType === PropertyBusinessType.SUB_UNIT;
  const isPropertySizeFieldVisible = !isMultiUnitProperty;
  const isSecurityDepositFieldVisible = isMultiUnitProperty;
  const isCurrencyFieldVisible = isMultiUnitProperty;

  const propertyTypeOptions = propertyTypes
    .map(({ label, type }) => ({
      label,
      value: type,
    }))
    .toSorted(({ label: label1 }, { label: label2 }) =>
      label1.localeCompare(label2),
    );

  const multiUnitPropertyTypeOptions = propertyTypes
    .filter(({ type }) =>
      Object.values(MultiUnitPropertyType).includes(
        type as MultiUnitPropertyType,
      ),
    )
    .map(({ label, type }) => ({
      label,
      value: type,
    }))
    .toSorted(({ label: label1 }, { label: label2 }) =>
      label1.localeCompare(label2),
    );

  return (
    <PropertyDetailsCollapseStyled
      data-testid="property-details"
      defaultExpanded
      label={t('pageProperty.mainSettings.propertyDetails.sectionName', {
        context: property?.businessType,
      })}
      variant="card"
    >
      <fieldset>
        <Row>
          <FormSectionColumn>
            {isPropertyTypeFieldVisible && (
              <SelectField
                label={t(
                  'pageProperty.mainSettings.propertyDetails.propertyType',
                  { context: property?.businessType },
                )}
                name="propertyDetails.propertyType"
                options={
                  isMultiUnitProperty
                    ? multiUnitPropertyTypeOptions
                    : propertyTypeOptions
                }
                colSmInput={7}
                colSmLabel={5}
                required
              />
            )}
            <TextField
              label={t(
                'pageProperty.mainSettings.propertyDetails.propertyName',
                { context: property?.businessType },
              )}
              name="propertyDetails.propertyName"
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.propertyDetails.propertyNameTooltip',
              )}
              maxLength={49}
              required
            />
            <PropertyDetailsUploadField />
          </FormSectionColumn>
          <FormSectionColumn>
            {isWeblinkFieldVisible && (
              <TextField
                label={t('pageProperty.mainSettings.propertyDetails.weblink')}
                name="propertyDetails.weblink"
                colSmInput={7}
                colSmLabel={5}
                rightTooltip={t(
                  'pageProperty.mainSettings.propertyDetails.weblinkTooltip',
                )}
              />
            )}
            {isFloorCountFieldVisible && (
              <TextField
                label={t(
                  'pageProperty.mainSettings.propertyDetails.floorCount',
                )}
                name="propertyDetails.floorCount"
                type="number"
                min={0}
                colSmInput={7}
                colSmLabel={5}
                rightTooltip={t(
                  'pageProperty.mainSettings.propertyDetails.floorCountTooltip',
                )}
              />
            )}
            {isPropertySizeFieldVisible && (
              <AreaSizeField
                areaUnitTypeFieldName="propertyDetails.propertySizeUnit"
                label={t(
                  'pageProperty.mainSettings.propertyDetails.propertySize',
                )}
                name="propertyDetails.propertySize"
                step="any"
                min={0}
                colSmInput={7}
                colSmLabel={5}
                rightTooltip={t(
                  'pageProperty.mainSettings.propertyDetails.propertySizeTooltip',
                )}
                required
              />
            )}
            {isCurrencyFieldVisible && (
              <CurrencySelectField
                label={t('pageProperty.pricing.priceSettings.currency')}
                name="propertyDetails.currency"
                colSmInput={7}
                colSmLabel={5}
                rightTooltip={t(
                  'pageProperty.pricing.priceSettings.currencyTooltip',
                )}
                required
              />
            )}
            {isSecurityDepositFieldVisible && (
              <PropertySecurityDepositField
                currency={currency}
                name="propertyDetails.securityDeposit"
              />
            )}
          </FormSectionColumn>
        </Row>
      </fieldset>
    </PropertyDetailsCollapseStyled>
  );
};

export default PropertyDetails;
