import { useTranslation } from 'react-i18next';
import SectionHeader from 'pages/property/common/SectionHeader';
import useOpenDirectBookingReviewModal from './modals/useOpenDirectBookingReviewModal';

const DirectBookingReviewsSectionHeader = () => {
  const { openCreateModal } = useOpenDirectBookingReviewModal();
  const { t } = useTranslation();

  return (
    <SectionHeader
      id="direct-booking-reviews-section"
      title={t('pageProperty.reviews.directBooking.header.title')}
      subtitle={t('pageProperty.reviews.directBooking.header.subtitle')}
      button={{
        label: t('pageProperty.reviews.directBooking.header.addReview'),
        onClick: openCreateModal,
      }}
    />
  );
};

export default DirectBookingReviewsSectionHeader;
