import { Trans, useTranslation } from 'react-i18next';
import PageHeader from 'components/pageHeader/PageHeader';
import { PropertyBusinessType } from 'models/Properties';
import PropertyStatus from 'components/domain/property/status/PropertyStatus';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import LoadingSectionPlaceholder from '../LoadingSectionPlaceholder';
import { CollapseSectionStyled } from '../common/Common.styles';
import { PropertySettingsMainTabStyled } from './PropertySettingsMainTab.styles';

const PropertySettingsMainTabLoader = () => {
  const { t } = useTranslation();
  const { property } = usePropertySettingsInitData();

  const sectionTitleList = [
    t('pageProperty.mainSettings.propertyDetails.sectionName'),
    t('pageProperty.mainSettings.propertyAddress.sectionTitle'),
    t('pageProperty.mainSettings.capacityDetails.sectionTitle'),
    t('pageProperty.mainSettings.bookingSettings.title'),
    t('pageProperty.mainSettings.miscInfo.title'),
  ];

  return (
    <PropertySettingsMainTabStyled data-testid="main-settings-loader">
      <PageHeader
        className="tab-title"
        title={
          <Trans
            i18nKey="pageProperty.mainSettings.tabTitle"
            values={{ propertyName: property?.name }}
            components={{ 1: <span className="green" /> }}
          />
        }
        buttons={
          property?.businessType !== PropertyBusinessType.HOTEL && (
            <PropertyStatus property={property} />
          )
        }
        noDivider
      />

      <div>
        {sectionTitleList.map((sectionTitle, index) => (
          <CollapseSectionStyled
            defaultExpanded
            label={sectionTitle}
            variant="card"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <LoadingSectionPlaceholder columns={2} lines={3} linesCount={2} />
          </CollapseSectionStyled>
        ))}
      </div>
    </PropertySettingsMainTabStyled>
  );
};

export default PropertySettingsMainTabLoader;
