import PageHeader from 'components/pageHeader/PageHeader';
import { Trans } from 'react-i18next';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import CategorizedAmenities from './sections/categorizedAmenities/CategorizedAmenities';
import CustomAmenities from './sections/customAmenities/CustomAmenities';

const PropertySettingsAmenitiesTab = () => {
  const { property, isLoading: isLoadingInitData } =
    usePropertySettingsInitData();

  return (
    <div>
      <PageHeader
        className="tab-title"
        title={
          <Trans
            i18nKey="pageProperty.amenities.tabTitle"
            values={{ propertyName: property?.name }}
            components={{ 1: <span className="green" /> }}
          />
        }
        noDivider
        isLoading={isLoadingInitData}
      />

      <CategorizedAmenities />
      <CustomAmenities />
    </div>
  );
};

export default PropertySettingsAmenitiesTab;
