import { useTranslation } from 'react-i18next';
import FormFooter from '../../common/FormFooter';
import PropertyCapacityDetails from '../../mainSettings/sections/capacityDetails/PropertyCapacityDetails';
import usePropertySettingsInitData from '../../usePropertySettingsInitData';
import AddUnitTypePricingFeesTaxesSection from './AddUnitTypePricingFeesTaxesSection';
import AddUnitTypePropertyDetailsSection from './AddUnitTypePropertyDetailsSection';

const AddUnitTypeFormElements = () => {
  const {
    property: {
      pricing: { currency },
    },
  } = usePropertySettingsInitData();
  const { t } = useTranslation();

  return (
    <>
      <AddUnitTypePropertyDetailsSection />
      <PropertyCapacityDetails currency={currency} />
      <AddUnitTypePricingFeesTaxesSection />
      <FormFooter
        saveButtonLabel={t('pageProperty.add.unitType.saveProperty')}
      />
    </>
  );
};

export default AddUnitTypeFormElements;
