export enum BookingDotComCheckInMethodHow {
  EMAIL = 'email',
  OTHER = 'other',
  PHONE = 'phone',
  SMS = 'sms',
}

export enum BookingDotComCheckInMethodWhen {
  DAY_OF_ARRIVAL = 'day_of_arrival',
  IMMEDIATE = 'immediate',
  MONTH_BEFORE = 'month_before',
  WEEK_BEFORE = 'week_before',
}

export enum BookingDotComCheckInMethodType {
  DOOR_CODE = 'door_code',
  INSTRUCTION_CONTACT_US = 'instruction_contact_us',
  INSTRUCTION_WILL_SEND = 'instruction_will_send',
  LOCK_BOX = 'lock_box',
  NONE = 'none',
  OTHER = 'other',
  RECEPTION = 'reception',
  SECRET_SPOT = 'secret_spot',
  SOMEONE_WILL_MEET = 'someone_will_meet',
}
