export interface PropertyType {
  type: string;
  label: string;
}

export type GetPropertyTypeResponseTO = PropertyType[];

export enum MultiUnitPropertyType {
  APARTHOTEL = 'APARTHOTEL',
  BOUTIQUE_HOTEL = 'BOUTIQUE_HOTEL',
  HOSTEL = 'HOSTEL',
  HOTEL = 'HOTEL',
  LODGE = 'LODGE',
  LOFT = 'LOFT',
  RESORT = 'RESORT',
}
