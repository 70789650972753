import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { Col, Modal, Row } from 'react-bootstrap';
import Button from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import { ModalDescription } from './ManageLanguagesModal.styles';

const ManageLanguagesModalLoader = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('pageProperty.descriptions.manageLanguageModal.title')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ModalDescription>
          {t('pageProperty.descriptions.manageLanguageModal.description')}
        </ModalDescription>

        <Row>
          {Array.from({ length: 14 }).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Col key={index} md={5}>
              <LoadingPlaceholder linesCount={1} width="100%" />
            </Col>
          ))}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="default" disabled>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="primary" disabled>
          {t('common.save')}
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default ManageLanguagesModalLoader;
