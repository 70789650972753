import { AirbnbListingHostDto } from 'models/AirbnbListingHost';
import { Namespace, TFunction } from 'react-i18next';

export const getListingHostName = (
  t: TFunction<Namespace<'en'>>,
  listingHost: AirbnbListingHostDto,
) => {
  return [
    listingHost.firstName,
    listingHost.lastName,
    listingHost.type
      ? `(${t(`common.airbnbListingHost.${listingHost.type}`)})`
      : '',
  ]
    .filter(Boolean)
    .join(' ');
};
