import { useTranslation } from 'react-i18next';
import SectionHeader from 'pages/property/common/SectionHeader';
import CancellationPolicyTable from './CancellationPolicyTable';
import useCancellationPolicyData from './useCancellationPolicyData';
import useAddCancellationPolicy from './modals/useAddCancellationPolicy';

const CancellationPolicy = () => {
  const { t } = useTranslation();
  const { cancellationPolicyData } = useCancellationPolicyData();
  const { openAddModal } = useAddCancellationPolicy();

  const hasCancellationPolicy = !!cancellationPolicyData?.values.length;

  return (
    <section data-testid="property-cancellation-policy-section">
      <SectionHeader
        id="cancellation-policy-header"
        title={t('pageProperty.feesTaxesAndPolicies.cancelationPolicy.title')}
        tooltip={t(
          'pageProperty.feesTaxesAndPolicies.cancelationPolicy.tooltipTitle',
        )}
        button={
          !hasCancellationPolicy && {
            label: t(
              'pageProperty.feesTaxesAndPolicies.cancelationPolicy.addButton',
            ),
            onClick: openAddModal,
          }
        }
      />

      <CancellationPolicyTable />
    </section>
  );
};

export default CancellationPolicy;
