import styled from 'styled-components';

interface LeadListTagProps {
  backgroundColor: string;
}

export const LeadListTag = styled.div<LeadListTagProps>`
  background-color: ${({ backgroundColor }: LeadListTagProps) =>
    backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: white;
  border-radius: 5px;
`;
