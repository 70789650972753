import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { AirbnbGuestReview } from 'models/Reviews';
import { useNavigate } from 'react-router-dom';

const GuestNameCell = ({ row }: { row: Row<AirbnbGuestReview> }) => {
  const navigate = useNavigate();

  const handleClickGuest = () => {
    if (row.original.leadUid) {
      navigate(`/pipeline?leadUid=${row.original.leadUid}`);
    }
  };

  return (
    <Button
      bsStyle="link"
      data-testid="row-guest-name-lead-button"
      onClick={handleClickGuest}
    >
      {row.original.author}
    </Button>
  );
};

export default GuestNameCell;
