import { Channel } from 'models/Channels';

import HostfullyIcon from 'assets/images/icons/channels/hostfully.svg?react';
import AirbnbIcon from 'assets/images/icons/channels/airbnb.svg?react';
import HvmiIcon from 'assets/images/icons/channels/hvmi.svg?react';
import VrboIcon from 'assets/images/icons/channels/vrbo.svg?react';
import BookingIcon from 'assets/images/icons/channels/booking.svg?react';
import GoogleIcon from 'assets/images/icons/channels/google.svg?react';

import { ChannelItemButtonStyled } from './ChannelFilter.styles';

const channelToIconMapping = {
  [Channel.orbirental]: <HostfullyIcon />,
  [Channel.airbnb]: <AirbnbIcon />,
  [Channel.homeaway]: <VrboIcon />,
  [Channel.booking_com]: <BookingIcon />,
  [Channel.google]: <GoogleIcon />,
  [Channel.hvmi]: <HvmiIcon />,
};

const channelToLabelMapping = {
  [Channel.orbirental]: 'Hostfully',
  [Channel.airbnb]: 'Airbnb',
  [Channel.homeaway]: 'Vrbo',
  [Channel.booking_com]: 'Booking.com',
  [Channel.google]: 'Google',
  [Channel.hvmi]: 'HVMI',
};

const ChannelItemButton = ({
  channel,
  isActive,
  onClick,
}: {
  channel: Channel;
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <ChannelItemButtonStyled
      $isActive={isActive}
      onClick={onClick}
      data-testid={`channel-item-button-${channel}`}
    >
      {channelToIconMapping[channel]}
      <span>{channelToLabelMapping[channel]}</span>
    </ChannelItemButtonStyled>
  );
};

export default ChannelItemButton;
