import * as Yup from 'yup';
import { Namespace, TFunction } from 'react-i18next';
import { AREA_UNIT_TYPE, AreaUnitType } from 'models/AreaUnitType';
import {
  BookingDotComCheckInMethodHow,
  BookingDotComCheckInMethodType,
  BookingDotComCheckInMethodWhen,
} from 'models/bookingDotCom/CheckInMethods';
import { PropertyBusinessType } from 'models/Properties';
import { BedType, RoomType } from 'models/PropertyRooms';
import { MEDIA_TYPE } from 'utils/constants/files';

export const basePropertyAddressSchema = (
  businessType: PropertyBusinessType,
) => {
  const isUnitType = businessType === PropertyBusinessType.UNIT_TYPE;

  return Yup.object({
    addressLine1: Yup.string().when([], {
      is: () => isUnitType,
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required(),
    }),
    addressLine2: Yup.string().nullable().optional(),
    city: Yup.string().when([], {
      is: () => isUnitType,
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required(),
    }),
    countryCode: Yup.string().when([], {
      is: () => isUnitType,
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required(),
    }),
    state: Yup.string().when('countryCode', {
      is: 'US' && !isUnitType,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    zipCode: Yup.string().when([], {
      is: () => isUnitType,
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required(),
    }),
  });
};

export const capacityDetailsRoomsSchema = () =>
  Yup.array().of(
    Yup.object({
      hasPrivateBathroom: Yup.boolean().nullable().optional(),
      roomNumber: Yup.number().integer().required(),
      roomType: Yup.mixed<RoomType>().oneOf(Object.values(RoomType)).required(),
      beds: Yup.array()
        .of(
          Yup.object({
            bedType: Yup.mixed<BedType>()
              .oneOf(Object.values(BedType))
              .required(),
            count: Yup.number().integer().positive().required(),
          }),
        )
        .required(),
    }),
  );

export const propertyCapacityDetailsSchema = ({
  businessType,
  t,
}: {
  businessType: PropertyBusinessType;
  t: TFunction<Namespace<'en'>>;
}) =>
  Yup.object({
    bathroomCount: Yup.string().optional(),
    baseGuests: Yup.number()
      .useNaNAsNull()
      .useNegativeZeroAsNegative()
      .integer()
      .positive()
      .when([], {
        is: () => businessType === PropertyBusinessType.HOTEL,
        then: (schema) => schema.nullable().optional(),
        otherwise: (schema) => schema.required(),
      }),
    bedCount: Yup.number().optional(),
    bedroomCount: Yup.number().optional(),
    maxGuests: Yup.number()
      .useNaNAsNull()
      .useNegativeZeroAsNegative()
      .integer()
      .positive()
      .when([], {
        is: () => businessType === PropertyBusinessType.HOTEL,
        then: (schema) => schema.nullable().optional(),
        otherwise: (schema) => schema.required(),
      })
      .when('baseGuests', {
        is: (value: number) => value > 0,
        then: (schema) =>
          schema.min(
            Yup.ref('baseGuests'),
            t('pageProperty.mainSettings.capacityDetails.validation.maxGuests'),
          ),
      }),
    extraGuestFee: Yup.number()
      .useNaNAsNull()
      .useNegativeZeroAsNegative()
      .min(0)
      .nullable()
      .notRequired(),
    rooms: capacityDetailsRoomsSchema().optional(),
  });

export const propertyDetailsInternalThumbnailSchema = (
  t: TFunction<Namespace<'en'>>,
) => ({
  internalThumbnail: Yup.mixed().test({
    message: t(
      'pageProperty.mainSettings.propertyDetails.internalThumbnailInvalidType',
    ),
    test: (fileList: FileList | File[] | null) => {
      if (!fileList?.length) return true;

      const [file] = Array.from(fileList);
      const isTypeValid = [
        MEDIA_TYPE.IMAGE_PNG,
        MEDIA_TYPE.IMAGE_JPEG,
        MEDIA_TYPE.IMAGE_GIF,
        MEDIA_TYPE.IMAGE_WEBP,
      ].includes(file.type);

      return isTypeValid;
    },
  }),
  internalThumbnailUrl: Yup.string().nullable().optional(),
});

export const checkInMethod = () =>
  Yup.object({
    brandName: Yup.string().optional().nullable(),
    how: Yup.mixed<BookingDotComCheckInMethodHow>().optional().nullable(),
    identifier: Yup.string().optional().nullable(),
    text: Yup.string().optional().nullable(),
    type: Yup.mixed<BookingDotComCheckInMethodType>().optional().nullable(),
    when: Yup.mixed<BookingDotComCheckInMethodWhen>().optional().nullable(),
  });

export const propertyDetailsPropertyNameSchema = () =>
  Yup.string().min(8).max(49).required();

export const propertyDetailsSchema = (
  businessType: PropertyBusinessType,
  t: TFunction<Namespace<'en'>>,
) => {
  const isHotel = businessType === PropertyBusinessType.HOTEL;
  const isUnitType = businessType === PropertyBusinessType.UNIT_TYPE;

  return Yup.object({
    ...propertyDetailsInternalThumbnailSchema(t),
    floorCount: Yup.number().integer().optional().nullable().useNaNAsNull(),
    propertyName: propertyDetailsPropertyNameSchema(),
    propertySize: Yup.number()
      .useNegativeZeroAsNegative()
      .min(0)
      .when([], {
        is: () => isHotel,
        then: (schema) => schema.optional().nullable(),
        otherwise: (schema) => schema.required(),
      }),
    propertySizeUnit: Yup.mixed<AreaUnitType>().oneOf(
      Object.values(AREA_UNIT_TYPE),
    ),
    propertyType: Yup.string().when([], {
      is: () => isUnitType,
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required(),
    }),
    weblink: Yup.string().optional().nullable(),
  });
};

export type CheckInMethodFormValues = Yup.InferType<
  ReturnType<typeof checkInMethod>
>;
