import { Row, SortingState } from '@tanstack/react-table';
import { compare } from 'utils/sortUtils';
import { SortDirection } from '../Table.types';

type ColumnNameOrFunction<TData> = keyof TData | ((arg: TData) => unknown);

const getRowValue = <TData>(
  column: ColumnNameOrFunction<TData>,
  row: Row<TData>,
) => {
  return column instanceof Function
    ? column(row.original)
    : row.original[column];
};

export const sortByColumns = <TData>(
  columnsToSort: ColumnNameOrFunction<TData>[],
) => {
  return (rowA: Row<TData>, rowB: Row<TData>): number => {
    let sortingResult = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const currentColumn of columnsToSort) {
      const valueA = getRowValue(currentColumn, rowA);
      const valueB = getRowValue(currentColumn, rowB);

      sortingResult = compare(valueA, valueB);

      if (sortingResult !== 0) break;
    }

    return sortingResult;
  };
};

/**
 * This will return the sort parameter for the API request based on the current sorting state.
 */
export const getSortParam = (
  sorting: SortingState,
  mapColumnToSortParam: {
    [key: string]: string;
  },
) => {
  const [currentSort] = sorting;
  const { id, desc } = currentSort ?? {};
  const sortParam = mapColumnToSortParam[id];

  return sortParam
    ? {
        sort: sortParam,
        direction: desc ? SortDirection.DESC : SortDirection.ASC,
      }
    : null;
};
