import { SortingState } from '@tanstack/react-table';
import { ExpectedQueryData } from 'components/table/hooks/useServerFilteredTable';
import { getSortParam } from 'components/table/utils/sorting';
import {
  GetAirbnbGuestReviewsResponse,
  AirbnbGuestReview,
} from 'models/Reviews';
import API from 'services/API';

export const AIRBNB_REVIEWS_BASE_QUERY_KEY = 'airbnb-reviews';

export const airbnbReviewsQuery = ({
  propertyUid,
  pageIndex,
  sorting,
  pageSize,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => ({
  queryKey: [
    AIRBNB_REVIEWS_BASE_QUERY_KEY,
    'list',
    propertyUid,
    { pageIndex, sorting },
  ],
  queryFn: async (): Promise<ExpectedQueryData<AirbnbGuestReview>> => {
    const sort = getSortParam(sorting, {
      guestName: 'SORT_BY_GUEST_NAME',
      rating: 'SORT_BY_RATING',
      createdAt: 'SORT_BY_DATE',
    });

    const response = await API.get<GetAirbnbGuestReviewsResponse>(
      '/api/internal/airbnb-reviews',
      {
        params: {
          propertyUid,
          _limit: pageSize,
          ...(pageIndex > 0
            ? { _cursor: btoa(`{"offset":${pageIndex * pageSize}}`) }
            : {}),
          ...(sort ? { sort: sort.sort, sortDirection: sort.direction } : {}),
        },
      },
    );

    const { guestReviews, _metadata: metadata } = response.data;

    return {
      metadata,
      data: guestReviews,
    };
  },
  enabled: !!propertyUid,
});
