import { Row } from '@tanstack/react-table';
import { parseISO, addDays, startOfDay, isAfter } from 'date-fns';
import Button from 'components/button/Button';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import { useTranslation } from 'react-i18next';
import { AirbnbGuestReview } from 'models/Reviews';

const ActionsCell = ({ row }: { row: Row<AirbnbGuestReview> }) => {
  const { date, reviewResponse } = row.original;
  const { t } = useTranslation();

  const handleViewAndRespond = () => {};

  const handleViewDetails = () => {};

  const isWithinResponseWindow = () => {
    const today = startOfDay(new Date());
    const reviewDate = startOfDay(parseISO(date));
    const responseWindowEnd = addDays(reviewDate, 30);

    // can only respond if today is before or equal to the end of response window
    return !isAfter(today, responseWindowEnd);
  };

  const shouldAllowRespond = !reviewResponse && isWithinResponseWindow();

  return (
    <PropertyEditActionWrapper tooltipId="guest-reviews-actions">
      {shouldAllowRespond ? (
        <Button
          bsStyle="link"
          data-testid="row-view-and-respond-guest-review-button"
          onClick={handleViewAndRespond}
        >
          {t(
            'pageProperty.reviews.airbnbGuest.guestReviews.table.viewAndRespond',
          )}
        </Button>
      ) : (
        <Button
          bsStyle="link"
          data-testid="row-view-details-guest-review-button"
          onClick={handleViewDetails}
        >
          {t('pageProperty.reviews.airbnbGuest.guestReviews.table.viewDetails')}
        </Button>
      )}
    </PropertyEditActionWrapper>
  );
};

export default ActionsCell;
