import styled from 'styled-components';
import theme from 'styles/theme';

export const TextFieldCharacterCounterContainer = styled.span.attrs({
  className: 'character-counter text-muted',
})`
  position: absolute;
  right: 15px;

  .glyphicon {
    margin-left: 5px;
    top: 2px;
  }

  .above-max-characters {
    color: ${theme.colors.hostfullyOrange};
  }
`;
