import BulkSaveIconSvg from 'assets/images/icons/bulk-save.svg?react';
import { BulkSaveButtonStyled } from './BulkSaveButton.styles';
import { Props } from './Button';

interface BulkSaveButtonProps extends Omit<Props, 'as'> {}

const BulkSaveButton = ({ children, ...rest }: BulkSaveButtonProps) => {
  return (
    <BulkSaveButtonStyled $colorScheme="green" {...rest}>
      <BulkSaveIconSvg />
      {children}
    </BulkSaveButtonStyled>
  );
};

export default BulkSaveButton;
