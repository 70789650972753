import { useContext } from 'react';
import { Row } from '@tanstack/react-table';
import { queryClient } from 'App';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import TableContext from 'components/table/TableContext';
import { CustomAmenity } from 'models/Amenity';
import useNotify from 'hooks/useNotify';
import { handlePaginationAfterDelete } from 'utils/pagination';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { BaseErrorResponse } from 'models/_base';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import { isNotSelectAll } from 'components/forms/multiSelectField/MultiSelectField.constants';
import { PROPERTIES_CUSTOM_AMENITIES_BASE_QUERY_KEY } from '../useCustomAmenitiesData';
import CustomAmenityDeleteModal, {
  CustomAmenityDeleteFormValues,
  deleteModalId,
} from './CustomAmenityDeleteModal';

const useDeleteCustomAmenityModal = ({ row }: { row: Row<CustomAmenity> }) => {
  const { property } = usePropertySettingsInitData();
  const { t } = useTranslation();
  const { tableInstance } = useContext(TableContext);
  const { uid: propertyUid } = useParams();
  const { openModal, closeModal } = useAppModal();
  const { notifySuccess, notifyError } = useNotify();

  const customAmenityName = row.original?.name;

  const { mutate: deleteCustomAmenity } = useAppMutation(
    (payload: {
      applyToAll?: boolean;
      propertyUids?: string[];
      customAmenity: {
        name: string;
      };
    }) => {
      return API.post('/api/internal/custom-amenities/bulk-delete', payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          PROPERTIES_CUSTOM_AMENITIES_BASE_QUERY_KEY,
          'detail',
          propertyUid,
        ]);

        handlePaginationAfterDelete(tableInstance);

        closeModal(deleteModalId);

        notifySuccess(
          t('pageProperty.amenities.customAmenities.deleteModal.success', {
            name: customAmenityName,
          }),
        );
      },
      onError: (error: BaseErrorResponse) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const handleSubmit = (values: CustomAmenityDeleteFormValues) => {
    const applyToAll = values.appliesTo === PROPERTIES_SELECTION.ALL_PROPERTIES;

    const payload = {
      ...(applyToAll
        ? { applyToAll: true }
        : {
            propertyUids: values.appliesToProperties
              .filter(isNotSelectAll)
              .map((p) => p.value),
          }),
      customAmenity: {
        uid: values.uid,
        name: values.name,
      },
    };
    deleteCustomAmenity(payload);
  };

  const openConfirmDeleteModal = () => {
    openModal({
      id: deleteModalId,
      customContent: (
        <CustomAmenityDeleteModal
          property={property}
          customAmenity={row.original}
          onSubmit={handleSubmit}
        />
      ),
    });
  };

  return {
    openConfirmDeleteModal,
  };
};

export default useDeleteCustomAmenityModal;
