import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import { useTranslation } from 'react-i18next';
import { DirectBookingReview } from 'models/Reviews';
import useOpenDirectBookingReviewModal from '../modals/useOpenDirectBookingReviewModal';
import useDeleteDirectBookingReviewModal from '../modals/useDeleteDirectBookingReviewModal';

const ActionsCell = ({ row }: { row: Row<DirectBookingReview> }) => {
  const { openDeleteModal } = useDeleteDirectBookingReviewModal();
  const { openEditModal } = useOpenDirectBookingReviewModal();
  const { t } = useTranslation();

  const handleEdit = () => {
    openEditModal(row.original);
  };

  const handleDelete = () => {
    openDeleteModal(row.original);
  };

  return (
    <PropertyEditActionWrapper tooltipId="direct-booking-review-actions">
      <Button
        bsStyle="link"
        data-testid="row-edit-direct-booking-review-button"
        onClick={handleEdit}
      >
        {t('common.edit')}
      </Button>
      <Button
        bsStyle="link"
        data-testid="row-delete-direct-booking-review-button"
        onClick={handleDelete}
      >
        {t('common.delete')}
      </Button>
    </PropertyEditActionWrapper>
  );
};

export default ActionsCell;
