import { useContext } from 'react';
import { queryClient } from 'App';
import useAppModal from 'hooks/useAppModal';
import useAppMutation from 'hooks/useAppMutation';
import { HouseRule } from 'models/HouseRules';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from 'services/API';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { ObjectUidFetchType } from 'models/_base';
import { Property } from 'models/Properties';
import useNotify from 'hooks/useNotify';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import { handlePaginationAfterDelete } from 'utils/pagination';
import TableContext from 'components/table/TableContext';
import { HOUSE_RULES_BASE_QUERY_KEY } from '../HouseRules.utils';
import HouseRulesDeleteModal, {
  houseRuleDeleteModalId,
  HouseRulesDeleteFormSchemaType,
} from './HouseRulesDeleteModal';

const useDeleteModal = ({ houseRule }: { houseRule: HouseRule }) => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const { notifyError } = useNotify();
  const { openModal, closeModal } = useAppModal();
  const { tableInstance } = useContext(TableContext);

  const mutationKey = ['house-rule-delete', houseRule?.uid];

  const { mutateAsync: deleteHouseRule } = useAppMutation(
    mutationKey,
    async ({
      uid,
      objectUidFetchType,
      objectUids,
    }: {
      uid: HouseRule['uid'];
      objectUidFetchType: ObjectUidFetchType;
      objectUids: Property['uid'][];
    }) => {
      await API.delete('api/internal/house-rules/bulk-delete', {
        data: {
          uid,
          objectUidFetchType,
          objectUids,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          HOUSE_RULES_BASE_QUERY_KEY,
          'all',
          propertyUid,
        ]);

        handlePaginationAfterDelete(tableInstance);

        closeModal(houseRuleDeleteModalId);
      },
      onError: (error: any) => {
        notifyError(
          getErrorMessage({
            apiErrorCode: error.response.data?.apiErrorCode,
            apiErrorMessage: error.response.data?.apiErrorMessage,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      },
    },
  );

  const handleDelete = async (values: HouseRulesDeleteFormSchemaType) => {
    const { appliesTo, appliesToProperties = [] } = values;

    const applyToAllProperties =
      appliesTo === PROPERTIES_SELECTION.ALL_PROPERTIES;

    // Remove select all option
    const appliesToPropertiesFiltered = appliesToProperties.filter(
      (item) => item.value !== 'all',
    );

    const objectUidFetchType = applyToAllProperties
      ? ObjectUidFetchType.ALL
      : ObjectUidFetchType.SPECIFIC;

    const objectUids = applyToAllProperties
      ? []
      : appliesToPropertiesFiltered.map((item) => item.value);

    await deleteHouseRule({
      uid: houseRule?.uid,
      objectUidFetchType,
      objectUids,
    });
  };

  const openBulkDeleteModal = () => {
    openModal({
      id: houseRuleDeleteModalId,
      customContent: (
        <HouseRulesDeleteModal
          onSubmit={handleDelete}
          mutationKey={mutationKey}
          houseRule={houseRule}
        />
      ),
    });
  };

  return {
    openBulkDeleteModal,
  };
};

export default useDeleteModal;
