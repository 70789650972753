import { Col, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFetchAgencyChannelSettings from 'components/domain/channelSettings/useFetchAgencyChannelSettings';
import LeadChannelIcon from 'components/domain/lead/LeadChannelIcon';
import TextField from 'components/forms/textField/TextField';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { Channel } from 'models/Channels';
import { LeadChannel } from 'models/Leads';
import { PropertyBusinessType } from 'models/Properties';
import {
  FormSectionColumn,
  SectionTitleContainer,
} from 'pages/property/common/Common.styles';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';
import { BookingDotComCollapseStyled } from './BookingDotComSettings.styles';
import RoomTypeField from './RoomTypeField';
import RoomNameField from './RoomNameField';
import PrimaryContactField from './PrimaryContactField';
import StatusField from './StatusField';
import CancellationPolicyField from './CancellationPolicyField';
import CheckInMethodField from './checkInMethod/CheckInMethodField';

const BookingDotComSettings = () => {
  const { t } = useTranslation();
  const { property } = usePropertySettingsInitData();
  const { getCopyAddonIcon } = useCopyToClipboard();
  const {
    bookingDotComSettings: { hotelId, roomId },
  } = useWatch<PropertySettingsMainTabFormValues>();

  const { channelSettings, isFetching: isLoadingChannelSettings } =
    useFetchAgencyChannelSettings(true);

  const isBookingDotComActive = !!channelSettings?.find(
    ({ enumId }) => enumId === Channel.booking_com,
  )?.isActive;

  if (!isBookingDotComActive || isLoadingChannelSettings) {
    return null;
  }

  const isUnitType = property?.businessType === PropertyBusinessType.UNIT_TYPE;
  const isNotHotel = property?.businessType !== PropertyBusinessType.HOTEL;
  const isNotMultiUnitProperty = !isUnitType && isNotHotel;
  const isCancellationPolicyFieldVisible = isNotMultiUnitProperty;
  const isPrimaryContactFieldVisible = !isUnitType;
  const isCheckInMethodFieldVisible = !isUnitType;

  const sectionTitle = (
    <SectionTitleContainer>
      <LeadChannelIcon channel={LeadChannel.BOOKING_COM} />
      {t('pageProperty.mainSettings.bookingDotComSettings.sectionTitle')}
    </SectionTitleContainer>
  );

  return (
    <BookingDotComCollapseStyled
      data-testid="booking-dot-com-settings"
      defaultExpanded
      label={sectionTitle}
      variant="card"
    >
      <fieldset>
        <Row>
          <FormSectionColumn>
            <TextField
              label={t(
                'pageProperty.mainSettings.bookingDotComSettings.hotelId',
              )}
              name="bookingDotComSettings.hotelId"
              colSmInput={7}
              colSmLabel={5}
              disabled
              rightAddons={[getCopyAddonIcon(hotelId)]}
            />
            {isNotHotel && (
              <>
                <TextField
                  label={t(
                    'pageProperty.mainSettings.bookingDotComSettings.roomId',
                  )}
                  name="bookingDotComSettings.roomId"
                  colSmInput={7}
                  colSmLabel={5}
                  disabled
                  rightAddons={[getCopyAddonIcon(roomId)]}
                />
                <StatusField />
              </>
            )}
            {isNotMultiUnitProperty && (
              <>
                <RoomTypeField />
                <RoomNameField />
              </>
            )}
          </FormSectionColumn>
          <FormSectionColumn>
            {isUnitType && (
              <>
                <RoomTypeField />
                <RoomNameField />
              </>
            )}
            {isPrimaryContactFieldVisible && <PrimaryContactField />}
            {isCheckInMethodFieldVisible && (
              <>
                <CheckInMethodField name="primaryCheckInMethod" />
                <CheckInMethodField name="alternativeCheckInMethod" />
              </>
            )}
          </FormSectionColumn>
        </Row>
        {isCancellationPolicyFieldVisible && (
          <Row>
            <Col xs={12}>
              <CancellationPolicyField />
            </Col>
          </Row>
        )}
      </fieldset>
    </BookingDotComCollapseStyled>
  );
};

export default BookingDotComSettings;
