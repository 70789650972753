import { ChangeEvent, useEffect } from 'react';
import Button from 'components/button/Button';
import useFetchDescriptions from 'components/domain/property/useFetchDescriptions';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import { getLanguageCode, getLanguageName } from 'i18n/i18n.utils';
import { PropertyBusinessType } from 'models/Properties';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { LanguageCodeUnderscored } from 'i18n/internationalization.types';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import { HeaderContainer } from './PropertySettingsDescriptionsTabHeader.styles';
import useManageLanguagesModal from './ManageLanguagesModal/useManageLanguagesModal';

const PropertySettingsDescriptionsTabHeader = () => {
  const { t, i18n } = useTranslation();
  const { openManageLanguageModal } = useManageLanguagesModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const { property, isLoading: isLoadingProperty } =
    usePropertySettingsInitData();

  const { uid: propertyUid, businessType } = property;
  const isHotel = businessType === PropertyBusinessType.HOTEL;
  const language = searchParams.get('language');

  const { data: descriptions, isLoading: isLoadingDescriptions } =
    useFetchDescriptions({
      propertyUid,
      isHotel,
    });

  const descriptionLanguages =
    descriptions
      ?.map((desc) => ({
        label: getLanguageName(i18n.language, getLanguageCode(desc.locale)),
        value: desc.locale,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const onLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSearchParams(
      { language: e.target.value },
      {
        replace: true,
      },
    );
  };

  useEffect(() => {
    // Set default language if it's not in the list or if it's not defined
    if (
      !isLoadingDescriptions &&
      !descriptionLanguages?.some((lang) => lang.value === language)
    ) {
      setSearchParams(
        { language: LanguageCodeUnderscored.EN_US },
        {
          replace: true,
        },
      );
    }
  }, [language, descriptions]);

  if (isLoadingDescriptions || isLoadingProperty) {
    return (
      <HeaderContainer>
        <LoadingPlaceholder linesCount={2.5} width="135px" />
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer>
      {descriptionLanguages?.length > 1 && (
        <select
          className="form-control"
          name="language"
          required
          value={language}
          onChange={onLanguageChange}
          data-testid="description-language-select"
        >
          {descriptionLanguages.map((lang) => (
            <option key={lang.value} value={lang.value}>
              {lang.label}
            </option>
          ))}
        </select>
      )}

      <Button
        $colorScheme="green"
        onClick={() => openManageLanguageModal({ property })}
        data-testid="manage-languages-button"
      >
        {t('pageProperty.descriptions.header.manageLanguages')}
      </Button>
    </HeaderContainer>
  );
};

export default PropertySettingsDescriptionsTabHeader;
