import SectionWrapper from '../../../common/SectionWrapper';
import DirectBookingReviewsSectionHeader from './DirectBookingReviewsSectionHeader';
import DirectBookingReviewsTable from './DirectBookingReviewsTable';

const DirectBookingReviewsSection = () => {
  return (
    <SectionWrapper>
      <section data-testid="direct-booking-reviews-section">
        <DirectBookingReviewsSectionHeader />
        <DirectBookingReviewsTable />
      </section>
    </SectionWrapper>
  );
};

export default DirectBookingReviewsSection;
