import { useEffect, useState } from 'react';
import useFetchPropertyChannelSettings from 'components/domain/channelSettings/useFetchPropertyChannelSettings';
import usePropertySettingsInitData from 'pages/property/usePropertySettingsInitData';
import { Channel } from 'models/Channels';
import useFetchAgencyChannelSettings from 'components/domain/channelSettings/useFetchAgencyChannelSettings';

export interface EnabledChannel {
  channel: Channel;
  isActive: boolean;
  alwaysEnabled?: boolean;
}

interface UseEnabledChannelsArgs {
  alwaysEnabledChannels?: Channel[];
  defaultEnabledChannels?: Channel[];
  supportedChannels: Channel[];
}

const useEnabledChannels = ({
  alwaysEnabledChannels,
  defaultEnabledChannels,
  supportedChannels,
}: UseEnabledChannelsArgs) => {
  const initialChannels = [
    ...(alwaysEnabledChannels ?? []),
    ...(defaultEnabledChannels ?? []),
  ];
  const initiallyEnabledChannels = initialChannels.map((channel) => ({
    channel,
    isActive: true,
  }));

  const [channels, setChannels] = useState<EnabledChannel[]>([]);

  const {
    property: { uid: propertyUid },
    isLoading: isFetchingPropertySettings,
  } = usePropertySettingsInitData();
  const {
    channelSettings: agencyChannelSettings,
    isFetching: isFetchingChannelSettings,
  } = useFetchAgencyChannelSettings();
  const {
    channelSettings: propertyChannelSettings,
    isFetching: isFetchingPropertyChannelSettings,
  } = useFetchPropertyChannelSettings(propertyUid);

  const isFetching =
    isFetchingPropertySettings ||
    isFetchingChannelSettings ||
    isFetchingPropertyChannelSettings;

  useEffect(() => {
    if (!agencyChannelSettings || !propertyChannelSettings) {
      return;
    }

    const enabledChannels = [...initiallyEnabledChannels];

    agencyChannelSettings.forEach((agencyChannel) => {
      if (
        initialChannels.includes(agencyChannel.enumId) ||
        !supportedChannels.includes(agencyChannel.enumId) ||
        !agencyChannel.isActive
      ) {
        return;
      }

      enabledChannels.push({
        channel: agencyChannel.enumId,
        // if channel is enabled at agency level, but not on property level, it should still show but be inactive:
        isActive: !!propertyChannelSettings.find(
          (propertyChannel) =>
            propertyChannel.enumId === agencyChannel.enumId &&
            propertyChannel.isActive,
        ),
      });
    });

    setChannels(enabledChannels);
  }, [agencyChannelSettings, propertyChannelSettings]);

  const toggleChannel = (channel: Channel) => {
    if (alwaysEnabledChannels?.includes(channel)) {
      return;
    }

    const updatedChannels = channels.map((c) => {
      if (c.channel === channel) {
        return {
          ...c,
          isActive: !c.isActive,
        };
      }

      return c;
    });

    setChannels(updatedChannels);
  };

  return {
    activeChannels: channels
      .filter(({ isActive }) => isActive)
      .map(({ channel }) => channel),
    activeChannelsAtPropertyLevel: (propertyChannelSettings ?? [])
      .filter(({ isActive }) => isActive)
      .map(({ enumId }) => enumId),
    channels,
    toggleChannel,
    isFetching,
  };
};

export default useEnabledChannels;
