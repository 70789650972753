import { Trans } from 'react-i18next';
import PageHeader from 'components/pageHeader/PageHeader';
import PropertyEditPermissionInfo from '../common/PropertyEditPermissionInfo';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import DirectBookingReviewsSection from './sections/directBookingReviews/DirectBookingReviewsSection';
import AirbnbGuestReviewsSection from './sections/airbnbGuestReviews/AirbnbGuestReviewsSection';

const PropertySettingsReviewsTab = () => {
  const { property, isLoading: isLoadingInitData } =
    usePropertySettingsInitData();

  return (
    <div>
      <PropertyEditPermissionInfo />

      <PageHeader
        className="tab-title"
        title={
          <Trans
            i18nKey="pageProperty.reviews.tabTitle"
            values={{ propertyName: property?.name }}
            components={{ 1: <span className="green" /> }}
          />
        }
        isLoading={isLoadingInitData}
        noDivider
      />

      <DirectBookingReviewsSection />

      <AirbnbGuestReviewsSection />
    </div>
  );
};

export default PropertySettingsReviewsTab;
