import { useTranslation } from 'react-i18next';
import {
  CURRENCIES_WITHOUT_NONE,
  Currency,
  CurrencyWithoutNone,
  currencyToFlagCode,
} from 'models/Currency';
import { getLanguageCode } from 'i18n/i18n.utils';
import { LanguageCode } from 'i18n/internationalization.types';
import { orderBy } from 'utils/arrayUtils';
import { countryFlags } from 'utils/constants/flags';
import SelectField from '../selectField/SelectField';
import { SelectFieldProps } from '../selectField/SelectField.types';

export interface CurrencySelectFieldProps
  extends Omit<SelectFieldProps, 'options' | 'optionGroups'> {
  excludeCurrencies?: Currency[];
}

export interface CurrencyOption {
  value: CurrencyWithoutNone;
  label: string;
}

const getLocale = (language: string) => {
  if (
    language &&
    Object.values(LanguageCode).includes(language as LanguageCode)
  ) {
    return language;
  }

  // Gets first part of the locale, i.e 'en_US' becomes just 'en'
  const languageCode = getLanguageCode(language);

  if (languageCode && Object.values(LanguageCode).includes(languageCode)) {
    return languageCode;
  }

  return LanguageCode.EN;
};

const CurrencySelectField = ({
  excludeCurrencies,
  ...props
}: CurrencySelectFieldProps) => {
  const {
    i18n: { language },
  } = useTranslation();
  const locale = getLocale(language);
  const formatter = new Intl.DisplayNames(locale, { type: 'currency' });

  const options = CURRENCIES_WITHOUT_NONE.filter(
    (currency) => !excludeCurrencies?.includes(currency) ?? true,
  ).map((value) => {
    const flagEmoji = countryFlags[currencyToFlagCode[value]].emoji;

    return { value, label: `${flagEmoji} ${formatter.of(value)}` };
  });

  const sortedOptions = orderBy(options, [
    { field: ({ label }) => label.substring(label.indexOf(' ')), order: 'asc' },
  ]);

  return <SelectField options={sortedOptions} {...props} />;
};

CurrencySelectField.defaultProps = {
  excludeCurrencies: undefined,
};

export default CurrencySelectField;
