import { useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'components/loader/Loader';
import { useWatch } from 'react-hook-form';
import LeadListItem from 'pages/dashboard/leadsList/leadListItem/LeadListItem';
import useLeadListItemActions from 'pages/dashboard/leadsList/leadListItem/useLeadListItemActions';
import LeadListItemPlaceHolder from 'pages/dashboard/leadsList/leadListItem/LeadListItemPlaceHolder';
import useRefreshOnLeadEvents from 'components/domain/lead/useRefreshOnLeadEvents';
import useAgencySettings from 'hooks/useAgencySettings';
import { isDuplicate } from 'utils/lead/leadUtils';
import DashboardContext from '../DashboardContext';
import { Container, EndMessageParagraph, Item } from './LeadsList.style';
import useDashboardLeads from './useDashboardLeads';
import NoLeadsMessage from './NoLeadsMessage';

const LeadsList = () => {
  const { hideProcessedLeads } = useWatch();
  const { leadModeType, isNewTab, isCheckinTab, isCheckoutTab } =
    useContext(DashboardContext);

  const { isLeadAssignmentEnabled } = useAgencySettings();

  const { hasMoreLeads, loadMoreLeads, leads, refetch, isFirstLoading } =
    useDashboardLeads({
      hideProcessedLeads,
      type: leadModeType,
    });

  const { signLead, isActionsLoading, ignoreLead } =
    useLeadListItemActions(refetch);

  useRefreshOnLeadEvents({ refreshFn: refetch });

  return (
    <Container data-testid="dashboard-lead-list">
      <InfiniteScroll
        dataLength={leads.length}
        next={loadMoreLeads}
        hasMore={hasMoreLeads}
        loader={<Loader />}
        scrollableTarget="app-page-content"
        endMessage={
          <EndMessageParagraph>
            {!isFirstLoading && <NoLeadsMessage isLeads={!!leads?.length} />}
          </EndMessageParagraph>
        }
      >
        {isFirstLoading && (
          <>
            <LeadListItemPlaceHolder
              assigneePlaceHolder={isNewTab}
              statusPlaceholder={isCheckinTab || isCheckoutTab}
            />
            <LeadListItemPlaceHolder
              assigneePlaceHolder={isNewTab}
              statusPlaceholder={isCheckinTab || isCheckoutTab}
            />
            <LeadListItemPlaceHolder
              assigneePlaceHolder={isNewTab}
              statusPlaceholder={isCheckinTab || isCheckoutTab}
            />
          </>
        )}

        {leads.map((lead) => (
          <Item key={`${lead.uid}-${leadModeType}`}>
            <LeadListItem
              leadData={{
                ...lead,
                onSign: signLead,
                disableActions: isActionsLoading,
                onIgnore: ignoreLead,
                leadMode: leadModeType,
                isLowOpacity:
                  (lead.wasProcessed && isNewTab) || isDuplicate(lead),
                showStatusRing: isNewTab,
                showGuestCounter: isNewTab,
                showBookAndInquiryHeader: isNewTab,
                showCheckinInHeader: isCheckinTab,
                showCheckoutInHeader: isCheckoutTab,
                showLeadStatusTags: isNewTab,
                showLeadStatusIcons: isCheckinTab || isCheckoutTab,
                showAssigneePhoto: isNewTab,
                showProcessActions: isNewTab && !isDuplicate(lead),
                showAssignToMe:
                  isNewTab && !isDuplicate(lead) && isLeadAssignmentEnabled,
                showMessageAction: isCheckinTab || isCheckoutTab,
              }}
            />
          </Item>
        ))}
      </InfiniteScroll>
    </Container>
  );
};

export default LeadsList;
