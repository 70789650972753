import { ComponentPropsWithoutRef } from 'react';
import usePropertiesUpdatingAllowed from 'components/domain/property/usePropertiesUpdatingAllowed';

interface SectionWrapperProps extends ComponentPropsWithoutRef<'fieldset'> {}

const SectionWrapper = ({ children, ...props }: SectionWrapperProps) => {
  const propertiesUpdatingAllowed = usePropertiesUpdatingAllowed();

  return (
    <fieldset disabled={!propertiesUpdatingAllowed} {...props}>
      {children}
    </fieldset>
  );
};

export default SectionWrapper;
