import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import useAppModal from 'hooks/useAppModal';
import useNotify from 'hooks/useNotify';
import { DirectBookingReview } from 'models/Reviews';
import useDirectBookingReviewMutations from 'components/domain/review/useDirectBookingReviewMutations';
import { BaseErrorResponse } from 'models/_base';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import { PROPERTIES_REVIEWS_BASE_QUERY_KEY } from '../DirectBookingReviews.utils';

const useDeleteDirectBookingReviewModal = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { notifyError } = useNotify();
  const { openConfirmModal } = useAppModal();
  const { deleteDirectBookingReview } = useDirectBookingReviewMutations();

  const openDeleteModal = (review: DirectBookingReview) => {
    openConfirmModal({
      title: t('pageProperty.reviews.directBooking.deleteModal.title'),
      body: (
        <>
          {t('pageProperty.reviews.directBooking.deleteModal.description', {
            author: review.author,
          })}
          <p>{t('pageProperty.reviews.directBooking.deleteModal.warning')}</p>
        </>
      ),
      onConfirmAsync: () =>
        deleteDirectBookingReview({
          uid: review.uid,
        })
          .then(() => {
            queryClient.invalidateQueries([PROPERTIES_REVIEWS_BASE_QUERY_KEY]);
          })
          .catch((error: BaseErrorResponse) => {
            notifyError(
              getErrorMessage({
                apiErrorMessage: error.response?.data.apiErrorMessage,
                apiErrorCode: error.response?.data.apiErrorCode,
                baseKey: 'pageProperty.apiErrorCodes.',
                t,
              }),
            );
          }),
      confirmLabel: t('common.delete'),
      confirmColor: 'danger',
    });
  };

  return {
    openDeleteModal,
  };
};

export default useDeleteDirectBookingReviewModal;
