import { Channel } from 'models/Channels';
import {
  ChannelFilterContainer,
  ChannelItemButtonsContainer,
} from './ChannelFilter.styles';
import ChannelItemButton from './ChannelItemButton';
import { EnabledChannel } from './useEnabledChannels';

const ChannelFilter = ({
  channels,
  label,
  toggleChannel,
}: {
  channels: EnabledChannel[];
  label: string;
  toggleChannel: (channel: Channel) => void;
}) => {
  const handleChannelClick = (channel: Channel) => {
    // should not allow to disable all channels, at least one channel should be always active:
    if (
      channels.filter((c) => c.isActive).length === 1 &&
      channels.find((c) => c.channel === channel)?.isActive
    ) {
      return;
    }

    toggleChannel(channel);
  };

  return (
    <ChannelFilterContainer>
      <h2>{label}</h2>

      <ChannelItemButtonsContainer>
        {channels.map(({ channel, isActive }) => {
          return (
            <ChannelItemButton
              key={channel}
              channel={channel}
              isActive={isActive}
              onClick={() => handleChannelClick(channel)}
            />
          );
        })}
      </ChannelItemButtonsContainer>
    </ChannelFilterContainer>
  );
};

export default ChannelFilter;
