import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { getDateTimeLocale } from 'i18n/dateTimeInternationalization';
import { Column } from '@tanstack/react-table';

interface FormattedDateCellProps<T> {
  getValue: () => Date | string | null;
  column: Column<T>;
}

/**
 * @description This cell is used to format a date based on the `meta.dateFormat` property of the column.
 * If the `meta.dateFormat` is not provided, it will default to 'yyyy-MM-dd'.
 */
const FormattedDateCell = <T,>({
  getValue,
  column,
}: FormattedDateCellProps<T>) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const value = getValue();
  const date = typeof value === 'string' ? parseISO(value) : value;

  const dateFormat = column.columnDef.meta?.dateFormat ?? 'yyyy-MM-dd';

  if (!date) {
    return t('common.notApplicable');
  }

  return format(date, dateFormat, { locale: getDateTimeLocale(language) });
};

export default FormattedDateCell;
