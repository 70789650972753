import useAppQuery from 'hooks/useAppQuery';
import { GetAirbnbRatingStatsResponse } from 'models/Reviews';
import { useParams } from 'react-router-dom';
import API from 'services/API';

const useAirbnbRatingStats = () => {
  const { uid: propertyUid } = useParams();

  const { data, isLoading } = useAppQuery(
    ['airbnbRatingStats'],
    async () => {
      const response = await API.get<GetAirbnbRatingStatsResponse>(
        `/v3/airbnb-rating-stats/${propertyUid}`,
      );

      return response.data?.rating_stats;
    },
    {
      enabled: !!propertyUid,
    },
  );

  return {
    airbnbRatingStats: data,
    isLoading,
  };
};

export default useAirbnbRatingStats;
