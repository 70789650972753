import { Trans, useTranslation } from 'react-i18next';
import { DescriptionFields } from '../PropertySettingsDescriptionsTab.types';

const useLabelTooltipText = (fieldName: DescriptionFields) => {
  const { t } = useTranslation();
  const tooltips: { [K in DescriptionFields]: React.ReactNode } = {
    access: t('pageProperty.descriptions.form.accessTooltip'),
    houseManual: (
      <Trans
        i18nKey="pageProperty.descriptions.form.houseManualTooltip"
        components={{
          bold: <b />,
          link1: (
            <a
              aria-label="Hostfully Guidebook"
              href="https://help.hostfully.com/en/articles/1687360-connect-your-guidebook-account-with-the-property-management-platform"
              target="_blank"
              rel="noreferrer"
            />
          ),
        }}
      />
    ),
    interaction: t('pageProperty.descriptions.form.interactionTooltip'),
    name: t('pageProperty.descriptions.form.nameTooltip'),
    neighbourhood: t('pageProperty.descriptions.form.neighbourhoodTooltip'),
    notes: t('pageProperty.descriptions.form.notesTooltip'),
    shortSummary: t('pageProperty.descriptions.form.shortSummaryTooltip'),
    space: t('pageProperty.descriptions.form.spaceTooltip'),
    summary: t('pageProperty.descriptions.form.summaryTooltip'),
    transit: t('pageProperty.descriptions.form.transitTooltip'),
  };

  return tooltips[fieldName];
};

export default useLabelTooltipText;
