import { toUpperFirst } from 'utils/stringUtils';
import {
  HotelMainSettingsData,
  HotelMainSettingsUpdatePayload,
  PropertyMainSettingsData,
} from './PropertySettingsMainTab.types';
import { PropertyMainSettingsUpdatePayload } from './PropertySettingsMainTab.schema';

const hotelToPropertyMainSettingsDataSectionValueConverter = {
  hotelAddress: (mainSettingsData) => [
    'propertyAddress',
    mainSettingsData.hotelAddress,
  ],
  hotelBookingDotComSettings: (mainSettingsData) => {
    const {
      alternative,
      bookingDotComHotelId,
      primary,
      ...bookingDotComSettings
    } = mainSettingsData.hotelBookingDotComSettings;

    return [
      'bookingDotComSettings',
      {
        ...bookingDotComSettings,
        alternativeCheckInMethod: alternative,
        hotelId: bookingDotComHotelId,
        primaryCheckInMethod: primary,
      },
    ];
  },
  hotelDetails: (mainSettingsData) => [
    'propertyDetails',
    mainSettingsData.hotelDetails,
  ],
} satisfies {
  [K in keyof HotelMainSettingsData]?: (
    mainSettingsData: HotelMainSettingsData,
  ) => [
    keyof PropertyMainSettingsData,
    Partial<PropertyMainSettingsData[keyof PropertyMainSettingsData]>,
  ];
};

/**
 * Converts main settings for hotels to a compatible property main settings (to make it easier to work with hotels using
 * the shared main settings components).
 */
const propertyToHotelMainSettingsUpdatePayloadSectionValueConverter = {
  propertyAddress: (updatePayload) => [
    'hotelAddress',
    updatePayload.propertyAddress,
  ],
  bookingDotComSettings: (updatePayload) => {
    const {
      hotelId,
      alternativeCheckInMethod,
      primaryCheckInMethod,
      ...bookingDotComSettings
    } = updatePayload.bookingDotComSettings;

    const {
      type: alternativeCheckInMethodType,
      ...restAlternativeCheckInMethod
    } = alternativeCheckInMethod || {};
    const { type: primaryCheckInMethodType, ...restPrimaryCheckInMethod } =
      primaryCheckInMethod || {};

    return [
      'hotelBookingDotComSettings',
      {
        ...bookingDotComSettings,
        alternativeCheckInMethod: alternativeCheckInMethod
          ? {
              ...restAlternativeCheckInMethod,
              checkinMethod: alternativeCheckInMethodType,
            }
          : undefined,
        bookingDotComHotelId: hotelId,
        primaryCheckInMethod: primaryCheckInMethod
          ? {
              ...restPrimaryCheckInMethod,
              checkinMethod: primaryCheckInMethodType,
            }
          : undefined,
      },
    ];
  },
  propertyDetails: (updatePayload) => [
    'hotelDetails',
    updatePayload.propertyDetails as unknown as HotelMainSettingsData['hotelDetails'],
  ],
} satisfies {
  [K in keyof PropertyMainSettingsUpdatePayload]?: (
    payload: PropertyMainSettingsUpdatePayload,
  ) => [
    keyof HotelMainSettingsUpdatePayload,
    Partial<
      HotelMainSettingsUpdatePayload[keyof HotelMainSettingsUpdatePayload]
    >,
  ];
};

export const convertHotelToPropertyMainSettings = (
  data: Partial<HotelMainSettingsData>,
): PropertyMainSettingsData => {
  const { hotelUid, ..._data } = data;

  return Object.entries(_data).reduce(
    (acc, [hotelSectionName, sectionValue]) => {
      const sectionConverter =
        hotelToPropertyMainSettingsDataSectionValueConverter[hotelSectionName];

      if (sectionConverter) {
        const [sectionName, updatedSectionValue] = sectionConverter(data);
        return {
          ...acc,
          [sectionName]: updatedSectionValue,
        };
      }

      const [, _sectionName] = hotelSectionName.split('hotel');
      const sectionName = _sectionName.replace(/^[A-z]/, (s1) =>
        s1.toLowerCase(),
      );

      return {
        ...acc,
        [sectionName]: sectionValue,
      };
    },
    {
      propertyUid: hotelUid,
      airbnbSettings: {},
      bookingSettings: {},
      capacityDetails: {},
    },
  ) as PropertyMainSettingsData;
};

/**
 * Converts main settings for property to a compatible hotel main settings, so that we can send the correct payload to
 * the hotels saving endpoint after using the hotel main settings data in the form converted to a property main settings
 * data.
 */
export const convertPropertyToHotelMainSettingsUpdatePayload = (
  data: PropertyMainSettingsUpdatePayload,
): HotelMainSettingsUpdatePayload => {
  // Ignores airbnbSettings, bookingSettings, and capacityDetails as those are not part of hotels.
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    airbnbSettings,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bookingSettings,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    capacityDetails,
    ..._data
  } = data;

  return Object.entries(_data).reduce(
    (acc, [propertySectionName, sectionValue]) => {
      const sectionConverter =
        propertyToHotelMainSettingsUpdatePayloadSectionValueConverter[
          propertySectionName
        ];

      if (sectionConverter) {
        const [sectionName, updatedSectionValue] = sectionConverter(data);
        return {
          ...acc,
          [sectionName]: updatedSectionValue,
        };
      }

      return {
        ...acc,
        [`hotel${toUpperFirst(propertySectionName, { lowerCaseRest: false })}`]:
          sectionValue,
      };
    },
    {},
  ) as HotelMainSettingsUpdatePayload;
};
