import { useContext } from 'react';
import { format, isValid } from 'date-fns';
import useFetchLeadsPaginated from 'components/domain/lead/useFetchLeadsPaginated';
import { LeadStatus } from 'models/Leads';
import { Property } from 'models/Properties';
import { getPropertiesFilterConditions } from 'utils/pipeline/pipelineUtils';
import PipelineContext from './PipelineContext';
import { PipelineFilter } from './Pipeline.types';

function getLeadsQueryConditions(
  filter: PipelineFilter,
  properties: Property[],
) {
  const {
    channels,
    checkInFrom,
    checkInTo,
    groupedStatus,
    sortStrategy,
    statuses,
    uids,
    userUids,
  } = filter;

  if (uids?.length) {
    return `uids:[${Array.from(uids)
      .map((uid) => `"${uid}"`)
      .join(',')}]`;
  }

  return `
    ${channels?.length ? `channels:[${Array.from(channels).join(',')}],` : ''}
    ${
      isValid(checkInFrom)
        ? `checkin_from:"${format(checkInFrom, 'yyyy-MM-dd')}",`
        : ''
    }
    ${
      isValid(checkInTo)
        ? `checkin_to:"${format(checkInTo, 'yyyy-MM-dd')}",`
        : ''
    }
    ${groupedStatus ? `groupedStatus:${groupedStatus},` : ''}
    ${getPropertiesFilterConditions(filter, properties)}
    ${statuses?.length ? `statuses:[${statuses.join(',')}],` : ''}
    ${
      userUids?.length
        ? `userUids:[${Array.from(userUids)
            .map((userUid) => `"${userUid}"`)
            .join(',')}],`
        : ''
    }
    sortStrategy:"${sortStrategy}"
    statusesToExclude:[${LeadStatus.BLOCKED}]
  `.trim();
}

const usePipelineLeadsListData = () => {
  const { filter, properties } = useContext(PipelineContext);

  const conditions = getLeadsQueryConditions(filter, properties);
  const fields = `assignee{bestProfile, uid, picture},
      availablePipelineActions,
      channel{bestProfile, channel},
      uid,
      firstName,
      lastName, 
      email, 
      source,
      leadType,
      bookedDateTimeString,
      createdDateTimeString,
      checkInLocalDateTime, 
      checkOutLocalDateTime, 
      childrenCount, 
      adultCount, 
      petCount, 
      infantCount, 
      status,
      referrer,
      hasCompletedPreArrivalForm,
      agreementSigned,
      autoRefundFail,
      autoRefundOk,
      wasSecurityDepositReceived,
      wereCheckInInstructionsSent,
      paidInFull,
      property{uid, name, businessType, mainPicture {
        largeThumbnail
      }},
      creator{type},
      countdown {
        color,
        countdownMinutes,
        countdownPercentage,
        overdue,
        visualCountdownValue
      },
      riskScore,
      order {
        balance,
        currency,
        currencySymbol,
        securityDeposit,
        totalAmount,
        uid
      },
      hotel { 
        name,
        uid 
      },
      duplicateOfLeadUid`;

  const {
    error,
    isFetching,
    isInitialFetching,
    leads,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useFetchLeadsPaginated({
    conditions,
    fields,
  });

  return {
    error,
    isFetching,
    isInitialFetching,
    leads,
    hasMoreLeads: hasNextPage,
    loadMoreLeads: fetchNextPage,
    refetch,
  };
};

export default usePipelineLeadsListData;
