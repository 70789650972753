import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styles/theme';

export const AppliesToFieldContainer = styled(Row).attrs({
  className: 'applies-to-field-container',
})`
  .radio {
    margin-bottom: 10px;
  }

  .radio + .radio {
    margin-top: -5px;
    padding-top: 0px;
  }

  .property-multi-select-filter {
    > .row {
      margin: 0;
    }
  }
`;

export const CustomAmenitiesDeleteModalContainer = styled.div`
  .applies-to-field-container {
    margin-top: 10px;
  }

  strong {
    color: ${theme.colors.fontGreen};
    font-weight: 400;
  }
`;
