import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/selectField/SelectField';
import { BookingDotComCheckInMethodType } from 'models/bookingDotCom/CheckInMethods';
import { orderBy } from 'utils/arrayUtils';

interface CheckInMethodTypeFieldProps {
  name: 'alternativeCheckInMethod' | 'primaryCheckInMethod';
}

const CHECK_IN_METHOD_TYPE_ORDER = [
  BookingDotComCheckInMethodType.NONE,
  BookingDotComCheckInMethodType.RECEPTION,
  BookingDotComCheckInMethodType.SOMEONE_WILL_MEET,
  BookingDotComCheckInMethodType.SECRET_SPOT,
  BookingDotComCheckInMethodType.DOOR_CODE,
  BookingDotComCheckInMethodType.LOCK_BOX,
  BookingDotComCheckInMethodType.INSTRUCTION_WILL_SEND,
  BookingDotComCheckInMethodType.INSTRUCTION_CONTACT_US,
  BookingDotComCheckInMethodType.OTHER,
];

const CheckInMethodTypeField = ({ name }: CheckInMethodTypeFieldProps) => {
  const { t } = useTranslation();

  const sortedCheckInMethodTypeValues = orderBy(
    Object.values(BookingDotComCheckInMethodType),
    [
      {
        field: (value) => CHECK_IN_METHOD_TYPE_ORDER.indexOf(value),
        order: 'asc',
      },
    ],
  );

  const checkInMethodTypeOptions = sortedCheckInMethodTypeValues.map(
    (value) => ({
      label: t(
        `pageProperty.mainSettings.bookingDotComSettings.checkInMethodType.${value}`,
      ),
      value,
    }),
  );

  return (
    <SelectField
      label={t(`pageProperty.mainSettings.bookingDotComSettings.${name}.type`)}
      name={`bookingDotComSettings.${name}.type`}
      options={checkInMethodTypeOptions}
      colSmInput={7}
      colSmLabel={5}
    />
  );
};

export default CheckInMethodTypeField;
