import { BasePagingResponse, BaseResponse } from './_base';

export interface ReviewByHost {
  cleanlinessRating: number;
  communicationRating: number;
  leadUid: string;
  notes: string;
  overallRating: number;
  respectRulesRating: number;
  uid: string;
}

export interface GetReviewByHostResponseTO {
  review: ReviewByHost;
}

export interface DirectBookingReview {
  uid: string;
  author: string;
  title: string;
  content: string;
  rating: number;
  date: string;
  propertyUid: string;
  leadUid: string;
  source: string;
  privateFeedback: string;
  reviewResponse: {
    content: string;
    author: string;
    responseDateTimeUTC: string;
  };
  ratingCategories: {
    category: string;
    rate: number;
    comment: string;
  }[];
  updatedUtcDateTime: string;
}

export interface GetDirectBookingReviewsResponse extends BaseResponse {
  reviews: DirectBookingReview[];
}

export enum AirbnbRatingCategory {
  ACCURACY = 'ACCURACY',
  CHECKIN = 'CHECKIN',
  CLEANLINESS = 'CLEANLINESS',
  COMMUNICATION = 'COMMUNICATION',
  LOCATION = 'LOCATION',
  VALUE = 'VALUE',
}

export interface AirbnbCategoryStatistic {
  category: AirbnbRatingCategory;
  rating: number;
  count: number;
}

export interface AirbnbRatingStats {
  uid: string;
  objectId: string;
  objectType: string;
  rating: number;
  count: number;
  categoryStatistics: AirbnbCategoryStatistic[];
}

export interface GetAirbnbRatingStatsResponse {
  rating_stats: AirbnbRatingStats;
}

export interface AirbnbGuestReview {
  uid: string;
  author: string;
  title: string;
  content: string;
  rating: number;
  date: string;
  propertyUid: string;
  leadUid: string;
  source: string;
  privateFeedback: string;
  reviewResponse: {
    content: string;
    author: string;
    responseDateTime: string;
  } | null;
  ratingCategories: {
    category: string;
    rate: number;
    comment: string;
  }[];
}

export interface GetAirbnbGuestReviewsResponse
  extends BaseResponse,
    BasePagingResponse {
  guestReviews: AirbnbGuestReview[];
}
