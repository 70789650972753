import TextAreaField from 'components/forms/textAreaField/TextAreaField';
import TextField from 'components/forms/textField/TextField';
import { Channel } from 'models/Channels';
import SectionWrapper from '../common/SectionWrapper';
import { DescriptionFields } from './PropertySettingsDescriptionsTab.types';
import LabelWithTooltip from './form/LabelWithTooltip';
import {
  ChannelToFieldVisibilityMapping,
  FieldVisibility,
  isRequiredAtPropertyLevel,
} from './PropertySettingsDescriptionsTabFormFields.utils';

interface PropertySettingsDescriptionsTabFormFieldsProps {
  activeChannels: Channel[];
  activeChannelsAtPropertyLevel: Channel[];
}

const PropertySettingsDescriptionsTabFormFields = ({
  activeChannels,
  activeChannelsAtPropertyLevel,
}: PropertySettingsDescriptionsTabFormFieldsProps) => {
  const channelVisibility = activeChannels
    .map((channel) => ChannelToFieldVisibilityMapping[channel])
    .filter(Boolean);

  const isRequired = (fieldName: DescriptionFields) => {
    return isRequiredAtPropertyLevel(activeChannelsAtPropertyLevel, fieldName);
  };

  const isVisible = (fieldName: DescriptionFields) => {
    if (isRequired(fieldName)) {
      return true;
    }

    return channelVisibility.some((fieldVisibility) => {
      return [
        FieldVisibility.VISIBLE,
        FieldVisibility.VISIBLE_AND_REQUIRED,
      ].includes(fieldVisibility[fieldName]);
    });
  };

  return (
    <SectionWrapper data-testid="description-form-fields">
      {isVisible('name') && (
        <TextField
          label={<LabelWithTooltip fieldName="name" />}
          name="name"
          maxLength={50}
          colSmInput={12}
          labelOnTop
          required={isRequired('name')}
        />
      )}
      {isVisible('shortSummary') && (
        <TextField
          label={<LabelWithTooltip fieldName="shortSummary" />}
          name="shortSummary"
          maxLength={250}
          colSmInput={12}
          labelOnTop
          required={isRequired('shortSummary')}
        />
      )}
      {isVisible('summary') && (
        <TextAreaField
          label={<LabelWithTooltip fieldName="summary" />}
          name="summary"
          maxLength={500}
          colSmInput={12}
          rows={3}
          labelOnTop
          required={isRequired('summary')}
        />
      )}
      {isVisible('notes') && (
        <TextAreaField
          label={<LabelWithTooltip fieldName="notes" />}
          name="notes"
          maxLength={49_150}
          maxCharacters={5_000}
          colSmInput={12}
          rows={3}
          labelOnTop
        />
      )}
      {isVisible('interaction') && (
        <TextAreaField
          label={<LabelWithTooltip fieldName="interaction" />}
          name="interaction"
          maxLength={49_150}
          maxCharacters={5_000}
          colSmInput={12}
          rows={3}
          labelOnTop
        />
      )}
      {isVisible('neighbourhood') && (
        <TextAreaField
          label={<LabelWithTooltip fieldName="neighbourhood" />}
          name="neighbourhood"
          maxLength={49_150}
          maxCharacters={5_000}
          colSmInput={12}
          rows={3}
          labelOnTop
        />
      )}
      {isVisible('access') && (
        <TextAreaField
          label={<LabelWithTooltip fieldName="access" />}
          name="access"
          maxLength={49_150}
          maxCharacters={5_000}
          colSmInput={12}
          rows={3}
          labelOnTop
        />
      )}
      {isVisible('space') && (
        <TextAreaField
          label={<LabelWithTooltip fieldName="space" />}
          name="space"
          maxLength={49_150}
          maxCharacters={5_000}
          colSmInput={12}
          rows={3}
          labelOnTop
        />
      )}
      {isVisible('transit') && (
        <TextAreaField
          label={<LabelWithTooltip fieldName="transit" />}
          name="transit"
          maxLength={49_150}
          maxCharacters={5_000}
          colSmInput={12}
          rows={3}
          labelOnTop
        />
      )}
      {isVisible('houseManual') && (
        <TextAreaField
          label={<LabelWithTooltip fieldName="houseManual" />}
          name="houseManual"
          maxLength={49_150}
          maxCharacters={5_000}
          colSmInput={12}
          rows={3}
          labelOnTop
        />
      )}
    </SectionWrapper>
  );
};

export default PropertySettingsDescriptionsTabFormFields;
