import { BaseSyntheticEvent } from 'react';
import Button from 'components/button/Button';
import useFetchDescriptions from 'components/domain/property/useFetchDescriptions';
import CheckboxField from 'components/forms/checkboxField/CheckboxField';
import FormWithProvider from 'components/forms/form/Form';
import { FormContextParts } from 'components/forms/form/Form.types';
import Tooltip from 'components/tooltip/Tooltip';
import useAppModal from 'hooks/useAppModal';
import { getLanguageName } from 'i18n/i18n.utils';
import {
  LanguageCode,
  LanguageCodeUnderscored,
} from 'i18n/internationalization.types';
import { Property, PropertyBusinessType } from 'models/Properties';
import { Col, Modal, Row } from 'react-bootstrap';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FooterContainer,
  ModalDescription,
} from './ManageLanguagesModal.styles';
import ManageLanguagesModalLoader from './ManageLanguagesModalLoader';
import UnselectedLanguagesWarning from './UnselectedLanguagesWarning';
import useUpdateLanguages from './useUpdateLanguages';

export const MANAGE_LANGUAGES_MODAL_ID = 'manage-languages-modal';

const ManageLanguagesModal = ({ property }: { property: Property }) => {
  const { t, i18n } = useTranslation();
  const { closeModal } = useAppModal();
  const { isLoading, updateLanguages } = useUpdateLanguages();

  const { uid: propertyUid, businessType } = property;
  const isHotel = businessType === PropertyBusinessType.HOTEL;

  const { data: descriptions, isLoading: isLoadingDescriptions } =
    useFetchDescriptions({
      propertyUid,
      isHotel,
    });

  const handleClose = () => {
    closeModal(MANAGE_LANGUAGES_MODAL_ID);
  };

  const possibleLanguages = [
    {
      label: getLanguageName(i18n.language, LanguageCode.EN),
      value: 'en_US',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.FR),
      value: 'fr_FR',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.ES),
      value: 'es_ES',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.IT),
      value: 'it_IT',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.DE),
      value: 'de_DE',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.NL),
      value: 'nl_NL',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.PT),
      value: 'pt_PT',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.ZH),
      value: 'zh_CN',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.KO),
      value: 'ko_KR',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.NO),
      value: 'no_NO',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.PL),
      value: 'pl_PL',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.TR),
      value: 'tr_TR',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.JA),
      value: 'ja_JP',
    },
    {
      label: getLanguageName(i18n.language, LanguageCode.VI),
      value: 'vi_VN',
    },
  ];

  const defaultValues = {
    languages: possibleLanguages?.reduce((acc, lang) => {
      const isCheck = descriptions?.some((desc) => desc.locale === lang.value);

      acc[lang.value] = isCheck;
      return acc;
    }, {}),
  };

  if (isLoadingDescriptions) {
    return <ManageLanguagesModalLoader />;
  }

  const handleSubmit = async (
    data: FieldValues,
    _: BaseSyntheticEvent,
    context: FormContextParts<FieldValues>,
  ) => {
    const selectedLocales = Object.entries(data.languages)
      .filter(([_key, value]) => !!value)
      .map(([key]) => key);

    const newSelectedLocales = selectedLocales.filter(
      (locale) =>
        !descriptions?.some((desc) => desc.locale === locale) &&
        locale !== LanguageCodeUnderscored.EN_US,
    );

    const unselectedLocales = Object.entries(data.languages).reduce(
      (acc, [key, value]) => {
        if (!value && context.formState.dirtyFields.languages?.[key]) {
          acc.push(key);
        }
        return acc;
      },
      [],
    );

    updateLanguages({
      unselectedLocales,
      newSelectedLocales,
      handleClose,
      isHotel,
      propertyUid,
    });
  };

  return (
    <FormWithProvider
      horizontal
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('pageProperty.descriptions.manageLanguageModal.title')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ModalDescription>
          {t('pageProperty.descriptions.manageLanguageModal.description')}
        </ModalDescription>

        <Row>
          {possibleLanguages.map((lang) => (
            <Col key={lang.value} md={5}>
              {lang.value === LanguageCodeUnderscored.VI_VN ? (
                <Tooltip
                  id="vietnamese-language-tooltip"
                  text={t(
                    'pageProperty.descriptions.manageLanguageModal.vietnameseTooltip',
                  )}
                >
                  <CheckboxField
                    name={`languages.${lang.value}`}
                    value={lang.value}
                  >
                    {lang.label}
                  </CheckboxField>
                </Tooltip>
              ) : (
                <CheckboxField
                  name={`languages.${lang.value}`}
                  value={lang.value}
                  disabled={lang.value === LanguageCodeUnderscored.EN_US}
                >
                  {lang.label}
                </CheckboxField>
              )}
            </Col>
          ))}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <FooterContainer>
          <Button bsStyle="default" onClick={handleClose}>
            {t('common.cancel')}
          </Button>

          <UnselectedLanguagesWarning />

          <Button bsStyle="primary" type="submit" disabled={isLoading}>
            {t('common.save')}
          </Button>
        </FooterContainer>
      </Modal.Footer>
    </FormWithProvider>
  );
};

export default ManageLanguagesModal;
