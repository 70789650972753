import { BreadcrumbItem } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Button from 'components/button/Button';
import {
  Property,
  PropertyBusinessType,
  PropertySubUnit,
  PropertyUnitType,
} from 'models/Properties';
import { PropertySettingsTab } from './PropertySettingsTabs.constants';
import usePropertySettingsInitData from './usePropertySettingsInitData';

const PropertySettingsTabHotelBreadcrumbItem = ({
  activeTab = PropertySettingsTab.mainSettings,
  property,
}: {
  activeTab?: string;
  property: Property | PropertySubUnit | PropertyUnitType;
}) => {
  const navigate = useNavigate();
  const { allProperties } = usePropertySettingsInitData();

  const { businessType } = property;

  if (businessType !== PropertyBusinessType.UNIT_TYPE) {
    return null;
  }

  const { hotelUid } = property as PropertyUnitType;

  const hotel = allProperties?.find(({ uid }) => uid === hotelUid);

  if (!hotel) {
    return null;
  }

  const { name } = hotel;
  const onClick = () => {
    const selectedTab =
      activeTab !== PropertySettingsTab.mainSettings ? `/${activeTab}` : '';

    navigate(`/property/${hotelUid}${selectedTab}`);
  };

  return (
    <BreadcrumbItem active>
      <Button bsStyle="link" className="hotel-link" onClick={onClick}>
        {name}
      </Button>
    </BreadcrumbItem>
  );
};

PropertySettingsTabHotelBreadcrumbItem.defaultProps = {
  activeTab: PropertySettingsTab.mainSettings,
};

export default PropertySettingsTabHotelBreadcrumbItem;
