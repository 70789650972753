import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const ChannelFilterContainer = styled.div.attrs({
  'data-testid': 'channel-filter',
})`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
    margin-right: 16px;
    font-weight: 600;
    font-size: 14px;
  }

  ${mediaQuery.xs} {
    flex-direction: column;
    align-items: flex-start;

    h2 {
      margin-bottom: 10px;
    }
  }
`;

export const ChannelItemButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  ${mediaQuery.xs} {
    flex-wrap: wrap;
    row-gap: 5px;
  }
`;

export const ChannelItemButtonStyled = styled.div<{
  $isActive: boolean;
}>`
  display: flex;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  height: 32px;
  border-radius: 4px;

  border: 1px solid;
  background-color: ${({ $isActive }) => ($isActive ? '#f3f3f7' : '#f9f9fb')};
  color: ${({ $isActive }) =>
    $isActive ? 'rgb(58, 132, 216)' : 'rgb(108, 117, 125)'};
  border-color: ${({ $isActive }) =>
    $isActive ? 'rgb(58, 132, 216)' : 'rgb(206, 212, 218)'};

  padding: 6px 10px;
  user-select: none;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0.7)};
  }
`;
