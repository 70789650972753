import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import { CancellationPolicy } from 'models/CancellationPolicy';
import PropertyEditActionWrapper from 'pages/property/common/PropertyEditActionWrapper';
import useEditCancellationPolicy from '../modals/useEditCancellationPolicy';
import useDeleteModal from './useDeleteModal';

const ActionsCell = ({ row }: { row: Row<CancellationPolicy> }) => {
  const { t } = useTranslation();

  const cancellationPolicyUid = row.original.uid;

  const { openEditModal } = useEditCancellationPolicy({
    cancellationPolicyUid,
  });
  const { openConfirmDeleteModal } = useDeleteModal({
    cancellationPolicyUid,
  });

  const cancellationPolicyValue = row.original.values?.[0];

  return (
    <div>
      <PropertyEditActionWrapper tooltipId="row-edit-cancellation-policy-button-tooltip">
        <Button
          bsStyle="link"
          onClick={() => openEditModal(cancellationPolicyValue)}
          data-testid="row-edit-cancellation-policy-button"
        >
          {t('common.edit')}
        </Button>
      </PropertyEditActionWrapper>
      <PropertyEditActionWrapper tooltipId="row-delete-cancellation-policy-button-tooltip">
        <Button
          bsStyle="link"
          onClick={openConfirmDeleteModal}
          data-testid="row-delete-cancellation-policy-button"
        >
          {t('common.delete')}
        </Button>
      </PropertyEditActionWrapper>
    </div>
  );
};

export default ActionsCell;
