import CommonTable from 'components/table/CommonTable';
import styled from 'styled-components';
import { DirectBookingReview } from 'models/Reviews';

export const DirectBookingReviewsTableStyled = styled(
  CommonTable<DirectBookingReview>,
)`
  .column-date {
    width: 120px;
    min-width: 120px;
  }

  .column-author {
    width: 150px;
    min-width: 150px;
  }

  .column-rating {
    width: 80px;
    min-width: 80px;
  }

  .column-content {
    min-width: 200px;
    width: 100%;
  }
`;
