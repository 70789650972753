import { Row } from '@tanstack/react-table';
import { DirectBookingReview } from 'models/Reviews';

const ReviewCell = ({ row }: { row: Row<DirectBookingReview> }) => {
  const { content, title } = row.original;

  if (title && content) {
    return `${title} - ${content}`;
  }

  return content;
};

export default ReviewCell;
