import Button from 'components/button/Button';
import { Modal } from 'react-bootstrap';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const DirectBookingReviewModalFooter = ({
  handleClose,
  isLoading,
  isEditMode,
}: {
  handleClose: () => void;
  isLoading: boolean;
  isEditMode: boolean;
}) => {
  const { t } = useTranslation();
  const { isDirty } = useFormState();

  return (
    <Modal.Footer>
      <Button onClick={handleClose}>{t('common.cancel')}</Button>
      <Button
        type="submit"
        bsStyle="primary"
        disabled={isLoading || (isEditMode && !isDirty)}
      >
        {t('common.save')}
      </Button>
    </Modal.Footer>
  );
};

export default DirectBookingReviewModalFooter;
