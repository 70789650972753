import Button from 'components/button/Button';
import PropertyMultiSelectFilterField from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import FormWithProvider from 'components/forms/form/Form';
import useAppModal from 'hooks/useAppModal';
import { CustomAmenity } from 'models/Amenity';
import { Property } from 'models/Properties';
import { OptionalObjectSchema } from 'yup/lib/object';
import { AppliesToLabel } from 'pages/property/common/Common.styles';
import { Col, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  AppliesToFieldContainer,
  CustomAmenitiesDeleteModalContainer,
} from './CustomAmenityModal.styles';
import { getDefaultFormValues } from './CustomAmenityModal.utils';

export const deleteModalId = 'delete-custom-amenity-modal';

const deleteSchema = () => ({
  uid: Yup.string(),
  name: Yup.string(),
  appliesTo: Yup.string().required(),
  appliesToProperties: Yup.array()
    .of(
      Yup.object({
        label: Yup.string(),
        value: Yup.string(),
        isFixed: Yup.boolean().optional(),
      }),
    )
    .label('properties')
    .when('appliesTo', {
      is: PROPERTIES_SELECTION.ALL_PROPERTIES,
      then: (schema) => schema.optional().nullable(true),
      otherwise: (schema) => schema.min(1).required(),
    }),
});

export type CustomAmenityDeleteFormValues = Yup.InferType<
  OptionalObjectSchema<ReturnType<typeof deleteSchema>>
>;

const CustomAmenityDeleteModal = ({
  property,
  onSubmit,
  customAmenity,
}: {
  property: Property;
  onSubmit: (data: CustomAmenityDeleteFormValues) => void;
  customAmenity: CustomAmenity;
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();

  const handleClose = () => {
    closeModal(deleteModalId);
  };

  return (
    <FormWithProvider
      horizontal
      schema={deleteSchema()}
      onSubmit={onSubmit}
      defaultValues={getDefaultFormValues(customAmenity, property)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('pageProperty.amenities.customAmenities.deleteModal.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomAmenitiesDeleteModalContainer>
          <Trans
            i18nKey="pageProperty.amenities.customAmenities.deleteModal.description"
            values={{ name: customAmenity.name }}
            components={{
              highlight: <strong />,
            }}
          />
          <AppliesToFieldContainer>
            <Col xs={4}>
              <AppliesToLabel>
                {t('pageProperty.common.cells.appliesTo')}
              </AppliesToLabel>
            </Col>
            <Col xs={8}>
              <PropertyMultiSelectFilterField
                name="appliesTo"
                showUnits={false}
                enableUnitTypes
                graphQlFilters={{
                  topLevelOnly: false,
                }}
              />
            </Col>
          </AppliesToFieldContainer>
        </CustomAmenitiesDeleteModalContainer>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="default" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="danger" type="submit">
          {t('common.delete')}
        </Button>
      </Modal.Footer>
    </FormWithProvider>
  );
};

export default CustomAmenityDeleteModal;
