import { Fragment, useContext } from 'react';
import LeadModalContext from 'components/domain/lead/modal/LeadModalContext';
import useFormatCurrency from 'hooks/useFormatCurrency';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { LeadQuoteFeeOverride } from 'models/Quote';
import { TDWithoutBorder } from './Fields.styles';
import AmountField from './common/AmountField';
import TextField from './common/TextField';

interface FeesProps {
  isEditable: boolean;
}

const Fees = ({ isEditable }: FeesProps) => {
  const { quote } = useContext(LeadModalContext);
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();

  return (
    <>
      {quote.fees.otherFees.map((orderFee, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`otherFee_${orderFee.name}_${i}`}>
            <tr>
              <TDWithoutBorder />
              {orderFee.feeId !== null || !isEditable ? (
                <td>{`${orderFee.name}`}</td>
              ) : (
                <td>
                  <TextField
                    value={orderFee.name}
                    isEditable={isEditable}
                    onItemUpdated={(oldValue, newValue) => {
                      // Delete the fee and recreate it with the new name
                      const otherFees = getValues('overrides.fees.otherFees');

                      const oldFeeIndex = otherFees.findIndex(
                        (fee) => fee.feeId === null && fee.name === oldValue,
                      );
                      otherFees[oldFeeIndex].removed = true;

                      otherFees.push({
                        ...otherFees[oldFeeIndex],
                        name: newValue,
                        removed: false,
                      });

                      setValue('overrides.fees.otherFees', otherFees);
                    }}
                  />
                </td>
              )}
              {orderFee.feeId !== null || !isEditable ? (
                <td />
              ) : (
                <td>
                  <Button
                    style={{ padding: '0' }}
                    onClick={() => {
                      const otherFees = getValues('overrides.fees.otherFees');

                      otherFees.forEach((_fee: LeadQuoteFeeOverride) => {
                        if (_fee.name === orderFee.name) {
                          _fee.removed = true;
                        }
                      });

                      setValue('overrides.fees.otherFees', otherFees);
                    }}
                    className="btn btn-link"
                  >
                    [{t('componentOrder.remove')}]
                  </Button>
                </td>
              )}
              <td>
                {orderFee.isEditable ? (
                  <AmountField
                    value={orderFee.netPrice}
                    isEditable={isEditable}
                    currency={quote.currency}
                    onItemUpdated={(newValue) => {
                      const otherFees = getValues('overrides.fees.otherFees');

                      otherFees.forEach((_fee: LeadQuoteFeeOverride) => {
                        if (_fee.name === orderFee.name) {
                          _fee.netPrice = newValue;
                        }
                      });

                      setValue('overrides.fees.otherFees', otherFees);
                    }}
                  />
                ) : (
                  formatCurrency({
                    value: orderFee.netPrice,
                    currency: quote.currency,
                  })
                )}
              </td>
            </tr>
            {orderFee.taxationRate > 0 && (
              <tr>
                <TDWithoutBorder />
                <td>
                  {t('componentOrder.otherFeeTax', {
                    name: orderFee.name,
                    tax: orderFee.taxationRate,
                  })}
                </td>
                <td />
                <td>
                  {formatCurrency({
                    value: orderFee.taxAmount,
                    currency: quote.currency,
                  })}
                </td>
              </tr>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default Fees;
