import { Namespace, TFunction } from 'react-i18next';
import { DeepPartial, FieldNamesMarkedBoolean } from 'react-hook-form';
import { parseISO } from 'date-fns';
import * as Yup from 'yup';
import { AirbnbCancellationPolicy } from 'models/AirbnbCancellationPolicy';
import { AirbnbCheckInOptions } from 'models/AirbnbCheckInOptions';
import { AirbnbGuestRequirements } from 'models/AirbnbGuestRequirements';
import { AREA_UNIT_TYPE } from 'models/AreaUnitType';
import { RoomTypes } from 'models/bookingDotCom/RoomTypes';
import { BOOKING_TYPES, BookingType } from 'models/BookingType';
import { BOOKING_WINDOWS } from 'models/BookingWindow';
import { VrboCancellationPolicy } from 'models/Channels';
import { CURRENCIES_WITHOUT_NONE, Currency } from 'models/Currency';
import { DAY_OF_WEEK } from 'models/DayOfWeek';
import { PropertyBusinessType } from 'models/Properties';
import { PropertyListingTypes } from 'models/PropertyListingTypes';
import {
  basePropertyAddressSchema,
  checkInMethod,
  propertyCapacityDetailsSchema,
  propertyDetailsSchema,
} from '../common/Common.schema';
import {
  AirbnbSettings,
  BookingSettings,
  MiscInfo,
  PropertyDetails,
  PropertyMainSettingsData,
} from './PropertySettingsMainTab.types';
import { CHECK_IN_TIME_END_FLEXIBLE } from './sections/airbnbSettings/AirbnbSettings.constants';
import { VRBO_AVAILABLE_BOOKING_TYPES } from './sections/vrboSettings/PropertyVrboSettings.constants';

export const propertySettingsMainTabSchema = (
  businessType: PropertyBusinessType,
  t: TFunction<Namespace<'en'>>,
) => {
  const isHotel = businessType === PropertyBusinessType.HOTEL;
  const isUnitType = businessType === PropertyBusinessType.UNIT_TYPE;

  return Yup.object({
    airbnbSettings: Yup.object({
      airbnbId: Yup.string().nullable().optional(),
      allowBookingRequestAboveMaximumStay: Yup.boolean().optional().nullable(),
      allowBookingRequestWithinBookingLeadTime: Yup.boolean()
        .optional()
        .nullable(),
      bookingType: Yup.string()
        .oneOf([null, ...Object.values(BOOKING_TYPES)])
        .nullable()
        .when([], {
          is: () => isHotel,
          then: (schema) => schema.optional(),
          otherwise: (schema) => schema.required(),
        }),
      cancellationPolicy: Yup.string()
        .oneOf([null, ...Object.values(AirbnbCancellationPolicy)])
        .nullable()
        .when([], {
          is: () => isHotel || isUnitType,
          then: (schema) => schema.optional(),
          otherwise: (schema) => schema.required(),
        }),
      checkInOption: Yup.string()
        .oneOf([null, ...Object.values(AirbnbCheckInOptions)])
        .nullable(),
      checkInTimeEnd: Yup.string(),
      checkInTimeStartFlexible: Yup.mixed<boolean | string>()
        .transform((value) => value === 'true')
        .optional()
        .nullable(),
      guestRequirements: Yup.string()
        .oneOf([null, ...Object.values(AirbnbGuestRequirements)])
        .nullable(),
      listingType: Yup.string()
        .oneOf([null, ...Object.values(PropertyListingTypes)])
        .nullable()
        .when([], {
          is: () => isHotel,
          then: (schema) => schema.optional(),
          otherwise: (schema) => schema.required(),
        }),
      nonRefundableRateEnabled: Yup.mixed<boolean | string>()
        .transform((value) => value === 'true')
        .optional()
        .nullable(),
      nonRefundableRateDiscount: Yup.number()
        .optional()
        .nullable()
        .when('nonRefundableRateEnabled', {
          is: true,
          then: (schema) =>
            schema
              .useNaNAsNull()
              .useNegativeZeroAsNegative()
              .min(0.01)
              .max(100)
              .required(),
        }),
      sendMessageAsUserId: Yup.string().optional().nullable(),
    }),
    bookingDotComSettings: Yup.object({
      alternativeCheckInMethod: checkInMethod(),
      cancellationPolicyCode: Yup.number().nullable(),
      hotelId: Yup.string().nullable(),
      primaryCheckInMethod: checkInMethod(),
      primaryContactUid: Yup.string()
        .transform((value) => (value === '' ? null : value))
        .nullable(),
      roomId: Yup.string().nullable(),
      status: Yup.string().nullable().strip(),
      roomType: Yup.mixed<RoomTypes | null>()
        .oneOf([null, ...Object.values(RoomTypes)])
        .nullable(),
      roomName: Yup.string().nullable(),
    }),
    bookingSettings: Yup.object({
      bookingLeadTime: Yup.number(),
      bookingType: Yup.string().oneOf(Object.values(BOOKING_TYPES)),
      bookingWindow: Yup.number().oneOf(Object.values(BOOKING_WINDOWS)),
      checkInTime: Yup.number(),
      checkoutTime: Yup.number(),
      dayOfWeekMinimumStay: Yup.object({
        [DAY_OF_WEEK.MONDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
        [DAY_OF_WEEK.TUESDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
        [DAY_OF_WEEK.WEDNESDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
        [DAY_OF_WEEK.THURSDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
        [DAY_OF_WEEK.FRIDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
        [DAY_OF_WEEK.SATURDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
        [DAY_OF_WEEK.SUNDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
      }),
      daysOfTheWeekToCheckInOn: Yup.object({
        [DAY_OF_WEEK.MONDAY]: Yup.boolean().optional(),
        [DAY_OF_WEEK.TUESDAY]: Yup.boolean().optional(),
        [DAY_OF_WEEK.WEDNESDAY]: Yup.boolean().optional(),
        [DAY_OF_WEEK.THURSDAY]: Yup.boolean().optional(),
        [DAY_OF_WEEK.FRIDAY]: Yup.boolean().optional(),
        [DAY_OF_WEEK.SATURDAY]: Yup.boolean().optional(),
        [DAY_OF_WEEK.SUNDAY]: Yup.boolean().optional(),
      }),
      fullPaymentTiming: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .integer()
        .min(0)
        .optional()
        .nullable(),
      maximumStay: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .integer()
        .min(0)
        .optional()
        .nullable(),
      minimumStay: Yup.number()
        .useNegativeZeroAsNegative()
        .integer()
        .min(0)
        .when(['maximumStay'], {
          is: (value: number) => value > 0,
          then: (schema) =>
            schema.max(
              Yup.ref('maximumStay'),
              t(
                'pageProperty.mainSettings.bookingSettings.minimumStayMinValidation',
              ),
            ),
        })
        .required(),
      minimumWeekendStay: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .integer()
        .min(0)
        .optional()
        .nullable(),
      percentageUponReservation: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .max(100)
        .integer()
        .optional()
        .nullable(),
      turnoverDays: Yup.number(),
    }),
    capacityDetails: propertyCapacityDetailsSchema({ businessType, t }),
    propertyAddress: basePropertyAddressSchema(businessType).shape({
      latitude: Yup.number()
        .min(
          -90,
          t('pageProperty.mainSettings.propertyAddress.validation.latitude'),
        )
        .max(
          90,
          t('pageProperty.mainSettings.propertyAddress.validation.latitude'),
        )
        .nullable()
        .transform((current, original) => (original === '' ? null : current)),
      longitude: Yup.number()
        .min(
          -180,
          t('pageProperty.mainSettings.propertyAddress.validation.longitude'),
        )
        .max(
          180,
          t('pageProperty.mainSettings.propertyAddress.validation.longitude'),
        )
        .nullable()
        .transform((current, original) => (original === '' ? null : current)),
    }),
    propertyDetails: propertyDetailsSchema(businessType, t).shape({
      currency: Yup.mixed<Currency>()
        .oneOf(CURRENCIES_WITHOUT_NONE)
        .when([], {
          is: () => isHotel,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.optional().nullable(),
        }),
      securityDeposit: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .optional()
        .nullable(),
    }),
    miscInfo: Yup.object({
      accountingId: Yup.string().nullable().optional(),
      externalId: Yup.string().nullable().optional(),
      rentalLicenseNumber: Yup.string().nullable().optional(),
      rentalLicenseExpirationDate: Yup.date().nullable().optional(),
      wifiNetwork: Yup.string().nullable().optional(),
      wifiPassword: Yup.string().nullable().optional(),
    }),
    vrboSettings: Yup.object({
      propertyId: Yup.string().nullable().optional(),
      bookingType: Yup.mixed<BookingType>()
        .oneOf(VRBO_AVAILABLE_BOOKING_TYPES)
        .when([], {
          is: () => isHotel || isUnitType,
          then: (schema) => schema.optional().nullable(),
          otherwise: (schema) => schema.required(),
        }),
      cancellationPolicy: Yup.mixed<VrboCancellationPolicy>().when([], {
        is: () => isHotel || isUnitType,
        then: (schema) => schema.optional().nullable(),
        otherwise: (schema) => schema.required(),
      }),
      showPropertyExactLocation: Yup.mixed<boolean | string>()
        .transform((value) => value === 'true')
        .when([], {
          is: () => isHotel || isUnitType,
          then: (schema) => schema.optional().nullable(),
          otherwise: (schema) => schema.required(),
        }),
    }),
  });
};

export type PropertySettingsMainTabFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof propertySettingsMainTabSchema>>
>;

export type PropertySettingsMainTabFormDirtyFields = Partial<
  Readonly<FieldNamesMarkedBoolean<PropertySettingsMainTabFormValues>>
>;

export type PropertyMainSettingsUpdatePayload = Omit<
  PropertySettingsMainTabFormValues,
  'airbnbSettings' | 'miscInfo'
> & {
  airbnbSettings?: Partial<AirbnbSettings>;
  bookingSettings?: Partial<BookingSettings>;
  propertyDetails?: Partial<PropertyDetails>;
  miscInfo?: Partial<MiscInfo>;
};

export const getFormDefaultValues = (
  mainSettingsData: Partial<PropertyMainSettingsData>,
): PropertySettingsMainTabFormValues => {
  // If property already exists, then return the data from the server
  if (mainSettingsData) {
    const {
      airbnbSettings: { checkInTimeEndFlexible, ...airbnbSettings },
      bookingSettings: {
        bookingLeadTime,
        checkInTime,
        checkoutTime,
        daysOfTheWeekToCheckInOn,
        fullPaymentTiming,
        maximumStay,
        minimumStay,
        minimumWeekendStay,
        percentageUponReservation,
      },
      bookingDotComSettings,
      capacityDetails: { rooms },
      propertyDetails: { propertySizeUnit, propertyType },
      ...restMainSettingsData
    } = mainSettingsData;

    return {
      ...restMainSettingsData,
      airbnbSettings: {
        ...airbnbSettings,
        checkInTimeEnd: checkInTimeEndFlexible
          ? CHECK_IN_TIME_END_FLEXIBLE
          : `${airbnbSettings.checkInTimeEnd}`,
        checkInTimeStartFlexible: `${airbnbSettings.checkInTimeStartFlexible}`,
        nonRefundableRateEnabled: `${airbnbSettings.nonRefundableRateEnabled}`,
        nonRefundableRateDiscount:
          typeof airbnbSettings.nonRefundableRateDiscount === 'number'
            ? airbnbSettings.nonRefundableRateDiscount * 100
            : airbnbSettings.nonRefundableRateDiscount,
      },
      bookingDotComSettings: {
        ...bookingDotComSettings,
        alternativeCheckInMethod: {
          type: bookingDotComSettings.alternativeCheckInMethod?.checkinMethod,
          ...bookingDotComSettings.alternativeCheckInMethod,
        },
        primaryCheckInMethod: {
          type: bookingDotComSettings.primaryCheckInMethod?.checkinMethod,
          ...bookingDotComSettings.primaryCheckInMethod,
        },
        primaryContactUid:
          bookingDotComSettings.primaryContactUid === null
            ? ''
            : bookingDotComSettings.primaryContactUid,
      },
      bookingSettings: {
        ...mainSettingsData.bookingSettings,
        bookingLeadTime: bookingLeadTime ?? 48,
        checkInTime: checkInTime ?? 15,
        checkoutTime: checkoutTime ?? 11,
        daysOfTheWeekToCheckInOn: {
          [DAY_OF_WEEK.MONDAY]: daysOfTheWeekToCheckInOn?.includes(
            DAY_OF_WEEK.MONDAY,
          ),
          [DAY_OF_WEEK.TUESDAY]: daysOfTheWeekToCheckInOn?.includes(
            DAY_OF_WEEK.TUESDAY,
          ),
          [DAY_OF_WEEK.WEDNESDAY]: daysOfTheWeekToCheckInOn?.includes(
            DAY_OF_WEEK.WEDNESDAY,
          ),
          [DAY_OF_WEEK.THURSDAY]: daysOfTheWeekToCheckInOn?.includes(
            DAY_OF_WEEK.THURSDAY,
          ),
          [DAY_OF_WEEK.FRIDAY]: daysOfTheWeekToCheckInOn?.includes(
            DAY_OF_WEEK.FRIDAY,
          ),
          [DAY_OF_WEEK.SATURDAY]: daysOfTheWeekToCheckInOn?.includes(
            DAY_OF_WEEK.SATURDAY,
          ),
          [DAY_OF_WEEK.SUNDAY]: daysOfTheWeekToCheckInOn?.includes(
            DAY_OF_WEEK.SUNDAY,
          ),
        },
        fullPaymentTiming: fullPaymentTiming ?? 45,
        maximumStay: maximumStay ?? 0,
        minimumStay: minimumStay ?? 1,
        minimumWeekendStay: minimumWeekendStay ?? 0,
        percentageUponReservation: percentageUponReservation
          ? percentageUponReservation * 100
          : 50,
      },
      capacityDetails: {
        ...mainSettingsData.capacityDetails,
        rooms: rooms?.map(({ beds, ...rest }) => ({
          ...rest,
          beds: Object.entries(beds).map(([bedType, count]) => ({
            bedType,
            count,
          })),
        })),
      },
      miscInfo: {
        ...mainSettingsData.miscInfo,
        rentalLicenseExpirationDate:
          mainSettingsData.miscInfo.rentalLicenseExpirationDate &&
          parseISO(mainSettingsData.miscInfo.rentalLicenseExpirationDate),
      },
      propertyAddress: {
        ...mainSettingsData.propertyAddress,
        countryCode: mainSettingsData?.propertyAddress?.countryCode ?? 'US',
        state: mainSettingsData?.propertyAddress?.state ?? 'AL',
      },
      propertyDetails: {
        ...mainSettingsData.propertyDetails,
        propertySizeUnit: propertySizeUnit || AREA_UNIT_TYPE.SQUARE_METERS,
        propertyType: propertyType ?? 'HOUSE',
      },
      vrboSettings: {
        ...mainSettingsData.vrboSettings,
        showPropertyExactLocation: `${mainSettingsData?.vrboSettings?.showPropertyExactLocation}`,
      },
    };
  }

  return {
    airbnbSettings: {
      checkInTimeEnd: CHECK_IN_TIME_END_FLEXIBLE,
    },
    bookingDotComSettings: {},
    bookingSettings: {
      bookingLeadTime: 48,
      checkInTime: 15,
      checkoutTime: 11,
      daysOfTheWeekToCheckInOn: {
        [DAY_OF_WEEK.MONDAY]: false,
        [DAY_OF_WEEK.TUESDAY]: false,
        [DAY_OF_WEEK.WEDNESDAY]: false,
        [DAY_OF_WEEK.THURSDAY]: false,
        [DAY_OF_WEEK.FRIDAY]: false,
        [DAY_OF_WEEK.SATURDAY]: false,
        [DAY_OF_WEEK.SUNDAY]: false,
      },
      fullPaymentTiming: 45,
      maximumStay: 0,
      minimumStay: 1,
      minimumWeekendStay: 0,
      percentageUponReservation: 50,
    },
    propertyAddress: {
      countryCode: 'US',
      state: 'AL',
    },
    propertyDetails: {
      propertySizeUnit: AREA_UNIT_TYPE.SQUARE_METERS,
      propertyType: 'HOUSE',
    },
    vrboSettings: {
      bookingType: BOOKING_TYPES.INSTANT_BOOKING,
      cancellationPolicy: VrboCancellationPolicy.NO_REFUND,
      showPropertyExactLocation: 'true',
    },
  };
};
