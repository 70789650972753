import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import TextField from 'components/forms/textField/TextField';
import FormWithProvider from 'components/forms/form/Form';
import StarRatingField from 'components/forms/starRatingField/StarRatingField';
import { DirectBookingReview } from 'models/Reviews';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import useAppModal from 'hooks/useAppModal';
import { parseISO } from 'date-fns';
import { useIsMutating } from '@tanstack/react-query';
import DirectBookingReviewModalFooter from './DirectBookingReviewModalFooter';

const schema = () => ({
  date: Yup.date().required(),
  rating: Yup.number().min(1).max(5).required(),
  author: Yup.string().max(55).required(),
  title: Yup.string().max(255).required(),
  content: Yup.string().max(1000).required(),
});

export type DirectBookingReviewFormSchemaType = Yup.InferType<
  OptionalObjectSchema<ReturnType<typeof schema>>
>;

export const addReviewModalId = 'add-direct-booking-review-modal';
export const editReviewModalId = 'edit-direct-booking-review-modal';

const DirectBookingReviewModal = ({
  review,
  onSubmit,
  mutationKey,
}: {
  review?: DirectBookingReview;
  onSubmit: (values: DirectBookingReviewFormSchemaType) => void;
  mutationKey: string[];
}) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();
  const isMutating = useIsMutating({
    mutationKey,
  });

  const isLoading = isMutating > 0;

  const isEditMode = !!review;

  const handleClose = () => {
    closeModal(isEditMode ? editReviewModalId : addReviewModalId);
  };

  const defaultValues = isEditMode
    ? {
        ...review,
        date: parseISO(review.date),
      }
    : undefined;

  return (
    <FormWithProvider
      schema={schema()}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isEditMode
            ? t('pageProperty.reviews.directBooking.modal.editTitle')
            : t('pageProperty.reviews.directBooking.modal.addTitle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <DateTimePickerField
              required
              colSmInput={8}
              colSmLabel={4}
              name="date"
              label={t('pageProperty.reviews.directBooking.modal.reviewDate')}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <StarRatingField
              required
              colSmInput={8}
              colSmLabel={4}
              name="rating"
              label={t('pageProperty.reviews.directBooking.modal.rating')}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              required
              colSmInput={8}
              colSmLabel={4}
              name="author"
              label={t('pageProperty.reviews.directBooking.modal.author')}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              required
              colSmInput={8}
              colSmLabel={4}
              name="title"
              label={t('pageProperty.reviews.directBooking.modal.reviewTitle')}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              required
              colSmInput={8}
              colSmLabel={4}
              componentClass="textarea"
              maxLength={1000}
              maxCharacters={1000}
              name="content"
              label={t(
                'pageProperty.reviews.directBooking.modal.reviewContent',
              )}
            />
          </Col>
        </Row>
      </Modal.Body>
      <DirectBookingReviewModalFooter
        handleClose={handleClose}
        isLoading={isLoading}
        isEditMode={isEditMode}
      />
    </FormWithProvider>
  );
};

DirectBookingReviewModal.defaultProps = {
  review: undefined,
};

export default DirectBookingReviewModal;
