import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import { CustomAmenity } from 'models/Amenity';
import { Property } from 'models/Properties';

export const getDefaultFormValues = (
  customAmenity: CustomAmenity,
  property: Property,
) => {
  if (!customAmenity) {
    return {
      appliesTo: PROPERTIES_SELECTION.SELECTED_PROPERTIES,
      appliesToProperties: [
        {
          label: property.name,
          value: property.uid,
          isFixed: true,
        },
      ],
    };
  }

  const { appliesToAllProperties, propertiesThatAppliesTo } =
    customAmenity.metadata || {};

  const fixedCurrentPropertyValue = {
    label: property.name,
    value: property.uid,
    isFixed: true,
  };

  return {
    uid: customAmenity.uid,
    name: customAmenity.name,
    appliesTo: appliesToAllProperties
      ? PROPERTIES_SELECTION.ALL_PROPERTIES
      : PROPERTIES_SELECTION.SELECTED_PROPERTIES,
    appliesToProperties: appliesToAllProperties
      ? [fixedCurrentPropertyValue]
      : [
          fixedCurrentPropertyValue,
          ...(propertiesThatAppliesTo || [])
            .filter(({ uid }) => uid !== property.uid)
            .map(({ name, uid }) => ({
              label: name,
              value: uid,
            })),
        ],
  };
};
