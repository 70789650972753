import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DirectBookingReview } from 'models/Reviews';
import useServerFilteredTable from 'components/table/hooks/useServerFilteredTable';
import FormattedDateCell from 'components/table/cells/common/FormattedDateCell';
import ActionsCell from './cells/ActionsCell';
import ReviewCell from './cells/ReviewCell';
import getDirectBookingReviewsQuery from './DirectBookingReviews.utils';
import { DirectBookingReviewsTableStyled } from './DirectBookingReviews.styles';

const DirectBookingReviewsTable = () => {
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<DirectBookingReview>();

  const columns = [
    columnHelper.accessor('date', {
      header: t('pageProperty.reviews.directBooking.table.reviewDate'),
      cell: FormattedDateCell,
      meta: {
        dateFormat: 'PP',
      },
      enableSorting: false,
    }),
    columnHelper.accessor('rating', {
      header: t('pageProperty.reviews.directBooking.table.rating'),
      enableSorting: false,
    }),
    columnHelper.accessor('author', {
      header: t('pageProperty.reviews.directBooking.table.author'),
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'content',
      header: t('pageProperty.reviews.directBooking.table.review'),
      cell: ReviewCell,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      header: t('common.actions'),
      cell: ActionsCell,
    }),
  ];

  const { tableInstance, isLoading } = useServerFilteredTable({
    columns,
    getQuery: (options) =>
      getDirectBookingReviewsQuery({ propertyUid, ...options }),
  });

  return (
    <DirectBookingReviewsTableStyled
      tableInstance={tableInstance}
      isLoading={isLoading}
      emptyState={t('pageProperty.reviews.directBooking.table.emptyTable')}
    />
  );
};

export default DirectBookingReviewsTable;
