import { getLanguageCode, getLanguageName } from 'i18n/i18n.utils';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const UnselectedLanguagesWarning = () => {
  const {
    formState: { dirtyFields },
  } = useFormContext();

  const { languages } = useWatch();
  const { t, i18n } = useTranslation();

  const unselectedLanguages =
    Object.keys(dirtyFields).length > 0
      ? Object.entries(languages).reduce((acc, [key, value]) => {
          if (!value && dirtyFields.languages?.[key]) {
            acc.push(key);
          }
          return acc;
        }, [])
      : [];

  const languagesText = unselectedLanguages?.map((lang) =>
    getLanguageName(i18n.language, getLanguageCode(lang)),
  );

  if (!unselectedLanguages.length) {
    return null;
  }

  return (
    <p className="text-center text-danger">
      {t('pageProperty.descriptions.manageLanguageModal.deleteWarning', {
        languageList: languagesText.join(', '),
      })}
    </p>
  );
};

export default UnselectedLanguagesWarning;
