import API from 'services/API';
import useAppMutation from 'hooks/useAppMutation';
import { DirectBookingReview } from 'models/Reviews';

interface MutateDirectBookingReviewResponse {
  review: DirectBookingReview;
}

export type CreateDirectBookingReviewPayload = Pick<
  DirectBookingReview,
  'propertyUid' | 'title' | 'content' | 'date' | 'rating' | 'author'
>;

export const CREATE_DIRECT_BOOKING_REVIEW_MUTATION_KEY =
  'create-direct-booking-review';
export const UPDATE_DIRECT_BOOKING_REVIEW_MUTATION_KEY =
  'update-direct-booking-review';
export const DELETE_DIRECT_BOOKING_REVIEW_MUTATION_KEY =
  'delete-direct-booking-review';

const useDirectBookingReviewMutations = () => {
  const { mutateAsync: createDirectBookingReview } = useAppMutation(
    [CREATE_DIRECT_BOOKING_REVIEW_MUTATION_KEY],
    ({
      directBookingReview,
    }: {
      directBookingReview: CreateDirectBookingReviewPayload;
    }) =>
      API.post<MutateDirectBookingReviewResponse>(
        `/api/v3/reviews`,
        directBookingReview,
      ),
  );

  const { mutateAsync: updateDirectBookingReview } = useAppMutation(
    [UPDATE_DIRECT_BOOKING_REVIEW_MUTATION_KEY],
    ({
      directBookingReview,
    }: {
      directBookingReview: Partial<DirectBookingReview>;
    }) =>
      API.put<MutateDirectBookingReviewResponse>(
        `/api/v3/reviews/${directBookingReview.uid}`,
        directBookingReview,
      ),
  );

  const { mutateAsync: deleteDirectBookingReview } = useAppMutation(
    [DELETE_DIRECT_BOOKING_REVIEW_MUTATION_KEY],
    ({ uid }: { uid: string }) =>
      API.delete<MutateDirectBookingReviewResponse>(`/api/v3/reviews/${uid}`),
  );

  return {
    createDirectBookingReview,
    updateDirectBookingReview,
    deleteDirectBookingReview,
  };
};

export default useDirectBookingReviewMutations;
