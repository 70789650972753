import useAppQuery from 'hooks/useAppQuery';
import { GetPropertyCustomAmenitiesResponse } from 'models/Amenity';
import { useParams } from 'react-router-dom';
import API from 'services/API';

export const PROPERTIES_CUSTOM_AMENITIES_BASE_QUERY_KEY =
  'property-custom-amenities';

const useCustomAmenitiesData = () => {
  const { uid: propertyUid } = useParams();

  const { data: customAmenitiesData, isLoading: isLoadingCustomAmenities } =
    useAppQuery(
      [PROPERTIES_CUSTOM_AMENITIES_BASE_QUERY_KEY, 'detail', propertyUid],
      async () => {
        const response = await API.get<GetPropertyCustomAmenitiesResponse>(
          `/api/internal/custom-amenities/?objectUid=${propertyUid}`,
        );

        const { customAmenities } = response.data;

        return customAmenities;
      },
      {
        enabled: !!propertyUid,
      },
    );

  return {
    customAmenities: customAmenitiesData ?? [],
    isLoadingCustomAmenities,
  };
};

export default useCustomAmenitiesData;
