import { UseQueryOptions } from '@tanstack/react-query';
import useAppQuery from 'hooks/useAppQuery';
import { Agency, GetAgencyResponseTO } from 'models/Agency';
import API from 'services/API';

export const AGENCY_BASE_QUERY_KEY = 'agencies';

type ReactQueryOptions = Omit<UseQueryOptions<Agency>, 'queryKey' | 'queryFn'>;

type UseFetchAgencyArgs = {
  agencyUid: string;
} & ReactQueryOptions;

const useFetchAgency = ({
  agencyUid,
  enabled = true,
  ...options
}: UseFetchAgencyArgs) => {
  return useAppQuery({
    queryKey: [AGENCY_BASE_QUERY_KEY, 'detail', agencyUid],
    queryFn: async () => {
      const response = await API.get<GetAgencyResponseTO>(
        `/api/v3.1/agencies/${agencyUid}`,
      );

      return response.data.agency;
    },
    enabled,
    staleTime: 30000,
    keepPreviousData: true,
    ...(options ?? {}),
  });
};

export default useFetchAgency;
