const theme = {
  colors: {
    hostfullyBlue: '#2d2aa5',
    hostfullyGreen: '#40caa1',
    hostfullyNeutral: '#999999',
    hostfullyOrange: '#ff7254',
    hostfullyPurple: '#8a5bda',
    hostfullySuccess: '#31b58e',
    hostfullyYellow: '#ffc454',
    info: '#0dcaf0',
    light: '#f3f3f7',
    dark: '#19172a',
    gray300: '#dee2e6',
    gray500: '#adb5bd',
    gray600: '#6c757d',
    fontDark: '#1f1f1f',
    fontGreen: '#0F5132',
    airbnbRed: '#ff5a5f',
    primary: '#337ab7',
    bgHover: '#f3f3f7',
    bgGreen: '#31B48D',
  },
};

export default theme;
