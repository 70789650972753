import { DeepPartial } from 'react-hook-form';
import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { Agency } from 'models/Agency';
import { CURRENCIES_WITHOUT_NONE, Currency } from 'models/Currency';
import { PropertyBusinessType } from 'models/Properties';
import { MultiUnitPropertyType } from 'models/PropertyType';
import {
  basePropertyAddressSchema,
  propertyDetailsInternalThumbnailSchema,
  propertyDetailsPropertyNameSchema,
} from '../../common/Common.schema';

export const addMultiUnitPropertySchema = (t: TFunction<Namespace<'en'>>) =>
  Yup.object({
    propertyAddress: basePropertyAddressSchema(PropertyBusinessType.HOTEL),
    propertyDetails: Yup.object({
      ...propertyDetailsInternalThumbnailSchema(t),
      currency: Yup.mixed<Currency>().oneOf(CURRENCIES_WITHOUT_NONE).required(),
      propertyName: propertyDetailsPropertyNameSchema(),
      propertyType: Yup.mixed<MultiUnitPropertyType>()
        .oneOf(Object.values(MultiUnitPropertyType))
        .required(),
      securityDeposit: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .optional()
        .nullable(),
    }),
  });

export type AddMultiUnitPropertyFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof addMultiUnitPropertySchema>>
>;

export type MultiUnitPropertyCreationPayload = {
  address: {
    address: string;
    address2?: string;
    city: string;
    countryCode: string;
    state?: string;
    zipCode: string;
  };
  agencyUid: string;
  mainPhotoUrl?: string;
  name: string;
  pricing: {
    currency: Currency;
    securityDeposit?: number;
  };
  type: MultiUnitPropertyType;
};

export function getDefaultFormValues(
  agency: Agency,
): AddMultiUnitPropertyFormValues {
  const defaultCountryCode = agency?.countryCode ?? 'US';

  return {
    propertyAddress: {
      countryCode: defaultCountryCode,
      state: defaultCountryCode === 'US' ? 'AL' : '',
    },
    propertyDetails: {
      currency: agency?.currencyCode ?? 'USD',
      propertyType: MultiUnitPropertyType.HOTEL,
    },
  };
}
