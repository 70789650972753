import { useTranslation } from 'react-i18next';
import StarIcon from 'assets/images/icons/star.svg?react';
import { AverageRatingReviewsContainer } from './OverallAirbnbRatings.styles';

const AverageRatingReviews = ({
  reviewsAmount,
  averageRating,
}: {
  reviewsAmount: number;
  averageRating: number;
}) => {
  const { t } = useTranslation();

  return (
    <AverageRatingReviewsContainer data-testid="average-rating-reviews">
      <StarIcon />
      <span data-testid="airbnb-average-rating">
        {Number(averageRating || 0).toFixed(2)}
      </span>
      <div className="separator" />
      <span data-testid="airbnb-reviews-amount">
        {t(
          'pageProperty.reviews.airbnbGuest.overallAirbnbRating.amountReviews.reviews',
          { count: reviewsAmount || 0 },
        )}
      </span>
    </AverageRatingReviewsContainer>
  );
};

export default AverageRatingReviews;
