import Button from 'components/button/Button';
import PropertyMultiSelectFilterField from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField';
import { PROPERTIES_SELECTION } from 'components/forms/domain/property/PropertyMultiSelectFilterField/PropertyMultiSelectFilterField.constants';
import FormWithProvider from 'components/forms/form/Form';
import TextField from 'components/forms/textField/TextField';
import useAppModal from 'hooks/useAppModal';
import { CustomAmenity } from 'models/Amenity';
import { Property } from 'models/Properties';
import { AppliesToLabel } from 'pages/property/common/Common.styles';
import { Col, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AppliesToFieldContainer } from './CustomAmenityModal.styles';
import { getDefaultFormValues } from './CustomAmenityModal.utils';

export const addModalId = 'add-custom-amenity-modal';
export const editModalId = 'edit-custom-amenity-modal';

const addEditSchema = () => ({
  name: Yup.string().required(),
  appliesTo: Yup.string().required(),
  appliesToProperties: Yup.array()
    .of(
      Yup.object({
        label: Yup.string(),
        value: Yup.string(),
        isFixed: Yup.boolean().optional(),
      }),
    )
    .label('properties')
    .when('appliesTo', {
      is: PROPERTIES_SELECTION.ALL_PROPERTIES,
      then: (schema) => schema.optional().nullable(true),
      otherwise: (schema) => schema.min(1).required(),
    }),
});

const CustomAmenityModal = ({
  property,
  onSubmit,
  customAmenity,
}: {
  property: Property;
  onSubmit: (data: any) => void;
  customAmenity?: CustomAmenity;
}) => {
  const { closeModal } = useAppModal();
  const { t } = useTranslation();

  const isEditMode = !!customAmenity;

  const handleClose = () => {
    closeModal(isEditMode ? editModalId : addModalId);
  };

  return (
    <FormWithProvider
      horizontal
      schema={addEditSchema()}
      onSubmit={onSubmit}
      defaultValues={getDefaultFormValues(customAmenity, property)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isEditMode
            ? t('pageProperty.amenities.customAmenities.edit')
            : t('pageProperty.amenities.customAmenities.add')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextField
          name="name"
          label={t('common.name')}
          required
          colSmLabel={4}
          colSmInput={8}
        />
        <AppliesToFieldContainer>
          <Col xs={4}>
            <AppliesToLabel>
              {t(
                'pageProperty.feesTaxesAndPolicies.propertyFeesAndTaxes.appliesTo',
              )}
            </AppliesToLabel>
          </Col>
          <Col xs={8}>
            <PropertyMultiSelectFilterField
              name="appliesTo"
              showUnits={false}
              enableUnitTypes
              graphQlFilters={{
                topLevelOnly: false,
              }}
            />
          </Col>
        </AppliesToFieldContainer>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="primary" type="submit">
          {t('common.save')}
        </Button>
        <Button bsStyle="default" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
      </Modal.Footer>
    </FormWithProvider>
  );
};

CustomAmenityModal.defaultProps = {
  customAmenity: undefined,
};

export default CustomAmenityModal;
